export const leadStatuses = {
  SENT: 'SENT',
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED',
  RECEIVED: 'RECEIVED',
};

export const leadItemStatuses = {
  USER_DECLINED: 'user_declined',
  ACCEPTED: 'accepted',
};

export const itemOfferChoices = {
  DECLINE: 'decline',
  CONSIGNMENT: 'consignment',
  CASH: 'cash',
};

export const followUpStatuses = {
  ON_THEIR_WAY: 'ON THEIR WAY',
};
