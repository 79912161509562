/** @format */
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import api from 'constants/api';
import config from '../config/config';

export const logoutCompletely = () => {
  // Can only WP logout by visiting the Logout URL
  window.location.assign(api.user.LOGOUT);
  // Clear Client Platform auth
  flushAuth();
};

export function delToken() {
  Cookies.remove('_tk'); // Remove legacy cookies that were not set with a domain value
  Cookies.remove('_tk', { domain: config.COOKIE_DOMAIN });
}

export function getToken() {
  return Cookies.get('_tk')?.replace('*', 'Bearer ');
}

export function setToken(value) {
  Cookies.set('_tk', value, { expires: 30, domain: config.COOKIE_DOMAIN });
}

export function flushAuth() {
  delToken();
  return true;
}

export function hasAuth() {
  try {
    jwtDecode(getToken());
  } catch (_) {
    return false;
  }

  return true;
}

export function decryptAuth() {
  const decrypted = jwtDecode(getToken());
  const { id, email } = decrypted;

  const auth = {
    id,
    email,
  };

  return auth;
}
