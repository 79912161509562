import React, { Fragment } from 'react';
import api from 'constants/api';
import SalesComponent from 'components/Sales/FullDetailsItem';
import IconHeader from 'components/IconHeader';
import AxiosPageReadyWrapper, {
  AxiosPageReadyContext,
} from 'components/AxiosPageReadyWrapper';
import NoSale from '../../components/NoSale';

const SalesHistoryView = () => (
  <Fragment>
    <IconHeader title="Sales | Paid Out" svgName="my_sale" />
    <AxiosPageReadyWrapper url={api.data.SALES_HISTORY}>
      <AxiosPageReadyContext.Consumer>
        {({ data: sales_history = [] }) => {
          if (sales_history.length > 0) {
            return sales_history.map((sale, index) => (
              <Fragment key={index}>
                <SalesComponent data={sale} />
              </Fragment>
            ));
          }
          return <NoSale />;
        }}
      </AxiosPageReadyContext.Consumer>
    </AxiosPageReadyWrapper>
  </Fragment>
);

export default SalesHistoryView;
