import React from 'react';
import api from 'constants/api';
import axios from 'helpers/axios';
import { Interests } from '@bonlineza/luxity-lib';
import {
  getBrandsQuery,
  getInterestsQuery,
  getShoeSizeQuery,
  getStylesQuery,
  getNotificationOptionsQuery,
} from 'views/Interests/query';
import { connect } from 'react-redux';

const InterestsPage = ({ userId }) => {
  const setSchedule = (selectedOption) => {
    const payload = { email_interest_send_schedule: selectedOption };
    return axios.post(api.interests.setInterestSchedule(), payload);
  };
  const getSchedule = () => {
    const url = `${api.graphql()}?gql_id=getSchedule`;
    return axios.post(url, {
      query: getNotificationOptionsQuery(userId),
    });
  };

  const interestQuery = getInterestsQuery(userId);

  return (
    <Interests
      addInterestApi={api.interests.createInterest()}
      baseGraphApi={api.graphql()}
      deleteInterestApi={api.interests.deleteInterest}
      requestInstance={axios}
      interestQuery={interestQuery}
      getBrandsGraphQuery={getBrandsQuery}
      getStylesGraphQuery={getStylesQuery}
      getShoesGraphQuery={getShoeSizeQuery}
      setSchedule={setSchedule}
      getSchedule={getSchedule}
    />
  );
};

const mapState = ({ authStore }) => ({
  userId: authStore.user.id,
});

export default connect(mapState)(InterestsPage);
