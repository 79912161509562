import React from 'react';
import LuxityRadioButtons from '.';

const LuxityRadioFormWrapper = ({
  name,
  title = '',
  controlRef,
  options = [],
  errors = {},
}) => {
  const optsWithControlRef = options.map((option) => ({
    ...option,
    actionCallback: () => controlRef(name, option.value),
  }));

  return (
    <LuxityRadioButtons
      name={name}
      title={title}
      options={optsWithControlRef}
      errors={errors}
      noWrap
    />
  );
};
export default LuxityRadioFormWrapper;
