export const getSubmissionStatusText = (status) => {
  switch (status) {
    case 'new':
      return 'Submission currently processing';
    case 'declined':
      return 'Declined';
    case 'declined_brand':
      return 'We do not stock this brand';
    case 'declined_condition':
      return 'Declined due to condition';
    case 'declined_authenticity':
      return 'Declined due to authenticity concerns';
    default:
      return status;
  }
};
