import React, { Fragment } from 'react';
import FormField from 'vendor/Form/FormField';
import LuxityLoader from 'components/LuxityLoader';
import SimplePopup from '@bonlineza/b-lib/SimplePopup';

export default ({
  isOpen = false,
  onClose = () => null,
  isLoading = false,
  title = 'Password Required',
  renderLoader = () => <LuxityLoader />,
  options = [],
  renderDescription = () => (
    <p>In order to change your bank details, please enter your password</p>
  ),
  controlRef,
  errors,
}) => (
  <SimplePopup
    isOpen={isOpen}
    close={onClose}
    title={title}
    isLoader={isLoading}
    renderLoader={renderLoader}
    options={options}
    renderContent={() => (
      <Fragment>
        {renderDescription()}

        <div className="u-margin--bottom">
          <FormField
            label="Password*"
            name="password"
            type="password"
            itemClass="form__item--horizontal"
            labelClass="form__item__label"
            controlRef={controlRef}
            errorFields={errors}
          />
        </div>
      </Fragment>
    )}
  />
);
