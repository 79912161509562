import React, { useContext, Fragment } from 'react';
import ListContext from 'vendor/GqlQueryList/config/ListContext.js';
import SubmissionItem from './SubmissionItem';

const SubmissionList = () => {
  const context = useContext(ListContext);
  const { list } = context;

  return (
    <Fragment>
      <div className="sales">
        {list.length > 0 &&
          list.map((submission, key) => (
            <SubmissionItem index={key} key={key} data={submission} />
          ))}
      </div>
    </Fragment>
  );
};

export default SubmissionList;
