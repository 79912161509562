import React, { Fragment } from 'react';
import { fC } from 'helpers/locale';
import StatusItemGauge from 'components/StatusItemGauge';
import convertUnixTimeToDate from 'functions/convertUnixTimeToDate.func';
import LuxityToolTip from 'components/LuxityToolTip';
import { purchaseStatusDefinitions } from 'constants/purchaseStatusDefinitions';
import purchaseStatuses from 'constants/purchaseStatuses';
import PropTypes from 'prop-types';

const FullDetailsItem = ({ data }) => {
  const purchaseDefinition =
    purchaseStatusDefinitions[data.status.text] || null;
  const statusText =
    data.status.text === purchaseStatuses.partially_paid
      ? 'Reserved'
      : data.status.text;
  const statusList = data.status.list.map((status) => {
    return status === purchaseStatuses.partially_paid ? 'Reserved' : status;
  });

  const getStatusMarkup = () => {
    if (purchaseDefinition) {
      return (
        <LuxityToolTip text={purchaseDefinition}>
          <span className="fc--primary">{statusText}</span>
        </LuxityToolTip>
      );
    }

    return <span className="fc--primary">{statusText}</span>;
  };

  const renderPayoutDetails = () => {
    if (!data.payout_details) {
      return null;
    }

    const estimatedPayoutDate = data.payout_details.find(
      (item) => item.type === 'estimated_payout_date',
    );

    const paymentDate = data.payout_details.find(
      (item) => item.type === 'payment_date',
    );

    const pendingItems = data.payout_details.filter((item) =>
      ['consignment_agreement', 'vat_form', 'id', 'bank_details'].includes(
        item.type,
      ),
    );

    const listingStatus = data.payout_details.find(
      (item) => item.type === 'listing_status',
    );

    return (
      <div className="payout-status">
        {estimatedPayoutDate && (
          <div className="payout-status__item">
            Estimated Payout Date: &nbsp;
            {convertUnixTimeToDate(estimatedPayoutDate.date)}
          </div>
        )}
        {paymentDate && (
          <div className="payout-status__item">
            Paid Out: &nbsp;
            {convertUnixTimeToDate(paymentDate.date)}
          </div>
        )}

        {pendingItems.length > 0 ? (
          <div className="payout-status__item">
            {renderPendingItems(pendingItems)}
          </div>
        ) : (
          <>
            {listingStatus && (
              <div className="payout-status__item">{listingStatus.text}</div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderPendingItems = (items) => {
    return items.map((item, index) => {
      let content;

      if (item.type === 'id' || item.type === 'bank_details') {
        content = (
          <a href="/profile" className="fc--primary underline">
            {item.type === 'id' ? 'ID/Passport Verification' : 'Bank Details'}
          </a>
        );
      } else {
        content = item.link ? (
          <a href={item.link} className="fc--primary underline">
            {item.type === 'consignment_agreement'
              ? 'Pending: Consignment Agreement'
              : 'Pending: VAT Form'}
          </a>
        ) : (
          <span className="fc--primary">
            {item.type === 'consignment_agreement'
              ? 'Pending: Consignment Agreement'
              : 'Pending: VAT Form'}
          </span>
        );
      }
      return (
        <Fragment key={item.type}>
          {index > 0 && ', '}
          {content}
        </Fragment>
      );
    });
  };

  return (
    <Fragment>
      <div className="sales__item item">
        <div className="item__header">
          <div className="item__header__left">
            <div className="item__header__left__primary">
              {`Reference No. ${data.reference}`}
            </div>
            {renderPayoutDetails()}
          </div>
          <div className="item__header__right">
            <div className="item__header__right__primary--status-container">
              Sale Status:&nbsp;
              {getStatusMarkup()}
            </div>
            <StatusItemGauge
              statusList={statusList}
              statusPosition={data.status.index}
            />
          </div>
        </div>
        <div className="item__details">
          <div className="item__details__sub-items sub-items">
            {data.items.map((item, key) => (
              <div className="sub-items__item" key={key}>
                {item.product_link ? (
                  <a
                    href={item.product_link}
                    target="_blank"
                    rel="noreferrer"
                    className="sub-items__item__image">
                    <img src={item.image} alt={item.name} />
                  </a>
                ) : (
                  <div className="sub-items__item__image">
                    <img src={item.image} alt={item.name} />
                  </div>
                )}

                <div className="sub-items__item__details">
                  {item.product_link ? (
                    <a
                      href={item.product_link}
                      target="_blank"
                      rel="noreferrer"
                      className="sub-items__item__details__item">
                      {item.name}
                    </a>
                  ) : (
                    <div className="sub-items__item__details__item">
                      {item.name}
                    </div>
                  )}

                  <div className="sub-items__item__details__item">
                    Payout:&nbsp;
                    <strong>{fC(item.price, false)}</strong>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {data.payment_date !== null ? (
          <div className="sales__item__summary item__summary">
            <div className="item__summary__item">
              <div className="item__summary__item__left">Payment Date:</div>
              <div className="item__summary__item__right">
                {convertUnixTimeToDate(data.payment_date) || 'No date set'}
              </div>
            </div>

            <div className="item__summary__item">
              <div className="item__summary__item__left">
                <strong>Payment Total:</strong>
              </div>
              <div className="item__summary__item__right">
                {fC(data.total, false)}
              </div>
            </div>

            {data.proofs_of_payment?.length > 0 && (
              <Fragment>
                <div className="item__generic-title">Proof of Payments</div>
                {data.proofs_of_payment.map((pop, key) => (
                  <div key={key}>
                    <a
                      href={pop.download_url}
                      target="_blank"
                      rel="noopener noreferrer">
                      {pop.file_name}
                    </a>
                  </div>
                ))}
              </Fragment>
            )}
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

FullDetailsItem.propTypes = {
  data: PropTypes.shape({
    reference: PropTypes.string.isRequired,
    payout_date: PropTypes.string.isRequired,
    proofs_of_payment: PropTypes.arrayOf(
      PropTypes.shape({
        file_name: PropTypes.string.isRequired,
        download_url: PropTypes.string.isRequired,
      }),
    ).isRequired,
    status_position: PropTypes.number.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        status: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
        price: PropTypes.number.isRequired,
      }),
    ).isRequired,
    status_list: PropTypes.array.isRequired,
    proof_of_payment: PropTypes.array.isRequired,
    total: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default FullDetailsItem;
