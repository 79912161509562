import React, { useCallback, useEffect } from 'react';
import withRouter from 'helpers/withRouter';
import GetSvg from 'vendor/GetSvg';
import { ColorRing } from 'react-loader-spinner';
import { Event } from 'components/Tracking';

const CreditPaymentResult = ({ location: { search } }) => {
  const sendGAForPayment = useCallback(() => {
    const parsed = new URLSearchParams(search);
    const sale_order_number = parsed.get('sale_order_number') || '';
    const amount = parsed.get('amount') || '';
    Event('Payment', 'success', sale_order_number, parseInt(amount, 10));
  }, [search]);

  useEffect(() => {
    sendGAForPayment();
    setTimeout(() => {
      window.location.href = '/';
    }, 60000);
  }, [sendGAForPayment]);

  return (
    <div className="credit-payment-result">
      <GetSvg svg="available_credits" wrapperClass="svg" />
      <div>We are applying your credits, this might take several minutes.</div>
      <ColorRing
        visible
        height="100"
        width="100"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass="color-ring-wrapper"
        colors={['#000000', '#000000', '#000000', '#000000', '#000000']}
      />
    </div>
  );
};

export default withRouter(CreditPaymentResult);
