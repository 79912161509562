import React, { Fragment, useState } from 'react';
import Select from 'react-select';
import { hasValue } from '@bonlineza/b-lib/build/functions';

const OPTION_OTHER = 'OTHER';

const convertBrankAccountsToSelectOptions = (bank_account_options) =>
  Object.keys(bank_account_options).map((key) => ({
    value: bank_account_options[key],
    label: `${key} - ${bank_account_options[key]}`,
  }));

const OtherOption = { value: OPTION_OTHER, label: 'Other ...' };

const BankAndCodeSelector = ({
  controlRef,
  viewOnly,
  hasError,
  label,
  name,
  itemClass,
  errors,
  placeholder,
  passedObj,
  mandatory,
}) => {
  const { branch_code: initialBranchCode = '', bank: initialBankName = '' } =
    passedObj;

  const [bankName, setBankName] = useState(initialBankName);
  const [branchCode, setBranchCode] = useState(initialBranchCode);

  const { branch_code_options } = passedObj;
  const branchOptions = [
    ...convertBrankAccountsToSelectOptions(branch_code_options),
    { ...OtherOption },
  ];

  const getBankNameFromCode = (passedCode = '') => {
    const filteredOption = Object.keys(branch_code_options)
      .map((bankNameFromOption) => ({
        bankName: bankNameFromOption,
        code: branch_code_options[bankNameFromOption],
      }))
      .filter(({ code }) => code === passedCode);
    if (filteredOption.length > 0) {
      return filteredOption[0].bankName;
    }
    return '';
  };

  // filter availabe branch options with current branch code for branchCodeSelection
  let branchCodeSelection = branchCode
    ? branchOptions.filter((item) => {
        const bankNameFromCode = getBankNameFromCode(item.value);
        return item.value === branchCode && bankNameFromCode === bankName;
      })
    : null;

  const [hasOtherOptionInitially] = useState(
    initialBranchCode !== '' && branchCodeSelection?.length === 0,
  );

  // if initial branch code is not part of available options then showOther true
  const [showOther, setShowOther] = useState(hasOtherOptionInitially);
  // the Other option is an exception to the rule, sometimes we must show it by default

  if (hasOtherOptionInitially) {
    branchCodeSelection = OtherOption;
  } else {
    branchCodeSelection = showOther ? OtherOption : branchCodeSelection;
  }

  const getValueForBankField = () => {
    const bankNames = Object.keys(branch_code_options);
    return hasValue(initialBankName) && !bankNames.includes(initialBankName)
      ? initialBankName
      : '';
  };

  const branchCodeErrMsg = errors.branch_code || '';
  const bankErrMsg = errors.bank || '';

  const getViewOnlyValue = () => {
    if (hasValue(initialBankName) && hasValue(initialBranchCode)) {
      return `${initialBankName} - ${initialBranchCode}`;
    }
    return '';
  };

  // Trigger control ref for bank on state change
  React.useEffect(() => {
    controlRef('bank', bankName);
  }, [bankName, controlRef]);

  // Trigger control ref for branch_code on state change
  React.useEffect(() => {
    controlRef('branch_code', branchCode);
  }, [branchCode, controlRef]);

  const resetBankValuesToInit = React.useCallback(() => {
    setShowOther(false);
    setBankName(initialBankName);
    setBranchCode(initialBranchCode);
  }, [initialBankName, initialBranchCode]);

  const handleRevertWithoutOtherOption = React.useCallback(() => {
    if (viewOnly) {
      resetBankValuesToInit();
    }
  }, [resetBankValuesToInit, viewOnly]);

  const handleRevertWithOtherOption = React.useCallback(() => {
    setShowOther(true);
    if (viewOnly) {
      resetBankValuesToInit();
    }
  }, [resetBankValuesToInit, viewOnly]);

  React.useEffect(() => {
    if (hasOtherOptionInitially) {
      handleRevertWithOtherOption();
    } else {
      handleRevertWithoutOtherOption();
    }
  }, [
    controlRef,
    handleRevertWithOtherOption,
    handleRevertWithoutOtherOption,
    hasOtherOptionInitially,
    initialBankName,
    initialBranchCode,
    viewOnly,
  ]);

  return (
    <Fragment>
      <div
        className={`${itemClass} ${
          (!viewOnly && hasError && !showOther && `has-error `) || ''
        }`}>
        <div className="bank-and-code-selector__label form__item__label">
          {label}
          {!viewOnly && mandatory && '*'}
        </div>
        <div className="bank-and-code-selector__input-container">
          {viewOnly ? (
            <div className="text--dk--flushed">{getViewOnlyValue()}</div>
          ) : (
            <Fragment>
              <Select
                className="react-select"
                classNamePrefix="react-select"
                isSearchable={false}
                // run control ref onrender
                // luxityInitial={controlRef('branch_code', branchCode || '')}
                options={branchOptions}
                placeholder={placeholder}
                defaultValue={branchCodeSelection}
                onChange={(selected) => {
                  if (selected.value === OPTION_OTHER) {
                    setShowOther(true);
                    setBranchCode('');
                  } else {
                    setShowOther(false);
                    setBranchCode(selected.value);
                  }
                  setBankName(
                    selected.value === OPTION_OTHER
                      ? ''
                      : getBankNameFromCode(selected.value),
                  );
                }}
              />
              {showOther ? (
                <Fragment>
                  <input
                    name="bank"
                    className={`bank-and-code-selector__input-container__input${
                      hasValue(bankErrMsg) ? '--error' : ''
                    }`}
                    placeholder="Enter your Bank..."
                    defaultValue={getValueForBankField()}
                    onChange={(e) => {
                      e.preventDefault();
                      setBankName(e.target.value);
                    }}
                  />

                  {!viewOnly && bankErrMsg ? (
                    <div className="form__item__validation">{bankErrMsg}</div>
                  ) : null}

                  <input
                    name="branch_code"
                    className={`bank-and-code-selector__input-container__input${
                      hasValue(branchCodeErrMsg) ? '--error' : ''
                    }`}
                    placeholder="Enter your Branch code..."
                    defaultValue={branchCode}
                    onChange={(e) => {
                      e.preventDefault();
                      setBranchCode(e.target.value);
                    }}
                  />

                  {!viewOnly && branchCodeErrMsg ? (
                    <div className="form__item__validation">
                      {branchCodeErrMsg}
                    </div>
                  ) : null}
                </Fragment>
              ) : null}
            </Fragment>
          )}
        </div>
        {!viewOnly && hasError && !showOther ? (
          <div className="form__item__validation">{errors[name]}</div>
        ) : null}
      </div>
    </Fragment>
  );
};

export default BankAndCodeSelector;
