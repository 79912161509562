import React from 'react';
import Header from './components/Header';
import Body from './components/Body';

const FullLayout = ({ children }) => (
  <div className="dashboard">
    <div className="dashboard__header">
      <Header />
    </div>
    <div className="dashboard__body">
      <Body>{children}</Body>
    </div>
  </div>
);

export default FullLayout;
