import React, { Fragment, useEffect, useState, useRef } from 'react';
import axios from 'helpers/axios';
import PropTypes from 'prop-types';
import LuxityLoader from 'components/LuxityLoader';

const BaseRequest = {
  loading: false,
  success: false,
  failed: false,
};

const QueryList = ({ body, url, onData, customErrorMessage, children }) => {
  const [requestState, setRequestState] = useState({
    ...BaseRequest,
  });

  // Get the previous value (was passed into hook on last render)
  const prevQuery = usePrevious(body.query);

  useEffect(() => {
    const getData = (obj) => {
      updateRequestState({ loading: true });
      axios
        .post(url, obj)
        .then((res) => {
          updateRequestState({ success: true });
          const {
            data: { data },
          } = res;
          onData(data);
        })
        .catch(() => {
          updateRequestState({ failed: true });
        });
    };

    // Compare previous query to current query. If different update
    if (body.query !== prevQuery) {
      getData({ query: body.query });
    }
  }, [onData, body.query, url, prevQuery]);

  const updateRequestState = (obj) => {
    setRequestState({
      ...BaseRequest,
      ...obj,
    });
  };

  const { loading, success, failed } = requestState;

  return (
    <Fragment>
      {(success && children) ||
        (loading && <LuxityLoader />) ||
        (failed && (
          <div>{customErrorMessage || 'Data could not be retrieved'}</div>
        ))}
    </Fragment>
  );
};

QueryList.propTypes = {
  body: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  onData: PropTypes.func.isRequired,
  customErrorMessage: PropTypes.string,
};

QueryList.defaultProps = {
  customErrorMessage: '',
};

export default QueryList;

function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
