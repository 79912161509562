import { leadStatuses } from 'constants/leadStatuses';

export const sortLeads = (leads) => {
  const statusOrder = [
    leadStatuses.SENT,
    leadStatuses.ACCEPTED,
    leadStatuses.RECEIVED,
    leadStatuses.DECLINED,
  ];
  return leads.sort((a, b) => {
    if (statusOrder.indexOf(a.status) !== statusOrder.indexOf(b.status)) {
      return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
    }
    return new Date(b.created_at) - new Date(a.created_at);
  });
};
