import React, { Fragment } from 'react';
import GetSvg from 'vendor/GetSvg';
import { Link } from 'react-router-dom';
import convertUnixTimeToDate from 'functions/convertUnixTimeToDate.func';

export default ({ data, index }) => {
  return (
    <Fragment>
      <div className="latest-sale-desktop-container">
        <div className="sale-headings">
          {index === 0 && (
            <div className="headings-desktop first-heading-first-content sale-heading-child">
              {' '}
              <strong>Reference No.</strong>
            </div>
          )}

          {index === 0 && (
            <div className="headings-desktop sale-heading-child">
              <strong>Items</strong>
            </div>
          )}
          {index === 0 && (
            <div className="headings-desktop sale-heading-child">
              <strong>Sale Status</strong>
            </div>
          )}

          {index === 0 && (
            <div className="headings-desktop sale-heading-child">
              <strong>Consignment Value</strong>
            </div>
          )}

          {index === 0 && (
            <div className="headings-desktop sale-heading-child">
              <strong>Estimated Payout Date</strong>
            </div>
          )}
          <div className="empty-space" />
        </div>
        <div className="dashboard-content">
          <div className="dashboard-content__row">
            <div className="first-heading-first-content dashboard-content__column">
              {data.order_number}
            </div>
            <div className="dashboard-content__column">
              <div className="dashboard-content__images">
                {data.purchase_order.purchase_order_lines
                  .slice(0, 3)
                  .map((valueObj, i) => {
                    return (
                      <img
                        className="dashboard-content__img"
                        key={i}
                        alt=""
                        src={valueObj.product.image}
                      />
                    );
                  })}
                {data.purchase_order.purchase_order_lines.length > 3 && (
                  <span className="dashboard-content__ellipsis"> ...</span>
                )}
              </div>
            </div>
            <div className="dashboard-content__column">
              {data.luxity_purchase_status}
            </div>
            <div className="dashboard-content__column">
              {' '}
              {`R${data.purchase_order.total}`}
            </div>
            <div className="dashboard-content__column">
              {convertUnixTimeToDate(data.estimated_payout_date)}
            </div>
          </div>
          <span className="sale-content-button">
            <div className="sale-action-button">
              <div className="headings-desktop">
                <Link to={{ pathname: '/sales', target_id: data.id }}>
                  <GetSvg svg="eye" wrapperClass="svg" />
                </Link>
              </div>
            </div>
          </span>
        </div>
      </div>

      <div className="latest-sale-mobile-container">
        <div className="latest-sale-mobile-container__first-row">
          <div className="latest-sale-mobile-container__first-row__col-1">
            <span>
              <strong>REF NO:</strong>
            </span>
            <span>{data.order_number}</span>
            <span className="dashboard-content__images">
              {data.purchase_order.purchase_order_lines
                .slice(0, 3)
                .map((valueObj, i) => {
                  return (
                    <img
                      className="item-imgs"
                      key={i}
                      alt=""
                      src={valueObj.product.image}
                    />
                  );
                })}
              {data.purchase_order.purchase_order_lines.length > 3 && (
                <span className="dashboard-content__ellipsis"> ...</span>
              )}
            </span>
          </div>
          <div className="latest-sale-mobile-container__first-row__col-2">
            <div className="sales-content">
              <strong>Sale Status:</strong>
              <div className="sales-content-value">
                {data.luxity_purchase_status}
              </div>
            </div>
            <div className="sales-content">
              <strong>Consignment Value:</strong>
              <div className="sales-content-value">
                {`R${data.purchase_order.total}`}
              </div>
            </div>
            <div className="sales-content">
              <strong>EST Payout Date:</strong>
              <div className="sales-content-value">
                {convertUnixTimeToDate(data.estimated_payout_date)}
              </div>
            </div>
          </div>
        </div>
        <div className="latest-sale-mobile-container__second-row">
          <div className="action-button">
            <div className="headings-mobile">
              <Link to={{ pathname: '/sales', target_id: data.id }}>
                <strong>VIEW</strong>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
