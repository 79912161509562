import React, { Fragment } from 'react';
import { fC } from 'helpers/locale';
import PropTypes from 'prop-types';

const PaymentItem = ({ data }) => (
  <Fragment>
    <div className="orders__item item">
      <div className="item__header">
        <div className="item__header__left">
          <div className="item__header__left__primary">
            {`Order No. ${data.order_number}`}
          </div>
        </div>
      </div>
      <div className="orders__item__details item__details">
        <div className="item__details__sub-items sub-items">
          {data.items.map((item, key) => (
            <div className="sub-items__item" key={key}>
              <div className="sub-items__item__image">
                <img src={item.image} alt="item.name" />
              </div>
              <div className="sub-items__item__details">
                <div className="sub-items__item__details__item">
                  {item.name}
                </div>
                <div className="sub-items__item__details__item">
                  Item Price:&nbsp;
                  <strong>{fC(item.price, false)}</strong>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="orders__item__summary item__summary">
        <div className="item__summary__item">
          <div className="item__summary__item__left">
            <strong>ORDER TOTAL</strong>
          </div>
          <div
            className={`item__summary__item__right ${
              !data.outstanding_balance ? 'fc--primary' : ''
            }`}>
            {fC(data.order_total, false)}
          </div>
        </div>
        {data.outstanding_balance ? (
          <div className="item__summary__item">
            <div className="item__summary__item__left">
              <strong>OUTSTANDING BALANCE</strong>
            </div>
            <div className="item__summary__item__right fc--primary">
              {fC(data.outstanding_balance, false)}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  </Fragment>
);

PaymentItem.propTypes = {
  order_number: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
    }),
  ).isRequired,
  order_total: PropTypes.number.isRequired,
  outstanding_balance: PropTypes.number.isRequired,
};

export default PaymentItem;
