/** @format */

import { combineReducers } from 'redux';
import { SET_TOOLTIP, CLEAR_TOOLTIP } from '../constants';

const initialDataState = {
  text: '',
};

function dataReducer(state = initialDataState, { type, payload }) {
  switch (type) {
    case SET_TOOLTIP:
      return {
        ...state,
        ...payload,
      };
    case CLEAR_TOOLTIP:
      return {
        ...initialDataState,
      };
    default:
      return state;
  }
}

export default combineReducers({
  data: dataReducer,
});
