import React, { Fragment } from 'react';
import Form from 'vendor/Form';
import axios from 'helpers/axios';
import api from 'constants/api';
import { snakeCaseToTitleCase } from '@bonlineza/b-lib/functions';
import { PrimaryButton } from '@bonlineza/luxity-lib';
import LuxityLoader from 'components/LuxityLoader';
import addressFormField from './addressFormFields';

const BaseRequest = {
  loading: false,
  error: false,
  success: false,
  message: '',
};

class AddressForm extends React.Component {
  constructor(props) {
    super(props);
    this.defaultAddressSettingRef = React.createRef();
    this.state = {
      request: BaseRequest,
      editting: props.data?.isNew || false,
      isNew: props.data?.isNew || false,
      errorMessages: {},
      currentCountry: this.props.data.country,
      countryHasChanged: false,
      address_type: 'personal',
    };
    this.onRadioChange = this.onRadioChange.bind(this);
  }

  onRadioChange = (e) => {
    this.setState({
      address_type: e.target.value,
    });
  };

  getDefaultAddressSetting = () =>
    !!this.defaultAddressSettingRef.current.checked;

  getFormFields() {
    const { currentCountry } = this.state;

    const isLocal =
      currentCountry === 'South Africa' || currentCountry === 'ZA';

    return addressFormField(
      this.props.data,
      this.updateCountry,
      this.state.countryHasChanged,
      this.setCountryHasChanged,
      isLocal,
      this.props.provinceOptions,
      this.props.countryOptions,
      this.state.address_type,
    );
  }

  setCountryHasChanged = () => this.setState({ countryHasChanged: true });

  updateCountry = (value = '') => this.setState({ currentCountry: value });

  // if new added address addItem or otherwise update existing field
  getSubmit = (payload) => {
    if (this.state.isNew)
      return this.handleValidation(payload) ? this.addItem(payload) : null;
    return this.handleValidation(payload) ? this.updateItem(payload) : null;
  };

  getAdditionalButtons = () => {
    if (this.state.isNew) {
      return [];
    }
    return [
      () => (
        <PrimaryButton type="button" onClick={this.removeItem.bind(null)}>
          Delete
        </PrimaryButton>
      ),
    ];
  };

  updateRequest = (obj) =>
    this.setState((prevState) => ({
      ...prevState,
      request: {
        ...BaseRequest,
        ...obj,
      },
    }));

  removeItem = () => {
    this.updateRequest({ loading: true });
    axios
      .delete(`${api.user.ADDRESS}/${this.props.data.id}`)
      .then(() => {
        this.updateRequest({ success: true });
        this.props.actionCallback('delete_address')();
      })
      .catch((e) => {
        this.updateRequest({ failed: true });
        this.handleError(e.status, e.data);
      });
  };

  updateItem = (payload) => {
    this.updateRequest({ loading: true });

    return axios
      .post(`${api.user.ADDRESS}/${this.props.data.id}`, {
        ...payload,
        type: this.props.type,
        default_for_type: this.getDefaultAddressSetting(),
      })
      .then(() => {
        this.updateRequest({ success: true });
        this.props.actionCallback('update_address')();
      })
      .catch((e) => {
        this.updateRequest({ failed: true });
        this.handleError(e.status, e.data);
      });
  };

  handleValidation = (payload) => {
    // add requirements here - pass true for now
    const errorMessages = Object.keys(payload).reduce(
      (accumulated, current) =>
        !payload[current]
          ? {
              ...accumulated,
              [current]: `${snakeCaseToTitleCase(current)} is mandatory`,
            }
          : {},
      {},
    );

    this.setErrors(errorMessages);
    return Object.keys(errorMessages).length === 0;
  };

  addItem = (payload) => {
    this.updateRequest({ loading: true });

    return axios
      .post(`${api.user.ADDRESS}`, {
        ...payload,
        type: this.props.type,
        default_for_type: this.getDefaultAddressSetting(),
      })
      .then(() => {
        this.updateRequest({ success: true });
        this.props.actionCallback('create_address')();
      })
      .catch((e) => {
        this.updateRequest({ failed: true });
        this.handleError(e.status, e.data);
      });
  };

  setErrors = (errObj) => this.setState({ errorMessages: { ...errObj } });

  handleError = (code, errorData = {}) => {
    if (code === 422) {
      const errorMessages = {};
      Object.keys(errorData).forEach((errPropName) => {
        errorMessages[errPropName] = errorData[errPropName].reduce(
          (pv, cV) => cV,
          '',
        );
      });
      this.setErrors(errorMessages);
    } else {
      this.props.actionCallback('generic_error')();
    }
  };

  switchEdit = () =>
    this.setState((prevState) => ({
      ...prevState,
      editting: !prevState.editting,
      errorMessages: {},
    }));

  render = () => {
    const { onCancel } = this.props;
    const { isNew } = this.state;
    return (
      <Fragment>
        <div>
          <form className="switch-address-type">
            <label key="personal" className="type-label" htmlFor="personal">
              <input
                id="personal"
                type="radio"
                name="switch-address"
                value="personal"
                defaultChecked
                checked={this.state.address_type === 'personal'}
                onChange={this.onRadioChange}
              />
              Personal
            </label>

            <label key="company" className="type-label" htmlFor="company">
              <input
                id="company"
                type="radio"
                name="switch-address"
                value="company"
                checked={this.state.address_type === 'company'}
                onChange={this.onRadioChange}
              />
              Company
            </label>
          </form>
        </div>
        <div className="u-margin--small--bottom">
          {this.state.editting && (
            <div className="form__item--horizontal">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="default_for_type" className="form__item__label">
                Set address as default
              </label>
              <div className="flex--align-items-center-wrap">
                <input
                  id="default_for_type"
                  type="checkbox"
                  ref={this.defaultAddressSettingRef}
                  defaultChecked={this.props.data.default_for_type}
                />
              </div>
            </div>
          )}

          <Form
            wrappingClass="section__fields__wrapper"
            sections={this.getFormFields()}
            submitAct={this.getSubmit}
            isProcessing={this.state.request.loading}
            actionsJsx={this.getAdditionalButtons()}
            afterSubmitErrors={this.state.errorMessages}
            submitButtonString={
              this.state.isNew ? `Submit new address` : `Update Address`
            }
            cancelAct={() => {
              if (isNew && onCancel) {
                return onCancel();
              }
              return this.switchEdit();
            }}
            viewOnly={!this.state.editting}
            displayToEdit
            toEdit={this.switchEdit}
            submitButtonClass="text-button"
            formClass="address-form"
          />

          {this.state.request.loading && (
            <div className="u-margin--top gw">
              <div className="g-1/1 u-text--right">
                <LuxityLoader />
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  };
}

AddressForm.defaultProps = {
  onCancel: null,
};

export default AddressForm;
