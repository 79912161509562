/** @format */

export function requestSuccess(type, payload, props = {}) {
  return { type, payload: payload.data, props };
}

export function requestError(type, error, props = {}) {
  // eslint-disable-next-line no-console
  console.warn(error);
  return { type, error, props };
}

export function requestRequest(type, props = {}) {
  return { type, props };
}

export function success(type) {
  return function successFunc(payload, props = {}) {
    return { type, payload: payload.data, props };
  };
}

export function failed(type) {
  return function failedFunc(error, props = {}) {
    // eslint-disable-next-line no-console
    console.warn(error);
    return { type, error, props };
  };
}

export function fetching(type) {
  return function fetchingFunc(props = {}) {
    return { type, props };
  };
}
