const scrollToElement = (domNode, minusTop = 0) => {
  if (!domNode || !window?.scrollTo) return;
  const top =
    window.pageYOffset + domNode.getBoundingClientRect().top - minusTop;
  window.scrollTo({
    top,
    behavior: 'smooth',
  });
};

export default scrollToElement;
