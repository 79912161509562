import React, { Fragment, useState } from 'react';
import AddressForm from 'views/AddressesView/components/Addresses/AddressForm';
import Accordion, { DummyAccordion } from '@bonlineza/luxity-lib/Accordion';
import Eye from '@bonlineza/luxity-lib/svg/Eye';
import Add from '@bonlineza/luxity-lib/svg/Add';
import Minus from '@bonlineza/luxity-lib/svg/Minus';
import Trash from '@bonlineza/luxity-lib/svg/Trash';
import SvgWrapper from '@bonlineza/luxity-lib/svg/SvgWrapper';
import axios from 'helpers/axios';
import api from 'constants/api';
import EllipsisLoader from '@bonlineza/b-lib/EllipsisLoader';

const EyeIcon = () => (
  <SvgWrapper>
    <Eye />
  </SvgWrapper>
);

const AddIcon = () => (
  <SvgWrapper>
    <Add />
  </SvgWrapper>
);
const CloseIcon = () => (
  <SvgWrapper>
    <Minus />
  </SvgWrapper>
);

const TrashIcon = (onDeleteClick, id, address) => (
  <SvgWrapper
    role="button"
    tabIndex={0}
    className="address-icon trash-address-icon"
    onClick={(e) => onDeleteClick(e, id, address)}
    onKeyDown={(e) => onDeleteClick(e, id, address)}>
    <Trash />
  </SvgWrapper>
);

export const AddAddressButton = (props) => (
  <DummyAccordion
    identifier="address-add"
    wrapperClass="address__item"
    containerClass="address-accordion"
    openIndicator={AddIcon}
    title={props.title}
    onClick={props.onClick}
  />
);

const AddressItem = ({
  title,
  identifier,
  className,
  children,
  closeIndicator,
  openIndicator,
}) => {
  return (
    <div className={className}>
      <Accordion
        identifier={identifier}
        wrapperClass="address__item"
        containerClass="address-accordion"
        closeIndicator={closeIndicator}
        openIndicator={openIndicator}
        title={title}>
        {(accordionClose) => children(accordionClose)}
      </Accordion>
    </div>
  );
};

AddressItem.defaultProps = {
  data: {},
  identifier: 'address-default',
  children: () => null,
};

const AddressFormGroup = ({
  addresses = [],
  actionCallback = () => null,
  addressType = '',
  countryOptions = [],
  provinceOptions = [],
}) => {
  const [loading, setLoading] = useState();

  const addressTitleComponent = (title, id, address) => (
    <div className="address-title-component">
      <div className="address-title-componen__icon-title">
        {address.default_for_type ? (
          <i className="fa fa-check-circle address-icon" />
        ) : (
          <div
            role="button"
            tabIndex={0}
            className="fa fa-check-circle uncheked-mark-icon address-icon"
            onClick={(e) => handleCheckIconClick(e, id, address)}
            onKeyDown={(e) => handleCheckIconClick(e, id, address)}
          />
        )}
        <div className="address-title">{title}</div>
      </div>
      {TrashIcon(handleDeleteAddressClick, id, address)}
    </div>
  );

  const handleCheckIconClick = (event, id, payload) => {
    event.stopPropagation();
    setLoading(true);
    axios
      .post(`${api.user.ADDRESS}/${id}`, { ...payload, default_for_type: true })
      .then(actionCallback('update_address'))
      .catch(actionCallback('generic_error'))
      .finally(() => setLoading(false));
  };

  const handleDeleteAddressClick = (event, id) => {
    event.stopPropagation();
    setLoading(true);
    axios
      .delete(`${api.user.ADDRESS}/${id}`)
      .then(actionCallback('delete_address'))
      .catch(actionCallback('generic_error'))
      .finally(() => setLoading(false));
  };

  return (
    <Fragment>
      {loading ? (
        <EllipsisLoader />
      ) : (
        <div className="address__form__group u-margin--bottom">
          <div className="u-margin--bottom">
            <AddressItem
              className="address__form__group__item--add"
              title="Add Address"
              closeIndicator={CloseIcon}
              openIndicator={AddIcon}>
              {(closeAccordion) => (
                <AddressForm
                  data={{ isNew: true, default_for_type: true }}
                  type={addressType}
                  actionCallback={actionCallback}
                  provinceOptions={provinceOptions}
                  countryOptions={countryOptions}
                  onCancel={closeAccordion}
                />
              )}
            </AddressItem>
            {addresses.length > 0 && !loading ? (
              <Fragment>
                {addresses
                  .filter((item) => !item.isNew)
                  .map((item, index) => (
                    <Fragment key={index}>
                      <AddressItem
                        className="address__form__group__item"
                        title={addressTitleComponent(
                          `${item.line1} ${item.line2}
                        ${item.post_code} ${item.city}
                        ${item.state} ${item.country}`,
                          item.id,
                          item,
                        )}
                        closeIndicator={EyeIcon}
                        openIndicator={EyeIcon}>
                        {() => (
                          <AddressForm
                            data={item}
                            type={addressType}
                            actionCallback={actionCallback}
                            provinceOptions={provinceOptions}
                            countryOptions={countryOptions}
                          />
                        )}
                      </AddressItem>
                    </Fragment>
                  ))}
              </Fragment>
            ) : null}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default AddressFormGroup;
