/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';
import config from 'config/config.json';
import {
  LuxityFooter,
  NotifyError,
  NotifySuccess,
} from '@bonlineza/luxity-lib';
import {
  buyLinks,
  sellLinks,
  contactLinks,
  storeLinks,
  popularLinks,
  socialLinks,
  companyLinks,
} from './Footer/footerLinks.js';
import '../../../../node_modules/@bonlineza/luxity-lib/build/bundle.css';

const AppFooter = () => {
  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      fetch(`${config.API_URL}/shop/newsletter-subscriber`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            NotifySuccess(
              'You have successfully subscribed to our newsletter.',
            );
            resolve({ success: true, data: response });
          } else {
            return response.json().then((errorData) => {
              const errorMessage = errorData.email;
              NotifyError(`Failed to subscribe to newsletter: ${errorMessage}`);
              resolve({ success: false, data: response, errorMessage });
            });
          }
          setLoading(false);
          return response;
        })
        .catch((error) => {
          setLoading(false);
          NotifyError('Failed to subscribe to newsletter.');
          reject(error);
        });
    });
  };

  if (loading) {
    return null;
  }

  return (
    <div>
      <LuxityFooter
        storeLinks={storeLinks}
        popularLinks={popularLinks}
        socialLinks={socialLinks}
        contactLinks={contactLinks}
        companyLinks={companyLinks}
        buyLinks={buyLinks}
        sellLinks={sellLinks}
        visaImgSrc="/assets/images/footer/visa.png"
        masterCardSrc="/assets/images/footer/mastercard.png"
        logoSrc="/assets/images/luxity-logo.svg"
        formIconSrc="/assets/images/white_arrow_right.svg"
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default AppFooter;
