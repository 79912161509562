/** @format */

import { createStore, compose, applyMiddleware } from 'redux';

import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';

import reducers from '../reducers';
import historyCore from '../helpers/history';

function createAppStore(history) {
  return createStore(
    reducers,
    compose(
      applyMiddleware(thunk, routerMiddleware(history)),
      window.devToolsExtension ? window.devToolsExtension() : (f) => f,
    ),
  );
}

export default createAppStore(historyCore);
