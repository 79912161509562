import React, { Fragment, useEffect, useState } from 'react';
import axios from 'helpers/axios';
import api from 'constants/api';
import {
  success as notifySuccess,
  error as notifyError,
} from '@bonlineza/luxity-lib/Notify';
import requestErrorMessages from 'constants/requestErrorMessages';
import { hasAuth } from 'functions/authenticator.func';
import GARoute from 'components/GARoute';
import GALink from 'components/GALink';
import config from 'config/config';
import LuxityLoader from 'components/LuxityLoader';
import withRouter from 'helpers/withRouter';
import RequestInfoSection from '../../components/RequestInfoSection';

const request = {
  fetching: false,
  failed: false,
  success: false,
  errorCode: null,
  errorMsg: '',
};

const VoidSale = ({ params: { sale_id, token }, history }) => {
  const saleToken = token.split('?')[0];
  const [requestState, setRequestState] = useState(request);

  const updateRequestState = (obj = {}) => {
    const newObj = {
      request,
      ...obj,
    };
    setRequestState(newObj);
  };

  useEffect(() => {
    updateRequestState({ fetching: true });
    axios
      .get(`${api.user.VOID_SALE}/${sale_id}/void/${saleToken}`)
      .then(() => {
        updateRequestState({ success: true });
        notifySuccess('Your sale has successfully been cancelled');
      })
      .catch((e) => {
        updateRequestState({ failed: true, errorCode: e.status });
        notifyError(requestErrorMessages.RESPONSE_500);
      });
  }, [history, sale_id, saleToken]);

  const getErrorMarkup = () => {
    notifyError(requestErrorMessages.RESPONSE_500);
    if (requestState.errorCode === 422) {
      return (
        <Fragment>
          <h4>We were unable to cancel this order.</h4>
          <div>
            This order cannot be cancelled at this time as there is a payment on
            this order, to cancel please email
            <a href="mailto:myorder@luxity.co.za" className="u-fc--primary">
              &nbsp; myorder@luxity.co.za
            </a>
          </div>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <h4>We were unable to cancel this order.</h4>
        <div>
          Please contact
          <GALink
            href={`${config.LUXITY_SHOP_URL}/bug-report`}
            target="_blank"
            className="u-fc--primary"
            rel="noopener noreferrer"
            label="Go to Luxity Shop">
            <span> support </span>
          </GALink>
          for further assistance.
        </div>
        <div>
          Or click
          <GARoute to="/" className="u-fc--primary">
            <span> here </span>
          </GARoute>
          to go back to
          {hasAuth() ? ' Dashboard' : ' Login'}
        </div>
      </Fragment>
    );
  };

  return (
    <div className="u-text--center u-padding">
      {requestState.success && (
        <Fragment>
          <RequestInfoSection
            title="Order has been successfully cancelled."
            button_link={config.LUXITY_SHOP_URL}
            button_text="Shop Now"
          />
        </Fragment>
      )}

      {requestState.fetching && (
        <Fragment>
          <h4 className="u-fc--primary">
            Order is in the process of being cancelled
          </h4>
          <LuxityLoader />
        </Fragment>
      )}

      {requestState.failed && getErrorMarkup()}
    </div>
  );
};

export default withRouter(VoidSale);
