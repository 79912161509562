import CacheHelper from './cache';

/**
 * Cache helper designed for array values
 * @param {string} key - the key under which to manage the cache values
 * @param {number} expiresInMinutes - the number of minutes after which the isExpired function will be true
 */
const ExpireableCache = (key, expiresInMinutes) => {
  const BasicCache = CacheHelper(key);
  const CacheExpiry = CacheHelper(`${key}-expiry`);

  const msToMinutes = (ms) => {
    return ms / 1000 / 60;
  };

  const minutesToMs = (min) => {
    return min * 60 * 1000;
  };

  const isExpired = () => {
    if (!expiresInMinutes) return false;

    const expiryTimestamp = CacheExpiry.get() || 0; // initial expiry timestamp (0) will return: true
    const minuteDifference = msToMinutes(Date.now() - expiryTimestamp);
    if (minuteDifference > expiresInMinutes) {
      return true;
    }
    return false;
  };

  const set = (value) => {
    BasicCache.set(value);
    if (!expiresInMinutes) return;
    CacheExpiry.set(Date.now() + minutesToMs(expiresInMinutes));
  };

  return { ...BasicCache, set, isExpired };
};

export default ExpireableCache;
