/** @format */

import { GetProfileActionTypes, UpdateAccountActionTypes } from '../actions';
//
// type StateShape = {
//   profile_data: Object,
//   account_data: Object,
// };
//
const initialState = {
  profile_data: null,
  account_data: null,
};
//
// type PayloadShape = {
//   type: string,
//   payload: Object,
//   error: Object,
// };

const dataReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case GetProfileActionTypes.success:
      return {
        ...state,
        profile_data: {
          ...payload,
        },
      };
    case UpdateAccountActionTypes.success: {
      return {
        ...state,
        profile_data: {
          ...state.profile_data,
          update_account: false,
        },
      };
    }
    default:
      return state;
  }
};

export default dataReducer;
