import React from 'react';
import PropTypes from 'prop-types';
import GetSvg from 'vendor/GetSvg';

const Notification = ({ containerClassName, svgName, renderContent }) => (
  <div className={containerClassName}>
    {svgName && <GetSvg svg={svgName} wrapperClass="svg" />}

    {renderContent()}
  </div>
);

Notification.propTypes = {
  containerClassName: PropTypes.string,
  svgName: PropTypes.string,
  renderContent: PropTypes.func.isRequired,
};

Notification.defaultProps = {
  containerClassName: 'notification',
  svgName: 'light_bulb',
};

export default Notification;
