import React, { useRef, Fragment } from 'react';
import api from 'constants/api';
import {
  success as notifySuccess,
  error as notifyError,
} from '@bonlineza/luxity-lib/Notify';
import IconHeader from 'components/IconHeader';
import AxiosPageReadyWrapper, {
  AxiosPageReadyContext,
} from 'components/AxiosPageReadyWrapper';
import requestErrorMessages from 'constants/requestErrorMessages';
import { formatOptsForProvincesAndCountries } from 'views/AddressesView/formatOptsForProvincesAndCountries.func';
import Addresses from './components/Addresses';

const AddressesView = () => {
  const addressesPageReady = useRef();

  const getActionCallback = (name) => {
    switch (name) {
      case 'update_address':
        return () => {
          addressesPageReady.current.refreshPage();
          notifySuccess('Address Updated');
        };

      case 'delete_address':
        return () => {
          addressesPageReady.current.refreshPage();
          notifySuccess('Address Deleted');
        };

      case 'create_address':
        return () => {
          addressesPageReady.current.refreshPage();
          notifySuccess('Address Added');
        };

      case 'generic_error':
        return () => {
          notifyError(requestErrorMessages.RESPONSE_500);
        };

      default:
        return false;
    }
  };

  return (
    <Fragment>
      <IconHeader title="Addresses" svgName="account" />
      <AxiosPageReadyWrapper
        ref={addressesPageReady}
        url={api.user.GET_PROFILE}>
        <AxiosPageReadyContext.Consumer>
          {({
            data: {
              shipping_addresses = [],
              billing_addresses = [],
              country_list = [],
              province_list = [],
            },
          }) => (
            <Addresses
              shipping_addresses={shipping_addresses}
              billing_addresses={billing_addresses}
              actionCallback={getActionCallback}
              provinceOptions={formatOptsForProvincesAndCountries(
                province_list,
              )}
              countryOptions={formatOptsForProvincesAndCountries(country_list)}
            />
          )}
        </AxiosPageReadyContext.Consumer>
      </AxiosPageReadyWrapper>
    </Fragment>
  );
};

export default AddressesView;
