import React, { Fragment } from 'react';
import GARoute from 'components/GARoute';
import withRouter from 'helpers/withRouter';

const Sidebar = ({ menuItems = [], location }) => {
  const generateMenuItem = () =>
    menuItems.map((group = [], index) => (
      <div className="sidebar__group" key={`side-bar-item-${index}`}>
        <div className="sidebar__group__title">
          <div className="sidebar__group__title__content">
            {group.titleInnerText}
          </div>
        </div>

        <div className="sidebar__group__items">
          {group.subFields.map((subItem, subItemIndex) => (
            <Fragment key={`side-bar-sub-item-${subItemIndex}`}>
              <GARoute
                {...subItem}
                to={`${subItem.route}`}
                label={subItem.innerText}
                className={location.pathname === subItem.route ? 'active' : ''}>
                {subItem.innerText}
              </GARoute>
            </Fragment>
          ))}
        </div>
      </div>
    ));

  return (
    <Fragment>
      <div className="sidebar">{generateMenuItem()}</div>
    </Fragment>
  );
};

export default withRouter(Sidebar);
