import React, { Fragment, useState } from 'react';
import api from 'constants/api';
import axios from 'helpers/axios';
import AxiosPageReadyWrapper, {
  AxiosPageReadyContext,
} from 'components/AxiosPageReadyWrapper';
import Item from './component/Item';
import { getMutateNotificationsQuery, getNotificationsQuery } from './query';
import ArrayCacheHelper from '../../helpers/ArrayCache';
import constants from './component/constants';

const cacheExpiryMinutes = 5;

const LuxityNotificationBanner = () => {
  const [notifications, setNotifications] = useState([]);
  const notificationCache = ArrayCacheHelper(
    constants.notificationCacheKey,
    cacheExpiryMinutes,
  );
  const seenIdListCache = ArrayCacheHelper(constants.seenIdListKey);

  const processNotifications = (data) => {
    const {
      data: {
        data: { luxity_notifications = [] },
      },
    } = data;
    if (notificationCache.isExpired()) {
      notificationCache.set(luxity_notifications.data);
      processNotificationsCache();
    } else {
      processNotificationsCache();
    }
  };

  const processNotificationsCache = () => {
    const unseen_notifications = getUnseenNotifications();
    if (unseen_notifications.length) {
      setNotifications(unseen_notifications);
    }
    const seenIdList = seenIdListCache.get();
    if (seenIdList.length) {
      postSeenIdListForUser(seenIdList);
    }
  };

  const dismissNotification = (notification_id) => {
    seenIdListCache.add(notification_id);
    processNotificationsCache();
  };

  const postSeenIdListForUser = (seenIdList) => {
    return axios
      .post(api.graphql(), { query: getMutateNotificationsQuery(seenIdList) })
      .catch(() => {});
  };

  const getUnseenNotifications = () => {
    const seenIdList = seenIdListCache.get();
    const list = notificationCache.get() || [];
    return list.filter((item) => !seenIdList.includes(item.id));
  };

  return (
    <div>
      <AxiosPageReadyWrapper
        method="post"
        url={api.graphql()}
        postBody={getNotificationsQuery()}
        onData={processNotifications}>
        <AxiosPageReadyContext.Consumer>
          {() => {
            return (
              <Fragment>
                {notifications.length > 0 &&
                  notifications.map((notification, key) => {
                    return (
                      <Fragment key={key}>
                        <Item
                          notificationData={notification}
                          closeNotification={dismissNotification}
                        />
                      </Fragment>
                    );
                  })}
              </Fragment>
            );
          }}
        </AxiosPageReadyContext.Consumer>
      </AxiosPageReadyWrapper>
    </div>
  );
};

export default LuxityNotificationBanner;
