import { categories } from 'constants/categories';

export const getCategoryImage = (category, isWhiteBg) => {
  const baseUrl = '/assets/svgs/';
  const extension = '.svg';

  let fileName;
  switch (category) {
    case categories.SHOES:
      fileName = 'shoes';
      break;
    case categories.WATCHES:
      fileName = 'watches';
      break;
    case categories.APPAREL:
      fileName = 'clothing';
      break;
    case categories.ACCESSORIES:
      fileName = 'accessories';
      break;
    case categories.BAGS:
      fileName = 'bags';
      break;
    default:
      fileName = 'accessories';
  }

  return `${baseUrl}${fileName}${isWhiteBg ? '_gray_bg' : ''}${extension}`;
};

export const getItemCategoriesCount = (items) => {
  const categoryCounts = {};

  items.forEach((item) => {
    const { category_type } = item;
    if (categoryCounts[category_type]) {
      categoryCounts[category_type] += 1;
    } else {
      categoryCounts[category_type] = 1;
    }
  });

  const sortedCategories = Object.keys(categoryCounts).sort();
  const categoryStrings = sortedCategories.map((category) => {
    const count = categoryCounts[category];
    const categoryLabel = getCategoryLabel(category);
    return `${count > 1 ? count : ''} ${categoryLabel}`;
  });

  return categoryStrings.join(', ');
};

export const getCategoryLabel = (category) => {
  switch (category) {
    case categories.SHOES:
      return 'Shoes';
    case categories.WATCHES:
      return 'Watches';
    case categories.APPAREL:
      return 'Apparel';
    case categories.ACCESSORIES:
      return 'Accessories';
    case categories.BAGS:
      return 'Bags';
    case categories.JEWELLERY:
      return 'Jewellery';
    case categories.SCARVES:
      return 'Scarves';
    case categories.WALLETS:
      return 'Wallets';
    case categories.SUNGLASSES:
      return 'Sunglasses';
    case categories.PENS:
      return 'Pens';
    default:
      return category;
  }
};
