import React, { useState } from 'react';
import { SimplePopup } from '@bonlineza/b-lib';
import {
  error as notifyError,
  success as notifySuccess,
} from '@bonlineza/luxity-lib/Notify';
import api from 'constants/api';
import axios from 'helpers/axios';
import LuxityLoader from '../LuxityLoader';
import requestErrorMessages from '../../constants/requestErrorMessages';

const ConfirmationModal = ({ isOpen, close, data }) => {
  if (!isOpen) {
    return null;
  }

  const BaseRequest = {
    loading: false,
    success: false,
    failed: false,
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [voidRequestState, setVoidRequestState] = useState(BaseRequest);

  const voidSale = (sale_id = '') => {
    setVoidRequestState({ ...BaseRequest, loading: true });
    return axios
      .post(`${api.user.CANCEL_ORDER}/${sale_id}/void`)
      .then(() => {
        setVoidRequestState({ ...BaseRequest, success: true });
        notifySuccess('Sale has successfully void');
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch(() => {
        closeVoidModal();
        notifyError(requestErrorMessages.RESPONSE_500);
        setVoidRequestState({ ...BaseRequest, failed: true });
      });
  };

  const getVoidOptions = () => {
    const { loading } = voidRequestState;
    return [
      {
        cb: () => closeVoidModal(),
        buttonText: 'Cancel',
        disabled: loading,
        buttonClass: 'modal__container__footer__item__button--secondary-button',
        dataQeId: 'button-submit',
      },
      {
        cb: () => voidSale(data.id),
        buttonText: 'Confirm Void',
        disabled: loading,
        buttonClass:
          'modal__container__footer__item__button--primary-button--bold',
        dataQeId: 'button-submit',
      },
    ];
  };

  const closeVoidModal = () => close(false);

  return (
    <SimplePopup
      isOpen={isOpen}
      close={closeVoidModal}
      title="Cancel Order Confirmation"
      renderLoader={() => <LuxityLoader />}
      description="Would you like to cancel this order?"
      options={getVoidOptions()}
    />
  );
};

export default ConfirmationModal;
