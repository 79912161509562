import { hasValue } from '@bonlineza/b-lib/build/functions';

function appendCSSToHead(url = '') {
  if (hasValue(url)) {
    const link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = url;
    document.head.appendChild(link);
  }
}

export default appendCSSToHead;
