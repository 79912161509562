/** @format */

import authActions, { auth } from 'constants/auth';
import history from 'helpers/history';
import axios from 'helpers/axios';
import api from 'constants/api';
import { flushAuth, decryptAuth } from 'functions/authenticator.func.js';

export function refreshToken(dispatch) {
  dispatch(loginFetching());
  axios
    .get(api.user.GET_PROFILE)
    .then(() => {
      dispatch(decryptAuthToState());
    })
    .catch((e) => {
      return dispatch(loginFailed(e));
    });
}

export function unAuth() {
  // side affect to remove local storage
  flushAuth();
  // end

  // side affect to ensure that we are going home
  history.push('/');
  // end

  // result of this request is a side affect
  // unAuthRequest();
  // end

  return { type: auth.AUTH_REMOVE };
}

export function loginFetching() {
  return { type: authActions.fetching };
}

export function loginSuccess(payload) {
  return { type: authActions.success, payload: payload.data.payload };
}

export function loginFailed(payload) {
  return { type: authActions.failed, error: payload };
}

export function decryptAuthToState() {
  const payload = decryptAuth();
  return { type: authActions.success, payload };
}
