import React, { useContext, Fragment } from 'react';
import ListContext from 'vendor/GqlQueryList/config/ListContext.js';
import LeadItem from './LeadItem';
import { sortLeads } from '../../utils/sortLeads';

const LeadList = () => {
  const context = useContext(ListContext);
  const { list } = context;

  const sortedList = sortLeads(list);

  return (
    <Fragment>
      <div className="sales">
        {sortedList.length > 0 &&
          sortedList.map((submission, key) => (
            <LeadItem index={key} key={key} data={submission} />
          ))}
      </div>
    </Fragment>
  );
};

export default LeadList;
