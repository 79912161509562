import React from 'react';
import { Event } from 'components/Tracking';

const GALink = (props) => {
  const gaOnClick = (event) => {
    Event('Link', `Click on ${props.href}`, props.label);

    if (typeof props.onClick === 'function') {
      props.onClick(event);
    }
  };

  return (
    <a href="/" {...props} onClick={gaOnClick}>
      {props.children}
    </a>
  );
};

export default GALink;
