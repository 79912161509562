import React, { Fragment, useState } from 'react';
import GAButton from 'components/GAButton';
import api from 'constants/api';
import axios from 'helpers/axios';
import LuxityLoader from 'components/LuxityLoader';
import { error as notifyError } from '@bonlineza/luxity-lib/Notify';
import requestErrorMessages from 'constants/requestErrorMessages';

export default function Index({ sale_id, profile, sale_outstanding_amount }) {
  const [isLoading, setIsLoading] = useState(false);
  const availableCustomerCredit = profile.customer_credits || 0;
  const usableCustomerCredit =
    availableCustomerCredit > sale_outstanding_amount
      ? sale_outstanding_amount
      : availableCustomerCredit;

  const handleClick = () => {
    setIsLoading(true);
    axios
      .post(api.data.PAY_WITH_CREDIT, {
        sale_id,
        credit_total: usableCustomerCredit,
      })
      .then((response) => {
        const { sale_order_number, amount } = response.data.payload;
        window.location.href = `/credit-payment-result?sale_order_number=${
          sale_order_number || ''
        }&amount=${amount || ''}`;
      })
      .catch(() => {
        notifyError(requestErrorMessages.RESPONSE_500);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const makePaymentButton = () => {
    return isLoading ? (
      <LuxityLoader />
    ) : (
      <GAButton
        className="btn--luxity make-payment-button"
        label="Make Credit Payment"
        onClick={(e) => {
          e.preventDefault();
          handleClick();
        }}>
        MAKE PAYMENT USING CREDIT
      </GAButton>
    );
  };

  return (
    <Fragment>
      <div className="accordion-container">
        <div className="desktop-payment-container">
          <div className="payment__content">
            <span>
              <b>Use my Credit: R</b>
              <b>{usableCustomerCredit.toLocaleString()}</b>
              <span> (available: R </span>
              {availableCustomerCredit.toLocaleString()}
              <span>)</span>
            </span>
            <span className="payment-button">{makePaymentButton()}</span>
          </div>
        </div>

        <div className="mobile-payment-container">
          <div className="payment__content">
            <span>
              <b>Use my Credit: R</b>
              <b>{usableCustomerCredit.toLocaleString()}</b>
              <span> (available: R </span>
              {availableCustomerCredit.toLocaleString()}
              <span>)</span>
            </span>
            <div>{makePaymentButton()}</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
