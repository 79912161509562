export default (statusList = [], statusPosition = null) => {
  const statusPoints = [];
  if (statusPosition !== null) {
    statusList.forEach((item, key) => {
      statusPoints[key] = {
        className: `step-${statusList.length}-gauge__pointer${
          statusPosition >= key ? '--active' : ''
        }`,
        stepNumber: key + 1,
        tooltipText: item,
      };
    });
  }
  return statusPoints;
};
