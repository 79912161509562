/** @format */

import constants from './constants';

const { ADD_SVG_TO_STORE, LOADING_SVG } = constants;

const initialState = {
  arrow_right: '',
  calendar_circled: '',
  calendar: '',
  close: '',
  file_attach_circled: '',
  file_attach: '',
  pointer_down: '',
  check_circled: '',
  tick: '',
};

export default function getSvgReducer(state = initialState, { type, data }) {
  switch (type) {
    case ADD_SVG_TO_STORE:
      return {
        ...state,
        ...{ [data.name]: data.html },
      };
    case LOADING_SVG:
      return {
        ...state,
        ...{ [data.name]: '' },
      };
    default:
      return state;
  }
}
