import ExpireableCache from './ExpireableCache';

/**
 * Cache helper designed for array values
 * @param {string} key - the key under which to manage the cache values
 * @param {number} expiresInMinutes - the number of minutes after which the isExpired function will be true (0 value prevents expiry)
 */
const ArrayCacheHelper = (key, expiresInMinutes = 0) => {
  const BasicCache = ExpireableCache(key, expiresInMinutes);

  const get = () => BasicCache.get() || [];

  const set = (value) => {
    if (Array.isArray(value)) {
      BasicCache.set(value);
    }
  };

  const add = (value) => {
    set(get().concat(value));
  };

  const hasValues = () => !!get().length;

  return {
    get,
    set,
    add,
    hasValues,
    isExpired: BasicCache.isExpired,
  };
};

export default ArrayCacheHelper;
