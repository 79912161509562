import React, { useState, useRef, useEffect } from 'react';
import { getUserLuxityLeadsQuery } from 'components/Submissions/query';
import AxiosPageReadyWrapper from 'components/AxiosPageReadyWrapper';
import api from 'constants/api';
import { leadStatuses } from 'constants/leadStatuses';
import LeadDetails from './components/LeadDetails';
import { sortLeads } from './utils/sortLeads';

const DeclinedLeads = ({ profile, updateDeclinedLeads, refreshTrigger }) => {
  const [leads, setLeads] = useState([]);
  const [showItems, setShowItems] = useState({});
  const pageReadyWrapperRef = useRef(null);

  useEffect(() => {
    pageReadyWrapperRef.current.refreshPage();
  }, [refreshTrigger]);

  const getLeads = (data) => {
    const {
      data: {
        data: { luxity_leads = { data: [] } },
      },
    } = data;

    const sortedLeads = sortLeads(luxity_leads.data);

    setLeads(sortedLeads);
    updateDeclinedLeads(sortedLeads);
  };

  const handleIconClick = (leadId) => {
    setShowItems((prev) => ({
      ...prev,
      [leadId]: !prev[leadId],
    }));
  };

  return (
    <AxiosPageReadyWrapper
      ref={pageReadyWrapperRef}
      method="post"
      url={api.graphql()}
      postBody={{
        query: getUserLuxityLeadsQuery(20, profile.email, [
          leadStatuses.DECLINED,
        ]),
      }}
      onData={getLeads}>
      {leads.length > 0 && (
        <LeadDetails
          leads={leads}
          showItems={showItems}
          handleIconClick={handleIconClick}
        />
      )}
    </AxiosPageReadyWrapper>
  );
};

export default DeclinedLeads;
