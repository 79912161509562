/** @format */
import React, { Fragment } from 'react';

import { Checkbox as Switch } from 'semantic-ui-react';
import TooltipButton from 'components/ModalTooltip/TooltipButton';
import PropTypes from 'prop-types';
import l from '../../helpers/locale';

class FormField extends React.Component {
  static defaultProps = {
    itemClass: '',
    label: '',
    labelTooltip: '',
    labelClass: 'form__item__label',
    inputClass: 'form__item__input',
    type: 'text',
    value: null,
    description: '',
    descriptionClass: 'form__item__description',
    errorFields: {},
    mandatory: false,
    containerClass: '',
    placeholder: '',
    viewOnly: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      isChecked: props.type === 'switch' && props.value,
      checkLabel:
        props.type === 'switch'
          ? this.getCheckLabel(props.value, props.meta)
          : '',
    };
  }

  componentDidMount() {
    this.props.controlRef(this.props.name, this.props.value || '');
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.viewOnly) {
      this.setError(false);
      return null;
    }

    if (
      Object.keys(nextProps.errorFields).includes(this.props.name) &&
      !this.state.hasError
    ) {
      return this.setError(true);
    }

    if (
      nextProps.viewOnly !== this.props.viewOnly &&
      nextProps.viewOnly === false
    ) {
      return this.resetCheckedState();
    }
    return false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.viewOnly && !this.props.viewOnly) {
      this.props.controlRef(this.props.name, this.props.value);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getCheckLabel(value, meta) {
    return meta && value ? meta.onLabel : meta.offLabel || '';
  }

  setError = (nextVal = false) =>
    this.setState((prevState) => ({
      ...prevState,
      hasError: nextVal,
    }));

  getSwitchProps = () => {
    let props = {};
    if (this.props.viewOnly) {
      props = {
        ...props,
        readOnly: true,
        disabled: this.props.viewOnly,
        checked: !!this.props.value,
      };
    } else {
      props = {
        ...props,
        readOnly: false,
        checked: !!this.state.isChecked,
      };
    }
    return props;
  };

  resetCheckedState = () =>
    this.setState((prevState) => ({
      ...prevState,
      isChecked: this.props.type === 'switch' && this.props.value,
      checkLabel:
        this.props.type === 'switch'
          ? this.getCheckLabel(this.props.value, this.props.meta)
          : '',
    }));

  toggleChangeHandler = (_, nextToggle) => {
    this.props.controlRef(this.props.name, nextToggle.checked);
    this.setState((prevState) => ({
      ...prevState,
      isChecked: nextToggle.checked,
      checkLabel: this.getCheckLabel(nextToggle.checked, this.props.meta),
    }));
  };

  changeHandler = (newVal) => {
    if (this.state.hasError) {
      this.setError();
    }
    this.props.controlRef(this.props.name, newVal.target.value);
    return newVal.target.value;
  };

  render() {
    return (
      <Fragment>
        <div
          className={`${(this.state.hasError && `has-error `) || ''}
            ${this.props.itemClass}`}>
          {(this.props.label && (
            <label
              htmlFor={this.props.name}
              className={this.props.labelClass || 'form__item__label'}>
              {l(this.props.label)}
              {this.props.labelTooltip ? (
                <TooltipButton
                  linkText={this.props.labelTooltipLinkText}
                  text={this.props.labelTooltip}
                />
              ) : null}
              {(!this.props.viewOnly && this.props.mandatory && '*') || ''}
            </label>
          )) ||
            null}
          {(this.props.type === 'switch' && (
            <Switch
              toggle
              onChange={this.toggleChangeHandler}
              {...this.getSwitchProps()}
              label={this.state.checkLabel}
            />
          )) ||
            (this.props.type === 'textarea' && (
              <textarea
                className="form__item__textarea"
                placeholder={this.props.placeholder}
                onChange={this.changeHandler}
                defaultValue={this.props.value}
              />
            )) ||
            (this.props.viewOnly && (
              <div className="text--dk--flushed">{this.props.value}</div>
            )) || (
              <input
                data-qe-id={`action-input-${this.props.name}_field`}
                type={this.props.type}
                name={this.props.name}
                defaultValue={this.props.value}
                className={`${this.props.inputClass} p-2`}
                onChange={this.changeHandler}
                placeholder={this.props.placeholder}
              />
            )}

          {this.state.hasError && (
            <div className="form__item__validation">
              {l(this.props.errorFields[this.props.name])}
            </div>
          )}
        </div>
        {this.props.description !== '' && (
          <div className={this.props.descriptionClass}>
            <span>{l(this.props.description)}</span>
          </div>
        )}
      </Fragment>
    );
  }
}

FormField.propTypes = {
  label: PropTypes.string, // display value of label
  labelTooltip: PropTypes.string,
  name: PropTypes.string.isRequired, // name of input
  itemClass: PropTypes.string,
  containerClass: PropTypes.string,
  labelClass: PropTypes.string,
  inputClass: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOf([PropTypes.string, PropTypes.number, PropTypes.bool]),
  description: PropTypes.string,
  descriptionClass: PropTypes.string,
  errorFields: PropTypes.object,
  placeholder: PropTypes.string,
  viewOnly: PropTypes.bool,
  mandatory: PropTypes.bool,
  controlRef: PropTypes.func.isRequired,
};

export default FormField;
