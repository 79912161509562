import React, { Fragment, useEffect, useCallback } from 'react';
import GARoute from 'components/GARoute';
import { Event } from 'components/Tracking';
import withRouter from 'helpers/withRouter';

const PaymentResult = ({
  history,
  location: { search },
  params: { status },
}) => {
  const sendGAForPayment = useCallback(() => {
    const parsed = new URLSearchParams(search);
    const sale_order_number = parsed.get('sale_order_number') || '';
    const amount = parsed.get('amount') || '';
    Event('Payment', status, sale_order_number, parseInt(amount, 10));
  }, [search, status]);

  const checkStatusToRedirect = useCallback(() => {
    if (status === 'success' || status === 'failure') {
      return sendGAForPayment();
    }
    return history.push('/');
  }, [history, sendGAForPayment, status]);

  useEffect(() => {
    checkStatusToRedirect();
  }, [checkStatusToRedirect]);

  const determineTitle = () => {
    switch (status) {
      case 'success':
        return <h2>Payment Successful</h2>;
      case 'failure':
        return <h2>Payment Failure..</h2>;
      default:
        return <h2>Something went wrong</h2>;
    }
  };

  return (
    <Fragment>
      {determineTitle()}
      <div>
        Click
        <GARoute to="/" label={`Payment Result: ${determineTitle()}`}>
          <span className="u-fc--primary"> here </span>
        </GARoute>
        to go back to the home page
      </div>
    </Fragment>
  );
};

export default withRouter(PaymentResult);
