/** @format */

import generator, { generateActions } from 'constants/generator';
import { simpleRequest } from 'helpers/axios';
import api, { method } from 'constants/api';

const GET_PROFILE = 'GET_PROFILE';
const UPDATE_PROFILE = 'UPDATE_PROFILE';
const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';

export const GetProfileActionTypes = generator(GET_PROFILE);
export const GetProfileActions = generateActions(GET_PROFILE);
export function get_profile(cb) {
  simpleRequest(method.GET, api.user.GET_PROFILE, null, cb);
}

export const UpdateProfileActionTypes = generator(UPDATE_PROFILE);
export const UpdateProfileActions = generateActions(UPDATE_PROFILE);
export function update_profile(payload, cb, failedCB = null) {
  simpleRequest(
    method.POST,
    api.user.UPDATE_PROFILE,
    UPDATE_PROFILE,
    payload,
    cb,
    null,
    failedCB,
  );
}

export const UpdateAccountActionTypes = generator(UPDATE_ACCOUNT);
export const UpdateAccountActions = generateActions(UPDATE_ACCOUNT);
export function update_account(payload, cb, failedCB = null) {
  simpleRequest(
    method.POST,
    api.user.BANK_DETAILS,
    UPDATE_ACCOUNT,
    payload,
    cb,
    null,
    failedCB,
  );
}
