import config from 'config/config';

const getSharedMenuItems = (navigateToPage) => [
  {
    titleClassName: 'mobile-side-menu__content__item__title',
    titleSvgName: 'my_order',
    titleSvgWrapperClassName: 'svg',
    titleInnerText: 'Orders',
    subFields: [
      {
        className: 'mobile-side-menu__content__item__link',
        subFieldName: 'orders',
        innerText: 'Current Orders',
        route: '/orders',
        callbackFn: navigateToPage.bind(null, '/orders'),
      },
      {
        className: 'mobile-side-menu__content__item__link',
        subFieldName: 'order_history',
        innerText: 'Past Orders',
        route: '/order-history',
        callbackFn: navigateToPage.bind(null, '/order-history'),
      },
    ],
  },

  {
    titleClassName: 'mobile-side-menu__content__item__title',
    titleSvgName: 'my_sale',
    titleSvgWrapperClassName: 'svg',
    titleInnerText: 'Sales',
    subFields: [
      {
        className: 'mobile-side-menu__content__item__link',
        innerText: 'Submissions',
        subFieldName: 'submissions',
        route: '/submissions',
        callbackFn: navigateToPage.bind(null, '/submissions'),
      },
      {
        className: 'mobile-side-menu__content__item__link',
        innerText: 'Sales & Consignments',
        subFieldName: 'on_consignment',
        route: '/sales',
        callbackFn: navigateToPage.bind(null, '/sales'),
      },
      {
        className: 'mobile-side-menu__content__item__link',
        innerText: 'Paid Out',
        subFieldName: 'paid_out',
        route: '/sales-history',
        callbackFn: navigateToPage.bind(null, '/sales-history'),
      },
    ],
  },
];

export const getMenuFields = (navigateToPage = () => null) => [
  {
    titleClassName: 'mobile-side-menu__content__item__title',
    titleSvgName: 'account',
    titleSvgWrapperClassName: 'svg',
    titleInnerText: 'Account',
    subFields: [
      {
        className: 'mobile-side-menu__content__item__link',
        innerText: 'Dashboard',
        subFieldName: 'dashboard',
        route: '/',
        callbackFn: navigateToPage.bind(null, '/'),
      },
      {
        className: 'mobile-side-menu__content__item__link',
        innerText: 'Interests',
        subFieldName: 'my_interests',
        route: '/interests',
        callbackFn: navigateToPage.bind(null, '/interests'),
      },
      {
        className: 'mobile-side-menu__content__item__link',
        innerText: 'Addresses',
        subFieldName: 'my_addresses',
        route: '/addresses',
        callbackFn: navigateToPage.bind(null, '/addresses'),
      },
      {
        className: 'mobile-side-menu__content__item__link',
        innerText: 'Coupons',
        subFieldName: 'my_coupons',
        route: '/coupons',
        callbackFn: navigateToPage.bind(null, '/coupons'),
      },
      {
        className: 'mobile-side-menu__content__item__link',
        innerText: 'Edit Profile',
        subFieldName: 'edit_profile',
        route: '/profile',
        callbackFn: navigateToPage.bind(null, '/profile'),
      },
      {
        className: 'mobile-side-menu__content__item__link',
        innerText: 'Help',
        subFieldName: 'help',
        isExternal: true,
        route: `${config.LUXITY_SHOP_URL}/bug-report/`,
        callbackFn: () => {
          window.location.href = `${config.LUXITY_SHOP_URL}/bug-report/`;
        },
      },
    ],
  },
  ...getSharedMenuItems(navigateToPage),
];

export const getMobileMenuFields = (navigateToPage = () => null) => [
  {
    titleClassName: 'mobile-side-menu__content__item__title',
    titleSvgName: 'account',
    titleSvgWrapperClassName: 'svg',
    titleInnerText: 'Account',
    subFields: [
      {
        className: 'mobile-side-menu__content__item__link',
        innerText: 'Interests',
        subFieldName: 'my_interests',
        route: '/interests',
        callbackFn: navigateToPage.bind(null, '/interests'),
      },
      {
        className: 'mobile-side-menu__content__item__link',
        innerText: 'Addresses',
        subFieldName: 'my_addresses',
        route: '/addresses',
        callbackFn: navigateToPage.bind(null, '/addresses'),
      },
      {
        className: 'mobile-side-menu__content__item__link',
        innerText: 'Coupons',
        subFieldName: 'my_coupons',
        route: '/coupons',
        callbackFn: navigateToPage.bind(null, '/coupons'),
      },
      {
        className: 'mobile-side-menu__content__item__link',
        innerText: 'Edit Profile',
        subFieldName: 'edit_profile',
        route: '/profile',
        callbackFn: navigateToPage.bind(null, '/profile'),
      },
    ],
  },
  ...getSharedMenuItems(navigateToPage),
];
