export const getNotificationsQuery = () => {
  const params = [
    `page:1`,
    `limit: 10`,
    `expired: false`,
    `target: "client"`,
    `seen: false`,
  ].join(',');

  return {
    query: `{
      luxity_notifications(${params}) {
        data {
          id
          seen
          meta
          content
          can_dismiss
        }
        total
        per_page
        current_page
        from
        to
        last_page
      },
    }`,
  };
};

export const getMutateNotificationsQuery = (notificationsIdList = []) => `
  mutation notifications {
    ${notificationsIdList.map(
      (noteId, index) => `
        notification${index}: mark_notification_as_seen(notification_id:${noteId}) {
          id
        }
      `,
    )}
  }`;
