import React from 'react';
import SimpleSelect from 'react-select';
import {
  leadStatuses,
  leadItemStatuses,
  itemOfferChoices,
} from 'constants/leadStatuses';

const LeadItemSelection = ({
  lead,
  item,
  itemSelections,
  handleItemSelection,
  formatLabel,
}) => {
  const getItemValueLabel = (currentItem, currentSelections, currentLead) => {
    let selection = itemOfferChoices.DECLINE;
    if (currentSelections[currentLead.id]?.[currentItem.id]) {
      selection = currentSelections[currentLead.id]?.[currentItem.id];
    } else if (currentItem.consignment_price) {
      selection = itemOfferChoices.CONSIGNMENT;
    } else if (currentItem.cash_price) {
      selection = itemOfferChoices.CASH;
    }

    return {
      value: selection,
      label: formatLabel(selection),
    };
  };

  if (lead.status !== leadStatuses.SENT) {
    const statusDisplay = (() => {
      if (item.status === leadItemStatuses.USER_DECLINED) {
        return 'Declined';
      }
      if (item.status === leadItemStatuses.ACCEPTED) {
        return item.consignment ? 'Consignment' : 'Cash';
      }
      return '';
    })();

    return <div className="text-gray-700">{statusDisplay}</div>;
  }

  const selectionValueLabel = getItemValueLabel(item, itemSelections, lead);

  return (
    <SimpleSelect
      isMulti={false}
      isSearchable={false}
      components={{ IndicatorSeparator: () => null }}
      options={[
        ...(item.consignment_price
          ? [{ value: itemOfferChoices.CONSIGNMENT, label: 'Consignment' }]
          : []),
        ...(item.cash_price
          ? [{ value: itemOfferChoices.CASH, label: 'Cash' }]
          : []),
        { value: itemOfferChoices.DECLINE, label: 'Decline' },
      ]}
      onChange={(selectedOption) =>
        handleItemSelection(lead.id, item.id, selectedOption.value)
      }
      value={selectionValueLabel}
      styles={{
        control: (provided, state) => ({
          ...provided,
          border: 'none',
          borderBottom: '1px solid black',
          borderRadius: 'none',
          boxShadow: 'none',
          background: 'transparent',
          borderColor: state.isFocused ? 'grey' : 'black',
          '&:hover': {
            borderBottom: '2px solid grey',
          },
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        option: (provided) => ({
          ...provided,
          backgroundColor: '#f9f9f9',
          color: 'black',
          ':hover': {
            backgroundColor: 'black',
            color: 'white',
          },
          padding: 5,
        }),
        menu: (provided) => ({
          ...provided,
          marginTop: -2,
          borderRadius: '2px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          color: 'black',
        }),
      }}
    />
  );
};

export default LeadItemSelection;
