export default (errorData) => {
  const errorMessages = {};
  Object.keys(errorData).forEach((errPropName) => {
    errorMessages[errPropName] = Array.isArray(errorData[errPropName])
      ? errorData[errPropName].reduce((pv, cV) => cV, '')
      : errorData[errPropName];
  });
  return errorMessages;
};

/**
 * Return a useable validation message from Laravel JSON validartion responses
 * @param {Object} jsonResponse - the laravel json validation response
 * @param {string} defaultMessage - a fallback message when none is returned from backend
 */
export const getMessageFrom = (
  jsonResponse,
  defaultMessage = 'Unknown Error',
) => {
  if (jsonResponse && Object.keys(jsonResponse).length) {
    return Object.keys(jsonResponse)
      .map((k) => {
        return jsonResponse[k];
      })
      .join(', ');
  }
  if (jsonResponse?.message) {
    return jsonResponse.message;
  }
  return defaultMessage;
};
