import React from 'react';
import { Event } from 'components/Tracking';

const GAButton = (props) => {
  const gaOnClick = (event) => {
    Event('Button', 'Click', props.label);

    if (typeof props.onClick === 'function') {
      props.onClick(event);
    }
  };

  return (
    <button type="button" {...props} onClick={gaOnClick}>
      {props.children}
    </button>
  );
};

export default GAButton;
