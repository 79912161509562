import React, { Fragment, useEffect, useState } from 'react';

const LuxityRadioButtons = ({
  options = [],
  name = '',
  noWrap = false,
  title = '',
  errors = {},
}) => {
  const [errMsg, setErrMsg] = useState(errors[name]);

  useEffect(() => {
    setErrMsg(errors[name]);
  }, [errors, name]);

  return (
    <Fragment>
      <div className="luxity-radio-buttons-container">
        <div className="luxity-radio-buttons-container__title">{title}</div>
        <div
          className={`luxity-radio-buttons-container__list${
            noWrap ? `--no-wrap` : ''
          }`}>
          {options.map(
            (
              { actionCallback = () => null, value = '', innerText = '' },
              index,
            ) => (
              <label
                className="luxity-radio-buttons-container__list__item"
                key={`${name}-${index}`}
                htmlFor={`${name}-${index}`}>
                <input
                  id={`${name}-${index}`}
                  className="luxity-radio-buttons-container__list__item__input"
                  type="radio"
                  name={name}
                  onChange={() => {
                    setErrMsg('');
                    actionCallback();
                  }}
                  value={value}
                />
                <div className="luxity-radio-buttons-container__list__item__circle">
                  <div className="luxity-radio-buttons-container__list__item__circle__inner" />
                </div>
                <div className="luxity-radio-buttons-container__list__item__text">
                  {innerText}
                </div>
              </label>
            ),
          )}
        </div>
      </div>
      {errMsg && <div className="luxity-radio-buttons-error-msg">{errMsg}</div>}
    </Fragment>
  );
};

export default LuxityRadioButtons;
