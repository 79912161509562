import React from 'react';
import LuxityLoader from 'components/LuxityLoader';
import {
  followUpStatuses,
  leadStatuses,
  leadItemStatuses,
  itemOfferChoices,
} from 'constants/leadStatuses';

export const LeadActionButtons = ({
  lead,
  isSubmitting,
  updateLeadStatus,
  updateFollowUpStatus,
  itemSelections,
}) => {
  const getSelection = (item) => {
    return itemSelections?.[item.id] || itemOfferChoices.CONSIGNMENT;
  };

  const handleUpdateLeadStatus = (status) => {
    const updatedLeadItems = lead.lead_items.map((item) => {
      if (status === leadStatuses.DECLINED) {
        return {
          item_id: item.id.toString(),
          consignment: false,
          item_status: leadItemStatuses.USER_DECLINED,
        };
      }

      const selection = itemSelections?.[item.id] || getSelection(item);
      return {
        item_id: item.id.toString(),
        consignment: selection === itemOfferChoices.CONSIGNMENT,
        item_status:
          selection === itemOfferChoices.DECLINE
            ? leadItemStatuses.USER_DECLINED
            : leadItemStatuses.ACCEPTED,
      };
    });

    updateLeadStatus(lead.id, status, updatedLeadItems);
  };

  return (
    <div className="w-full flex gap-2 justify-end">
      {isSubmitting ? (
        <LuxityLoader />
      ) : (
        <>
          {lead.status === leadStatuses.SENT && (
            <>
              <button
                type="button"
                className="text-black focus:outline-none px-4 py-2.5 text-center inline-flex border border-black gap-2 hover:text-white hover:border-white hover:bg-black"
                onClick={() => handleUpdateLeadStatus(leadStatuses.DECLINED)}>
                DECLINE ALL
                <img
                  src="/assets/images/delete.png"
                  alt="delete"
                  width={15}
                  height={15}
                />
              </button>
              <button
                type="button"
                className="text-black focus:outline-none px-4 py-2.5 text-center inline-flex border border-black gap-2 hover:text-white hover:border-white hover:bg-black"
                onClick={() => handleUpdateLeadStatus(leadStatuses.ACCEPTED)}>
                PROCEED
                <img
                  src="/assets/images/checkmark.png"
                  alt="checkmark"
                  width={15}
                  height={15}
                />
              </button>
            </>
          )}

          {lead.status === leadStatuses.ACCEPTED && (
            <button
              disabled={lead.follow_up_status === followUpStatuses.ON_THEIR_WAY}
              type="button"
              className={`focus:outline-none px-4 py-2.5 text-center inline-flex items-center gap-2 ${
                lead.follow_up_status === followUpStatuses.ON_THEIR_WAY
                  ? 'text-[#4FA254] bg-[#EFEFEF] border border-[#EEEEEE]'
                  : 'text-black bg-white border border-black'
              }`}
              onClick={() => updateFollowUpStatus(lead.id)}>
              ON THE WAY
              <img
                src="/assets/svgs/received.svg"
                alt="delete"
                width={15}
                height={15}
              />
            </button>
          )}
        </>
      )}
    </div>
  );
};
