import React, { Fragment } from 'react';
import axios from 'helpers/axios';
import responseMessages from 'functions/responseMessages';
import api from 'constants/api';
import {
  removeWhiteSpaces,
  snakeCaseToTitleCase,
} from '@bonlineza/b-lib/functions';
import Form from 'vendor/Form';
import LuxityLoader from 'components/LuxityLoader';
import AxiosPageReadyWrapper from 'components/AxiosPageReadyWrapper.js';
import profileFormFields from './profileFormFields.js';
import PasswordConfirmation from '../PasswordConfirmation';
import { getCommunicationPreferenceOptions } from './query.js';

const BaseRequest = {
  loading: false,
  error: false,
  success: false,
};

class ProfileInformation extends React.Component {
  constructor() {
    super();
    this.state = {
      request: BaseRequest,
      errorMessages: {},
      editting: false,
      countryData: [],
      communicationPreferenceOptions: [],
      payload: {
        communication_preference: '',
      },
      password: null,
    };
  }

  componentDidMount = () => {
    this.getCountryCodes();
    this.getCommunicationPreferenceOptions();
  };

  setErrors = (errObj) => this.setState({ errorMessages: { ...errObj } });

  handleValidation = (payload) => {
    const errorMessages = {};
    Object.keys(payload).forEach((propName) => {
      if (
        ['first_name', 'surname', 'contact_number'].includes(propName) &&
        !payload[propName]
      ) {
        errorMessages[propName] = `${snakeCaseToTitleCase(
          propName,
        )} is mandatory`;
      }
    });

    const errObj = {
      fields: errorMessages,
      result: Object.keys(errorMessages).length === 0,
    };

    return {
      ...errObj,
    };
  };

  getCountryCodes = () => {
    axios.get(api.data.COUNTRY_CODES).then();
  };

  getCommunicationPreferenceOptions = () => {
    const url = `${api.graphql()}?gql_id=getCommunicationPreferenceOptions`;
    axios
      .post(url, {
        query: getCommunicationPreferenceOptions(),
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        this.setState({
          communicationPreferenceOptions: data.luxity_communication_preference,
        });
      });
  };

  getSubmit = (payload) => {
    const {
      first_name,
      email,
      country_code,
      contact_number,
      id_number,
      id_file,
      surname,
      whatsapp_number,
      whatsapp_country_code,
    } = payload;

    this.setState((prev) => {
      return {
        passwordConfirmationVisible: true,
        payload: {
          ...prev.payload,
          first_name: first_name.trim(),
          surname: surname.trim(),
          email, // isn't editable
          country_code, // form selection
          contact_number: removeWhiteSpaces(contact_number),
          id_number: removeWhiteSpaces(id_number || ''),
          id_file,
          // only submit whatsapp number if it is different from contact number
          whatsapp_number:
            removeWhiteSpaces(whatsapp_number) !==
            removeWhiteSpaces(contact_number)
              ? removeWhiteSpaces(whatsapp_number)
              : null,
          whatsapp_country_code:
            removeWhiteSpaces(whatsapp_number) !==
            removeWhiteSpaces(contact_number)
              ? whatsapp_country_code
              : null,
        },
      };
    });
  };

  handleBeforeSubmitPasswordValidation = (password = null) => {
    const errorMessages = {};
    if (!password) {
      errorMessages.password = 'Password is mandatory';
    }
    this.setErrors(errorMessages);
    return Object.keys(errorMessages).length === 0;
  };

  postProfileInfo = () => {
    const { password, payload } = this.state;
    if (this.handleBeforeSubmitPasswordValidation(password)) {
      this.updateRequest({ loading: true });
      const formData = new FormData();
      formData.append('password', password);

      Object.entries(payload).forEach(([key, value]) => {
        if (value && key !== 'id_file') {
          formData.append(key, value);
        }
      });

      if (typeof payload.id_file === 'object') {
        formData.append('id_file', payload.id_file);
      }

      axios
        .post(api.user.UPDATE_PROFILE, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          this.updateRequest({ success: true });
          this.props.actionCallback('update_profile')();
        })
        .catch((e) => {
          this.updateRequest({ failed: true });
          this.handleError(e.status, e.data);
        });
    }
  };

  handleError = (code, errorData = {}) => {
    if (code === 422) {
      const errorMessages = responseMessages(errorData);
      this.setState({
        errorMessages,
        // hide password confirmation if error is not password related
        passwordConfirmationVisible:
          Object.keys(errorMessages).includes('password'),
      });
      this.props.actionCallback('profile_error')();
    } else {
      this.props.actionCallback('generic_error')();
    }
  };

  updateRequest = (obj) =>
    this.setState((prevState) => ({
      ...prevState,
      request: {
        ...BaseRequest,
        ...obj,
      },
    }));

  switchEdit = () =>
    this.setState((prevState) => ({
      ...prevState,
      editting: !prevState.editting,
      errorMessages: {},
    }));

  closeModal = () => this.setState({ passwordConfirmationVisible: false });

  controlRef = (name, value) => {
    const { errorMessages } = this.state;
    const oldErrors = {
      ...errorMessages,
    };
    delete oldErrors[name];
    this.setState({
      [name]: !value ? null : value,
      errorMessages: oldErrors,
    });
  };

  onPreferenceChange = (preference) => {
    this.setState((prev) => {
      return {
        ...prev,
        payload: {
          ...prev.payload,
          communication_preference: preference?.value,
        },
      };
    });
  };

  getOptions = () => [
    {
      cb: () => this.closeModal(),
      buttonText: 'Cancel',
      buttonClass: 'modal__container__footer__item__button--secondary-button',
      dataQeId: 'button-cancel',
    },
    {
      cb: () => this.postProfileInfo(),
      buttonText: 'Submit',
      isDisabled: this.state.request.loading,
      buttonClass:
        'modal__container__footer__item__button--primary-button--bold',
      dataQeId: 'button-submit',
    },
  ];

  render = () => (
    <Fragment>
      <AxiosPageReadyWrapper
        url={api.data.COUNTRY_CODES}
        method="get"
        onData={({ data: { countries } }) => {
          this.setState({ countryData: countries });
        }}>
        <Fragment>
          <Form
            sections={profileFormFields(
              this.props.profile,
              this.state.countryData,
              this.state.communicationPreferenceOptions,
              this.onPreferenceChange,
              this.state.payload?.communication_preference,
            )}
            submitAct={this.getSubmit}
            isProcessing={this.state.request.loading}
            afterSubmitErrors={this.state.errorMessages}
            validateBefore={this.handleValidation}
            submitButtonString="Save"
            submitButtonClass="text-button"
            cancelAct={this.switchEdit}
            viewOnly={!this.state.editting}
            displayToEdit
            toEdit={this.switchEdit}
          />
          <PasswordConfirmation
            renderDescription={() => (
              <p>Enter you password to verify your Profile Update request</p>
            )}
            isOpen={this.state.passwordConfirmationVisible}
            onClose={this.closeModal}
            isLoading={this.state.request.loading}
            options={this.getOptions()}
            controlRef={this.controlRef}
            errors={this.state.errorMessages}
          />
        </Fragment>
      </AxiosPageReadyWrapper>
      {this.state.request.loading && <LuxityLoader />}
    </Fragment>
  );
}

export default ProfileInformation;
