import { saveJson, getJson } from '../functions/storage';

/**
 * Stores JSON data in local storage
 * @param {string} key - the key under which to save your data object
 */
const CacheHelper = (key) => {
  return {
    shouldReCache: () => !getJson(key),
    get: () => getJson(key),
    set: (value) => saveJson(key, value),
  };
};

export default CacheHelper;
