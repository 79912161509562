import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

const withRouter = (WrappedComponent) => (props) => {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();

  return (
    <WrappedComponent
      {...props}
      params={params}
      location={location}
      history={history}
    />
  );
};

export default withRouter;
