import React, { useState, useRef } from 'react';
import { getUserLuxityLeadsQuery } from 'components/Submissions/query';
import AxiosPageReadyWrapper from 'components/AxiosPageReadyWrapper';
import api from 'constants/api';
import axios from 'helpers/axios';
import { NotifyError, NotifySuccess } from '@bonlineza/luxity-lib';
import requestErrorMessages from 'constants/requestErrorMessages';
import { leadStatuses } from 'constants/leadStatuses';
import LeadDetails from './components/LeadDetails';
import { sortLeads } from './utils/sortLeads';

const Leads = ({ profile, updateLeads, triggerRefresh }) => {
  const [leads, setLeads] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showItems, setShowItems] = useState({});
  const pageReadyWrapperRef = useRef(null);

  const getLeads = (data) => {
    const {
      data: {
        data: { luxity_leads = { data: [] } },
      },
    } = data;

    const sortedLeads = sortLeads(luxity_leads.data);

    setLeads(sortedLeads);
    updateLeads(sortedLeads);
  };

  const handleIconClick = (leadId) => {
    setShowItems((prev) => ({
      ...prev,
      [leadId]: !prev[leadId],
    }));
  };

  const updateLeadStatus = async (leadId, status, leadItems = []) => {
    setIsSubmitting(true);
    try {
      const requestBody = {
        lead_id: leadId,
        lead_status: status,
        lead_items: leadItems,
      };

      await axios.post(api.data.UPDATE_LEAD_STATUS, requestBody);

      if (status === leadStatuses.ACCEPTED) {
        NotifySuccess('Offer Accepted');
      } else if (status === leadStatuses.DECLINED) {
        NotifySuccess('Offer Declined');
      }
      setIsSubmitting(false);
      triggerRefresh();
      pageReadyWrapperRef.current.refreshPage();
    } catch (error) {
      NotifyError(requestErrorMessages.RESPONSE_500);
      setIsSubmitting(false);
    }
  };

  const updateFollowUpStatus = async (leadId) => {
    setIsSubmitting(true);
    try {
      await axios.post(api.data.UPDATE_FOLLOW_UP_STATUS, {
        lead_id: leadId,
      });
      NotifySuccess('On The Way');
      setIsSubmitting(false);
      pageReadyWrapperRef.current.refreshPage();
    } catch (error) {
      NotifyError(requestErrorMessages.RESPONSE_500);
      setIsSubmitting(false);
    }
  };

  return (
    <AxiosPageReadyWrapper
      ref={pageReadyWrapperRef}
      method="post"
      url={api.graphql()}
      postBody={{
        query: getUserLuxityLeadsQuery(20, profile.email, [
          leadStatuses.SENT,
          leadStatuses.ACCEPTED,
          leadStatuses.RECEIVED,
        ]),
      }}
      onData={getLeads}>
      {leads.length > 0 && (
        <LeadDetails
          leads={leads}
          showItems={showItems}
          handleIconClick={handleIconClick}
          updateLeadStatus={updateLeadStatus}
          updateFollowUpStatus={updateFollowUpStatus}
          isSubmitting={isSubmitting}
        />
      )}
    </AxiosPageReadyWrapper>
  );
};

export default Leads;
