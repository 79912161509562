import React from 'react';
import PageReady from '@bonlineza/b-lib/PageReady';
import EllipsisLoader from '@bonlineza/b-lib/EllipsisLoader';
import buildAxiosRequest from 'functions/buildAxiosRequest.func';
import PropTypes from 'prop-types';

export const AxiosPageReadyContext = React.createContext({ data: null });

class AxiosPageReadyWrapper extends React.Component {
  static defaultProps = {
    url: '',
    method: 'get',
    postBody: {},
    customErrorMessage: '',
    customErrorHandler: () => null,
    renderCustomLoader: () => null,
    onData() {
      return true;
    },
  };

  state = {
    data: null,
  };

  refreshPage = () => this.pageReadyInstance.initRequest();

  render() {
    const {
      url,
      method,
      postBody,
      customErrorMessage,
      customErrorHandler,
      renderCustomLoader,
      onData,
      children,
    } = this.props;

    const { data } = this.state;

    return (
      <PageReady
        ref={(inst) => {
          this.pageReadyInstance = inst;
        }}
        onData={(res) => {
          this.setState({ data: res.data });
          onData(res);
        }}
        getRequestInstance={() => buildAxiosRequest(url, method, postBody)}
        customErrorMessage={customErrorMessage}
        customErrorHandler={customErrorHandler}
        renderCustomLoader={() => renderCustomLoader() || <EllipsisLoader />}>
        <AxiosPageReadyContext.Provider value={{ data }}>
          {children}
        </AxiosPageReadyContext.Provider>
      </PageReady>
    );
  }
}

AxiosPageReadyWrapper.propTypes = {
  url: PropTypes.string,
  method: PropTypes.string,
  postBody: PropTypes.object,
  customErrorMessage: PropTypes.string,
  customErrorHandler: PropTypes.func,
  renderCustomLoader: PropTypes.func,
  onData: PropTypes.func,
};

export default AxiosPageReadyWrapper;
