/**
 * @param options
 * @returns {{label: *, value: *}[]}
 * @options: [{ name: string, code: string }] = []
 * @description formats provinces and country options for ReactSelect
 */

export const formatOptsForProvincesAndCountries = (options = []) =>
  options.map(({ name = '', code = '' }) => ({
    value: code,
    label: name,
  }));
