/** @format */

import React from 'react';
import { connect } from 'react-redux';
import { setTooltip, clearTooltip } from './actions';

const TooltipButton = ({
  linkText = '[?]',
  text = '',
  setTooltipAction,
  overrideClassName,
}) => (
  <button
    type="button"
    className={overrideClassName || `tooltip-button`}
    onClick={() => setTooltipAction(text)}>
    {linkText}
  </button>
);

const mapActions = (dispatch) => ({
  clearTooltipAction() {
    return dispatch(clearTooltip());
  },
  setTooltipAction(text) {
    return dispatch(setTooltip(text));
  },
});

const mapState = ({ tooltipStore }) => ({
  tooltip: tooltipStore.data,
});

export default connect(mapState, mapActions)(TooltipButton);
