import React, { useState, useCallback } from 'react';
import IconHeader from 'components/IconHeader';
import withRouter from 'helpers/withRouter';
import api from 'constants/api';
import AxiosPageReadyWrapper from 'components/AxiosPageReadyWrapper';
import Leads from 'components/Submissions/Leads';
import Submissions from 'components/Submissions/Submissions';
import DeclinedSubmissions from 'components/Submissions/DeclinedSubmissions';
import DeclinedLeads from 'components/Submissions/DeclinedLeads';
import NoSale from 'components/NoSale';

const SubmissionsView = () => {
  const [profile, setProfile] = useState(null);
  const [status, setStatus] = useState({
    hasDeclinedSubmissions: false,
    hasDeclinedLeads: false,
    hasLeads: false,
    hasSubmissions: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [loadingCount, setLoadingCount] = useState(4);
  const [refreshTrigger, setRefreshTrigger] = useState(false);

  const handleDataFetchComplete = useCallback(() => {
    setLoadingCount((prevCount) => prevCount - 1);
    if (loadingCount - 1 === 0) {
      setIsLoading(false);
    }
  }, [loadingCount]);

  const updateStatus = (key) => (data) => {
    setStatus((prevStatus) => ({ ...prevStatus, [key]: data.length > 0 }));
    handleDataFetchComplete();
  };

  const triggerRefresh = () => {
    setRefreshTrigger((prev) => !prev);
  };

  const { hasDeclinedSubmissions, hasDeclinedLeads, hasLeads, hasSubmissions } =
    status;
  const showDeclinedSection = hasDeclinedSubmissions || hasDeclinedLeads;
  const allEmpty =
    !showDeclinedSection && !hasLeads && !hasSubmissions && !isLoading;

  return (
    <>
      <IconHeader title="Submissions" svgName="my_sale" />
      <AxiosPageReadyWrapper
        url={api.user.GET_PROFILE}
        onData={(res) => setProfile(res.data)}>
        <div className="mt-5 flex flex-col gap-5">
          <Submissions updateSubmissions={updateStatus('hasSubmissions')} />
          <Leads
            profile={profile}
            updateLeads={updateStatus('hasLeads')}
            triggerRefresh={triggerRefresh}
          />
          {showDeclinedSection && (
            <div className="flex items-center gap-4">
              <img
                src="/assets/images/declined-submission.png"
                alt="declined submission"
                width={25}
                height={25}
              />
              <h2 className="mt-1 uppercase">Declined</h2>
            </div>
          )}
          <DeclinedSubmissions
            updateDeclinedSubmissions={updateStatus('hasDeclinedSubmissions')}
            refreshTrigger={refreshTrigger}
          />
          <DeclinedLeads
            profile={profile}
            updateDeclinedLeads={updateStatus('hasDeclinedLeads')}
            refreshTrigger={refreshTrigger}
          />
          {allEmpty && <NoSale />}
        </div>
      </AxiosPageReadyWrapper>
    </>
  );
};

export default withRouter(SubmissionsView);
