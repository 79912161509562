import React, { useContext, Fragment } from 'react';
import ListContext from 'vendor/GqlQueryList/config/ListContext.js';
import SalesItem from './Item';
import NoSale from '../NoSale';

export default ({ footer = null }) => {
  const context = useContext(ListContext);
  const { list } = context;
  return (
    <Fragment>
      <div className="sales">
        {list.length > 0 ? (
          list.map((sale, key) => (
            <SalesItem index={key} key={key} data={sale} />
          ))
        ) : (
          <NoSale />
        )}
        {footer && list.length > 0 ? (
          <div className="sales__footer">{footer()}</div>
        ) : null}
      </div>
    </Fragment>
  );
};
