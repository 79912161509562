import React, { Component, Fragment } from 'react';
import CountryCodeSelect from '@bonlineza/luxity-lib/CountryCodeSelect';

const getDialCodeFromCountryCode = (data, countryCode) => {
  if (!data) return '';
  const item = data.find((country) => country.code === countryCode);
  return item ? `+${item.dial_code}` : null;
};

class PhoneNumberField extends Component {
  componentDidMount() {
    this.props.controlRef(
      this.props.countryCodeVar,
      this.props.countryCode || '',
    );
    this.props.controlRef(
      this.props.phoneNumberVar,
      this.props.phoneNumber || '',
    );
  }

  render = () => {
    const {
      className,
      phoneNumber,
      countryCode,
      countryCodeData,
      viewOnly,
      phoneNumberVar,
      countryCodeVar,
      placeholder,
      ...props
    } = this.props;
    return (
      <Fragment>
        <div className="form__item__contact-number">
          {viewOnly ? (
            <Fragment>
              {phoneNumber &&
                `(${getDialCodeFromCountryCode(
                  countryCodeData,
                  countryCode,
                )}) ${phoneNumber}`}
            </Fragment>
          ) : (
            <Fragment>
              <div className="form__item__contact-number__dial-code">
                <CountryCodeSelect
                  name={countryCodeVar}
                  className="dropdown-selector-container"
                  classNamePrefix="dropdown-selector"
                  onChange={(value) =>
                    props.controlRef(countryCodeVar, value?.value || null)
                  }
                  countryData={countryCodeData}
                  defaultCountryCode={countryCode || undefined}
                />
              </div>
              <div className="form__item__contact-number__separator" />
              <div className="form__item__contact-number__national">
                <input
                  type="number"
                  name={phoneNumberVar}
                  className="form__item__input"
                  onChange={(e) =>
                    props.controlRef(phoneNumberVar, e.target.value)
                  }
                  defaultValue={phoneNumber || ''}
                  placeholder={placeholder}
                />
              </div>
            </Fragment>
          )}
        </div>
        {props.errors[phoneNumberVar] ? (
          <div className="form__item__validation">
            {props.errors[phoneNumberVar]}
          </div>
        ) : null}
      </Fragment>
    );
  };
}

PhoneNumberField.defaultProps = {
  className: 'form__item--horizontal',
  countryCode: 'ZA',
  placeholder: 'Contact Number',
};

export default PhoneNumberField;
