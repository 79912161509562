import React from 'react';
import GetSvg from 'vendor/GetSvg';

export default ({ title, svgName, renderAppendage = null }) => (
  <h5 className="icon-header">
    <div className="icon-header__left">
      <GetSvg svg={svgName} wrapperClass="svg--hard-left" />
      {title}
    </div>
    <div className="icon-header__right">
      {renderAppendage && renderAppendage()}
    </div>
  </h5>
);
