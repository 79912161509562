export const purchaseStatusDefinitions = {
  Received:
    'We have received your item and booked it into our system. It is currently being photographed and conditioned before being listed.',
  Listed:
    'Your item has been listed online and is available for sale in one of our boutiques.',
  'On Quote':
    'A customer showed interest and we’ve sent out a quote for this item - now we’re waiting for the item to be paid!',
  'Partially Paid':
    'We have received at least the minimum deposit. The customer has up to 60 days to settle the full balance.',
  'Return Window':
    'This item has been shipped to the customer. We allow max. 14 days for an item to be returned (unused, security tags still intact) after which we can pay you out!',
  'Pending Payout':
    'Payment rounds occur on Mondays and Thursdays. Please ensure your banking details are up to date under your profile.',
  'Paid Out': 'We have paid you out for this item.',
  'Consignment Cancelled':
    'You have cancelled the consignment agreement and collected this item',
};
