import React, { Fragment, useState } from 'react';
import { leadStatuses } from 'constants/leadStatuses';
import {
  getCategoryImage,
  getItemCategoriesCount,
} from '../utils/getCategories';
import { LeadActionButtons } from './LeadActionButtons';
import { getLeadStatusText } from '../utils/getLeadStatusText';
import LeadItemSelection from './LeadItemSelection';

const LeadDetails = ({
  leads,
  showItems,
  handleIconClick,
  updateLeadStatus,
  updateFollowUpStatus,
  isSubmitting,
}) => {
  const [itemSelections, setItemSelections] = useState({});

  const handleItemSelection = (leadId, itemId, value) => {
    setItemSelections((prev) => ({
      ...prev,
      [leadId]: {
        ...(prev[leadId] || {}),
        [itemId]: value,
      },
    }));
  };

  function formatLabel(value) {
    if (!value) return 'Consignment';
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  return (
    <Fragment>
      <div className="md:block xm:hidden">
        <div className="flex w-[calc(100%-40px)] px-10 mb-1">
          <div className="w-1/5 font-bold">Offer ID</div>
          <div className="w-2/12 font-bold">Date</div>
          <div className="w-2/5 font-bold">Items by Category</div>
        </div>
        {leads.map((lead) => (
          <div key={lead.id} className="gap-2 mb-2 flex">
            <div className="w-full border border-gray-200">
              <div className="flex min-w-full px-10 py-2">
                <div className="w-1/5 text-gray-700">
                  O0-
                  {lead.id}
                </div>
                <div className="w-2/12 text-gray-700">{lead.created_at}</div>
                <div className="w-2/5 text-gray-700">
                  {getItemCategoriesCount(lead.lead_items)}
                </div>
                {getLeadStatusText(lead.status)}
              </div>
              <div
                className={`overflow-hidden ${
                  showItems[lead.id] ? 'max-h-[1500px]' : 'max-h-0'
                }`}
                style={{
                  transition:
                    'transform 0.5s ease-in-out, max-height 0.5s ease-in-out',
                }}>
                <div className="px-5">
                  <div className="flex px-8 mb-1 mt-10">
                    <div className="w-2/12 font-bold">Category</div>
                    <div className="w-5/12 font-bold">Items</div>
                    <div className="w-3/12 font-bold">Consignment Offer</div>
                    <div className="w-2/12 font-bold">Cash Offer</div>
                    <div className="w-3/12 font-bold">Selection</div>
                  </div>
                  {lead.lead_items.map((item, itemIndex) => (
                    <div
                      key={itemIndex}
                      className={`flex items-center px-8 py-2 ${
                        itemIndex % 2 === 0 ? 'bg-[#EFEFEF]' : 'bg-white'
                      }`}>
                      <div className="w-2/12">
                        <img
                          src={getCategoryImage(
                            item.category_type,
                            itemIndex % 2 !== 0,
                          )}
                          alt={item.category_type}
                          width={30}
                          height={30}
                        />
                      </div>
                      <div className="w-5/12 text-gray-700">
                        {item.item_name}
                      </div>
                      <div className="w-3/12 text-gray-700">
                        {item.consignment_price
                          ? `R ${item.consignment_price.toLocaleString()}`
                          : '-'}
                      </div>
                      <div className="w-2/12 text-gray-700">
                        {item.cash_price
                          ? `R ${item.cash_price.toLocaleString()}`
                          : '-'}
                      </div>
                      <div className="w-3/12">
                        <LeadItemSelection
                          lead={lead}
                          item={item}
                          itemSelections={itemSelections}
                          handleItemSelection={handleItemSelection}
                          formatLabel={formatLabel}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                {lead.lead_notes && (
                  <div className="px-14 py-8 flex flex-col gap-2">
                    <div className="font-bold">Notes</div>
                    <div className="text-gray-700">{lead.lead_notes}</div>
                  </div>
                )}
                <div className="pl-14 pr-6 py-8 flex items-center justify-between">
                  <LeadActionButtons
                    lead={lead}
                    isSubmitting={isSubmitting}
                    updateLeadStatus={updateLeadStatus}
                    updateFollowUpStatus={updateFollowUpStatus}
                    itemSelections={itemSelections[lead.id]}
                  />
                </div>
              </div>
            </div>
            <div className="w-[40px] max-h-[31px] py-2 flex items-center justify-center border border-gray-200 cursor-pointer">
              <button
                type="button"
                onClick={() => handleIconClick(lead.id)}
                className="w-full text-2xl font-thin">
                {showItems[lead.id] ? '-' : '+'}
              </button>
            </div>
          </div>
        ))}
      </div>

      {leads.map((lead) => (
        <div
          key={lead.id}
          className="p-3 mb-2 border border-gray-200 block md:hidden">
          <button
            type="button"
            className="w-full text-left"
            onClick={() => handleIconClick(lead.id)}>
            <div className="flex flex-col space-y-2">
              <div className="flex justify-between">
                <div className="flex">
                  <div className="mr-1 font-bold">Offer ID:</div>
                  <div className="text-gray-700">
                    O0-
                    {lead.id}
                  </div>
                </div>
                <div className="font-bold text-end">
                  Date:&nbsp;
                  <span className="font-normal text-gray-700">
                    {lead.created_at}
                  </span>
                </div>
              </div>
              <div className="flex">
                <div className="mr-1 font-bold">Items by Category:</div>
                <div className="w-5/12 text-gray-700">
                  {getItemCategoriesCount(lead.lead_items)}
                </div>
              </div>
              <div className="flex justify-between">
                <div className="flex">
                  {lead.status !== leadStatuses.SENT && (
                    <div className="mr-1 font-bold">Status:</div>
                  )}
                  {getLeadStatusText(lead.status)}
                </div>
                <div className="mb-2 text-end text-4xl font-thin">
                  {showItems[lead.id] ? '-' : '+'}
                </div>
              </div>
            </div>
          </button>

          <div
            className={`overflow-hidden ${
              showItems[lead.id] ? 'max-h-[1500px]' : 'max-h-0'
            }`}
            style={{
              transition:
                'transform 0.5s ease-in-out, max-height 0.5s ease-in-out',
            }}>
            {lead.lead_items.map((item, itemIndex) => (
              <div
                className={`p-2 flex justify-between ${
                  itemIndex % 2 === 0 ? 'bg-[#EFEFEF]' : 'bg-white'
                }`}>
                <div className="space-y-2 px-1 w-full">
                  <div className="flex">
                    <div className="w-2/5 mr-1 font-bold">Items:</div>
                    <div className="w-3/5 text-gray-700">{item.item_name}</div>
                  </div>
                  <div className="flex">
                    <div className="w-2/5 mr-1 font-bold">
                      Consignment Offer:
                    </div>
                    <div className="w-3/5 text-gray-700">
                      {item.consignment_price
                        ? `R ${item.consignment_price.toLocaleString()}`
                        : '-'}
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-2/5 mr-1 font-bold">Cash Offer:</div>
                    <div className="w-3/5 text-gray-700">
                      {item.cash_price
                        ? `R ${item.cash_price.toLocaleString()}`
                        : '-'}
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-2/5 mr-1 font-bold">Selection:</div>
                    <div className="w-3/5 text-gray-700">
                      <LeadItemSelection
                        lead={lead}
                        item={item}
                        itemSelections={itemSelections}
                        handleItemSelection={handleItemSelection}
                        formatLabel={formatLabel}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {lead.lead_notes && (
              <div className="p-4 flex">
                <div className="w-1/5 font-bold">Notes:&nbsp;</div>
                <div className="w-4/5 text-gray-700">{lead.lead_notes}</div>
              </div>
            )}
            <div className="py-6 px-3 flex items-center justify-between">
              <LeadActionButtons
                lead={lead}
                isSubmitting={isSubmitting}
                updateLeadStatus={updateLeadStatus}
                updateFollowUpStatus={updateFollowUpStatus}
                itemSelections={itemSelections[lead.id]}
              />
            </div>
          </div>
        </div>
      ))}
    </Fragment>
  );
};

export default LeadDetails;
