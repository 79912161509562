import React from 'react';
import config from 'config/config.json';
import { LuxityDesktopHeader, LuxityMobileHeader } from '@bonlineza/luxity-lib';
import { logoutCompletely } from 'functions/authenticator.func';
import withRouter from 'helpers/withRouter';
import { NavbarAssets } from './Navbar/icons.tsx';
import { stores } from './Navbar/stores.tsx';
import { links } from './Navbar/links.tsx';
import '../../../../node_modules/@bonlineza/luxity-lib/build/bundle.css';

const LuxityMenu = ({ history }) => {
  const categoryElements = [
    {
      slug: 'womens',
      name: "Women's",
      category: '',
    },
    {
      slug: 'mens',
      name: "Men's",
      category: '',
    },
  ];
  const onDashboardClick = () => {
    history.push('/');
  };

  const onInterestClick = () => {
    history.push('/interests');
  };

  const onCartClick = () => {
    window.location.replace(links.cartLink);
  };

  const onSellToUsClick = () => {
    window.location.replace(links.sellToUsLink);
  };

  const onLuxityShopClick = () => {
    window.location.replace(`${config.LUXITY_SHOP_URL}/`);
  };

  const onAddressClick = () => {
    history.push('/addresses');
  };

  const onCouponsClick = () => {
    history.push('/coupons');
  };

  const onEditProfileClick = () => {
    history.push('/profile');
  };

  const onCurrentOrdersClick = () => {
    history.push('/orders');
  };

  const onPastOrdersClick = () => {
    history.push('/order-history');
  };

  const onSubmissionsClick = () => {
    history.push('/submissions');
  };

  const onPastSalesClick = () => {
    history.push('/sales');
  };

  const onPaidOutClick = () => {
    history.push('/sales-history');
  };

  const onSearch = () => {
    window.location.replace(links.productLink);
  };

  const onLogoClick = () => {
    window.location.replace(`${config.LUXITY_SHOP_URL}/`);
  };

  const onLogoutClick = () => {
    logoutCompletely();
  };

  return (
    <>
      <div className="xlg:hidden">
        <LuxityMobileHeader
          accordionIcon={NavbarAssets.accordionIcon}
          backIcon={NavbarAssets.backIcon}
          logoSrc={NavbarAssets.logoSrc}
          menuIcon={NavbarAssets.menuIcon}
          inputSearchIcon={NavbarAssets.inputSearchIcon}
          onSearch={onSearch}
          onLogoutClick={onLogoutClick}
          cartIcon={NavbarAssets.cartIcon}
          accountIcon={NavbarAssets.accountIcon}
          closeIcon={NavbarAssets.closeIcon}
          orderIcon={NavbarAssets.orderIcon}
          saleIcon={NavbarAssets.saleIcon}
          contactUsLink={links.contactUsLink}
          ourStoryLink={links.ourStoryLink}
          blogLink={links.blogLink}
          bugReportLink={links.bugReportLink}
          onLuxityShopClick={onLuxityShopClick}
          onDashboardClick={onDashboardClick}
          onInterestClick={onInterestClick}
          onAddressClick={onAddressClick}
          onCouponsClick={onCouponsClick}
          onEditProfileClick={onEditProfileClick}
          onCurrentOrdersClick={onCurrentOrdersClick}
          onPastOrdersClick={onPastOrdersClick}
          onSubmissionsClick={onSubmissionsClick}
          onPastSalesClick={onPastSalesClick}
          onPaidOutClick={onPaidOutClick}
          categoryElements={categoryElements}
          productsUrl={links.productLink}
          onLogoClick={onLogoClick}
          app
        />
      </div>
      <div className="xm:hidden xlg:block">
        <LuxityDesktopHeader
          accountIcon={NavbarAssets.accountIcon}
          cartIcon={NavbarAssets.cartIcon}
          interestIcon={NavbarAssets.interestIcon}
          logoSrc="/assets/images/luxity-logo.svg"
          categoryElements={categoryElements}
          newBrandsImage={NavbarAssets.newBrandsImage}
          bagsImage={NavbarAssets.bagsImage}
          shoesImage={NavbarAssets.shoesImage}
          clothingImage={NavbarAssets.clothingImage}
          accessoriesImage={NavbarAssets.accessoriesImage}
          watchesImage={NavbarAssets.watchesImage}
          stores={stores}
          contactNumber="010 020 6171"
          productsUrl={`${config.LUXITY_SHOP_URL}/products`}
          onInterestClick={onInterestClick}
          onCartClick={onCartClick}
          inputSearchIcon={NavbarAssets.inputSearchIcon}
          onSearch={onSearch}
          onSellToUsClick={onSellToUsClick}
          blogLink={links.blogLink}
          contactUsLink={links.contactUsLink}
          ourStoryLink={links.ourStoryLink}
          onLogoClick={onLogoClick}
          endpoint={`${config.API_URL}/graphql/public`}
          menuData={`${config.CLOUDFRONT_URL}/categories.json`}
          app
        />
      </div>
    </>
  );
};

export default withRouter(LuxityMenu);
