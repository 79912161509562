import React from 'react';
import { connect } from 'react-redux';
import GARoute from 'components/GARoute';
import { captureException } from 'functions/sentry';

const NotFoundPage = ({ isAuth }) => {
  captureException(new Error('NotFoundPage'));
  return (
    <div className="u-text--center u-padding">
      <h4 className="u-fc--primary">Not Found Page</h4>
      <div>
        Click
        <GARoute to="/" label="Link to dashboard from page not found">
          <span className="u-fc--primary"> here </span>
        </GARoute>
        <span> to go back to </span>
        {isAuth ? 'Dashboard' : 'Login Page'}
      </div>
    </div>
  );
};

const mapState = ({ authStore }) => ({
  isAuth: authStore.auth,
});

export default connect(mapState)(NotFoundPage);
