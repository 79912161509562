export const saveToLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const getFromLocalStorage = (key) => {
  return localStorage.getItem(key);
};

export const removeFromLocalStorage = (key) => {
  return localStorage.removeItem(key);
};

export const saveJson = (key, value) =>
  saveToLocalStorage(key, JSON.stringify(value));

export const getJson = (key) => JSON.parse(getFromLocalStorage(key));

export const saveToSessionStorage = (key, value) => {
  window.sessionStorage.setItem(key, value);
};

export const getFromSessionStorage = (key) => {
  return window.sessionStorage.getItem(key);
};

export const removeFromSessionStorage = (key) => {
  return window.sessionStorage.removeItem(key);
};

export const saveJsonToSessionStorage = (key, value) => {
  saveToSessionStorage(key, JSON.stringify(value));
};

export const getJsonFromSessionStorage = (key) =>
  JSON.parse(getFromSessionStorage(key));
