import React, { useState, useRef } from 'react';
import {
  error as notifyError,
  success as notifySuccess,
} from '@bonlineza/luxity-lib/Notify';
import api from 'constants/api';
import axios from 'helpers/axios';
import { useForm } from 'react-hook-form';
import EllipsisLoader from '@bonlineza/b-lib/EllipsisLoader';
import requestErrorMessages from '../../../constants/requestErrorMessages';

const CouponForm = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);

  const onSubmit = (formData) => {
    setIsLoading(true);
    clearErrors(['coupon_code']);
    return axios
      .post(`${api.user.COUPON}`, formData)
      .then(() => {
        setIsLoading(false);
        notifySuccess('Coupon successfully saved.');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch(({ data, status }) => {
        if (status === 500) {
          notifyError(requestErrorMessages.RESPONSE_500);
        }
        setError('coupon_code', {
          type: 'custom',
          message: data.coupon_code,
        });
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return <EllipsisLoader />;
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      formRef?.current.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true }),
      );
    }
  };

  return (
    <div className="coupon-form">
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <input
          type="text"
          className="coupon-textfield"
          onKeyDown={handleKeyPress}
          placeholder=" Add coupon code"
          {...register('coupon_code', {
            required: 'The coupon code field is required.',
          })}
        />
        {errors.coupon_code && (
          <span className="coupons__error-message">
            {errors?.coupon_code?.message}
          </span>
        )}
      </form>
    </div>
  );
};

export default CouponForm;
