/** @format */

import axios from 'axios';
import { generateActions } from 'constants/generator';
import { auth } from 'constants/auth';
import history from './history';
import store from '../store';
import {
  setToken,
  getToken,
  logoutCompletely,
} from '../functions/authenticator.func.js';

export const superSimpleRequest = (
  url,
  method = 'get',
  payload = {},
  onSuccess = () => null,
  onFailed = () => null,
  requestConfigParams = null,
) =>
  axiosInstance()
    [method.toLowerCase()](url, payload, requestConfigParams)
    .then((res) => {
      onSuccess(res);
      return res;
    })
    .catch((err) => {
      onFailed(err);
      return err;
    });

export const simpleRequest = (
  method,
  url,
  baseAction,
  payload = {},
  cb = null,
  config = null,
  failedCB = null,
) => {
  const actionFunctions = generateActions(baseAction);
  store.dispatch(actionFunctions.fetching());
  axiosInstance()
    [method.toLowerCase()](url, payload, config)
    .then((res) => {
      if (cb) {
        cb(res);
      }
      return store.dispatch(actionFunctions.success(res));
    })
    .catch((err) => {
      if (failedCB) {
        failedCB(err);
      }
      return store.dispatch(actionFunctions.failed(err));
    });
};

function getEncodedSearch(currentUrl) {
  const splitUrl = currentUrl.split('?');
  const baseUrl = splitUrl[0];
  const nextUrlSegments = splitUrl[1].split('&');
  let nextQueryString = splitUrl[1];

  if (nextUrlSegments.length > 0) {
    nextQueryString = nextUrlSegments
      .map((v) => {
        let nextValue = v;

        if (v.includes('search=')) {
          const temp = v.split('=');
          nextValue = `${temp[0]}=${encodeURIComponent(temp[1])}`;
        }

        return nextValue;
      })
      .join('&');
  }

  return `${baseUrl}?${nextQueryString}`;
}

const axiosInstance = () => {
  const instance = axios.create({
    validateStatus: (status) => status >= 200 && status < 300,
  });
  let token = getToken();
  if (token !== '') {
    instance.defaults.headers.common.Authorization = token;
  }

  instance.defaults.headers.common['Content-Type'] =
    'application/json;charset=UTF-8';

  // Add a request interceptor
  instance.interceptors.request.use(
    (request, error) => {
      if (error) {
        return Promise.reject(error.response);
      }

      if (request.url.includes('search') && request.url.includes('?')) {
        // do some uri manipulation for ie
        // https://stackoverflow.com/questions/18220057

        // eslint-disable-next-line no-param-reassign
        request.url = getEncodedSearch(request.url);
      }

      // eslint-disable-next-line no-param-reassign
      request.headers.common['Content-Type'] =
        'application/json; charset=utf-8';
      if (typeof request.body === 'undefined') {
        request.body = {};
      }

      token = getToken();
      if (token !== '') {
        // eslint-disable-next-line no-param-reassign
        request.headers.common.Authorization = token;
      }
      return request;
    },
    (error) => Promise.reject(error.message),
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    (response, error) => {
      if (error) {
        return Promise.reject(error.response);
      }
      if (response.headers.authorization) {
        setToken(response.headers.authorization);
      }
      return response;
    },
    (error) => {
      if (error.response) {
        // network error
        if (
          (error.response.data &&
            error.response.data.error &&
            (error.response.data.error === 'token_invalid' ||
              error.response.data.error === 'token_not_provided' ||
              error.response.data.error === 'user_not_found' ||
              error.response.data.error === 'token_expired')) ||
          error.response.status === 401 ||
          error.response.status === 503 ||
          error.response.status === 400
        ) {
          logoutCompletely();
          store.dispatch({ type: auth.AUTH_REMOVE });
        }

        // if (error.response.status === 500) {
        //   // INTERNAL SERVER ERROR
        //   store.dispatch({
        //     type: AppConstants.OVERLAY.SERVER_ERROR,
        //   });
        //   return Promise.reject(error.response);
        // }

        if (error.response.status === 403) {
          // FORBIDDEN
          history.push('/');
        }

        return Promise.reject(error.response);
      }

      return Promise.reject(error);
    },
  );

  return instance;
};

export default axiosInstance();
