/** @format */
import { CLEAR_TOOLTIP, SET_TOOLTIP } from '../constants';

export function clearTooltip() {
  return {
    type: CLEAR_TOOLTIP,
  };
}

export function setTooltip(text) {
  return {
    type: SET_TOOLTIP,
    payload: {
      text,
    },
  };
}
