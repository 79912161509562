import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const ListPaginator = ({ showMore }) => {
  return (
    <Fragment>
      <div className="show-more-btn-container">
        <button
          type="button"
          className="btn--base show-more-btn"
          onClick={showMore}>
          <div className="flex gap-4 items-center justify-center mt-1">
            Show More
            <img
              src="/assets/images/view-more.png"
              alt="view more"
              width={15}
              height={15}
              className="self-start"
            />
          </div>
        </button>
      </div>
    </Fragment>
  );
};

ListPaginator.propTypes = {
  showMore: PropTypes.func.isRequired,
};

export default ListPaginator;
