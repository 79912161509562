import React from 'react';
import { Link } from 'react-router-dom';
import { Event } from 'components/Tracking';

const GARoute = ({
  subFieldName,
  innerText,
  callbackFn,
  isExternal,
  ...props
}) => {
  const gaOnClick = (event) => {
    Event('Link', `Click on ${props.to}`, props.label);

    if (typeof props.onClick === 'function') {
      props.onClick(event);
    }
  };

  // external links dont follow the normal 'Router' components
  return isExternal ? (
    <a
      {...props}
      href={props.route}
      onClick={gaOnClick}
      target="_blank"
      rel="noopener noreferrer">
      {props.children}
    </a>
  ) : (
    <Link {...props} onClick={gaOnClick}>
      {props.children}
    </Link>
  );
};

export default GARoute;
