/** @format */
import React from 'react';
import { connect } from 'react-redux';
import { hasAuth } from 'functions/authenticator.func.js';
import LuxityLoader from 'components/LuxityLoader';
import { refreshToken } from '../actions/auth';

const App = ({ hasProfile, refreshTokenRequest, children, refreshing }) => {
  if (hasAuth()) {
    // trigger token refresh
    if (!hasProfile && !refreshing) {
      refreshTokenRequest();
      return null;
    }
    if (!hasProfile && refreshing) {
      return <LuxityLoader />;
    }
    if (hasProfile && !refreshing) {
      return children;
    }
  }
  return null;
};

const mapState = ({ authStore }) => ({
  hasProfile: !!authStore.user.id,
  isAuth: authStore.auth,
  refreshing: authStore.request.fetching,
});

const mapActions = (dispatch) => ({
  refreshTokenRequest: () => refreshToken(dispatch),
});

export default connect(mapState, mapActions)(App);
