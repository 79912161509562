/** @format */

import generator from './generator';

export const auth = {
  AUTH_REMOVE: 'AUTH_REMOVE',
  AUTH_ADD: 'AUTH_ADD',
};

export default generator('AUTH');
