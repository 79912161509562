import React, { Fragment } from 'react';
import api from 'constants/api';
import payMethods from 'constants/payMethods';
import axios from 'helpers/axios';
import IconHeader from 'components/IconHeader';
import AxiosPageReadyWrapper from 'components/AxiosPageReadyWrapper';
import { snakeCaseToTitleCase } from '@bonlineza/b-lib/functions';
import LuxityLoader from 'components/LuxityLoader';
import GAButton from 'components/GAButton';
import { error as notifyError } from '@bonlineza/luxity-lib/Notify';
import requestErrorMessages from 'constants/requestErrorMessages';
import withRouter from 'helpers/withRouter';
import checkMinimumPaymentAmount from 'functions/checkMinimumPaymentAmount';
import scrollToElement from 'functions/scrollToElement';
import PaymentItem from './components/PaymentItem';

const initialProcessState = {
  is_processing: false,
};

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialProcessState,
      hasScrolled: false,
      order: {},
      paygate: {},
      sales_id: props.params.order_number,
      payment_methods: [],
      usable_customer_credit: 0,
      field_validation_err_exists: false,
      fields: {
        current_payment_method: {
          value: null,
          validation_msg: null,
        },
        amount: {
          value: null,
          validation_msg: null,
        },
      },
    };

    this.assignFormRef = (formElement) => {
      this.formElement = formElement;
    };

    this.paymentInputRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.params.order_number && !this.state.hasScrolled) {
      window.setTimeout(() => {
        const targetElement = document.getElementById('payment-method-section');
        scrollToElement(targetElement);
        this.setState({ hasScrolled: true });
      }, 500);
    }
  }

  reintializeProcessState = () => {
    this.setState({
      ...initialProcessState,
    });
  };

  submitPaygateForm = () => {
    this.formElement.submit();
  };

  sendPayment = () => {
    this.reintializeProcessState();
    const {
      fields: {
        amount: { value: paymentAmount },
      },
    } = this.state;
    this.setState({ is_processing: true });
    axios
      .post(api.data.PAY_OUTSTANDING_AMOUNT, {
        amount: paymentAmount,
        sale_id: this.state.sales_id,
      })
      .then((res) => {
        this.setState(
          {
            paygate: res.data,
          },
          () => {
            this.submitPaygateForm();
          },
        );
      })
      .catch((e) => {
        const { status, data } = e;
        this.afterSubmitErrors(status, data);

        this.setState({
          is_processing: false,
        });
      });
  };

  afterSubmitErrors = (status = null, data = {}) => {
    if (status === 422) {
      const key = Object.keys(data)[0];
      const validationMsg = data.amount[0] || '';

      return this.setState((prevState) => ({
        ...prevState,
        field_validation_err_exists: true,
        fields: {
          ...prevState.fields,
          [key]: {
            ...prevState.fields.amount,
            validation_msg: validationMsg,
          },
        },
      }));
    }
    return notifyError(requestErrorMessages.RESPONSE_500);
  };

  handleCustomerCreditPayment = () => {
    this.reintializeProcessState();
    const { usable_customer_credit } = this.state;
    this.setState({ is_processing: true });
    axios
      .post(api.data.PAY_WITH_CREDIT, {
        credit_total: usable_customer_credit,
        sale_id: this.state.sales_id,
      })
      .then((response) => {
        const { sale_order_number, amount } = response.data.payload;
        window.location.href = `/credit-payment-result?sale_order_number=${
          sale_order_number || ''
        }&amount=${amount || ''}`;
      })
      .catch(() => {
        notifyError(requestErrorMessages.RESPONSE_500);
        setTimeout(() => {
          this.props.history.push('/orders');
          window.location.reload();
        }, 3000);
      })
      .finally(() => {
        this.setState({
          is_processing: false,
        });
      });
  };

  handleValidation = () => {
    this.setState({ field_validation_err_exists: false });
    const dataKeys = Object.keys(this.state.fields);
    Object.values(this.state.fields).forEach((field, key) => {
      switch (true) {
        case this.paymentInputRef.current.validity.badInput:
          this.setState((prevState) => ({
            ...prevState,
            field_validation_err_exists: true,
            fields: {
              ...prevState.fields,
              amount: {
                ...prevState.fields.amount,
                validation_msg: `This field only accepts numbers`,
              },
            },
          }));
          break;

        case !field.value:
          this.setState((prevState) => ({
            ...prevState,
            field_validation_err_exists: true,
            fields: {
              ...prevState.fields,
              [dataKeys[key]]: {
                ...prevState.fields[dataKeys[key]],
                validation_msg: `${snakeCaseToTitleCase(
                  dataKeys[key],
                )} is mandatory`,
              },
            },
          }));
          break;

        case parseInt(this.state.fields.amount.value, 10) >
          parseInt(this.state.order.outstanding_balance, 10): {
          this.setState((prevState) => ({
            ...prevState,
            field_validation_err_exists: true,
            fields: {
              ...prevState.fields,
              amount: {
                ...prevState.fields.amount,
                validation_msg:
                  'The amount you are paying is more than the outstanding balance',
              },
            },
          }));

          break;
        }
        case parseInt(this.state.fields.amount.value, 10) < 0: {
          this.setState((prevState) => ({
            ...prevState,
            field_validation_err_exists: true,
            fields: {
              ...prevState.fields,
              amount: {
                ...prevState.fields.amount,
                validation_msg: 'Amount needs to be bigger than 0',
              },
            },
          }));

          break;
        }

        case checkMinimumPaymentAmount(
          this.state.order.outstanding_balance,
          this.state.order.order_total,
          this.state.fields.amount.value,
        ): {
          this.setState((prevState) => ({
            ...prevState,
            field_validation_err_exists: true,
            fields: {
              ...prevState.fields,
              amount: {
                ...prevState.fields.amount,
                validation_msg:
                  'The payment amount must be at least 25% of the order total.',
              },
            },
          }));

          break;
        }

        default:
          break;
      }
    });
    this.setState(
      (prevState) => ({
        ...prevState,
      }),
      () => {
        if (!this.state.field_validation_err_exists) {
          this.sendPayment();
        }
      },
    );
  };

  handleInputChange = (value = null, fieldName) => {
    this.setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [fieldName]: {
          ...prevState.fields[fieldName],
          value,
          validation_msg: null,
        },
      },
    }));
  };

  handleGetPayInfoError = (status = null) =>
    status === 404 ? this.props.history.push('/') : false;

  render = () => (
    <Fragment>
      {this.props.params.order_number ? (
        <AxiosPageReadyWrapper
          url={`${api.data.GET_PAYMENT_INFO}/${this.props.params.order_number}`}
          customErrorHandler={this.handleGetPayInfoError}
          onData={({
            data: { order, payment_methods, usable_customer_credit },
          }) =>
            this.setState({
              order,
              payment_methods,
              usable_customer_credit,
            })
          }>
          <Fragment>
            <IconHeader title="Make Payment" svgName="my_payment" />

            <div className="orders">
              <PaymentItem data={this.state.order} />
            </div>

            <h5>Select Payment Method</h5>

            {this.state.fields.current_payment_method.validation_msg && (
              <div className="u-fc--primary">
                {this.state.fields.current_payment_method.validation_msg}
              </div>
            )}
            <div
              id="payment-method-section"
              className={`section${
                this.state.fields.current_payment_method.validation_msg
                  ? '--highlighted'
                  : ''
              }`}>
              {this.state.payment_methods.map((method, key) => (
                <div
                  key={key}
                  className={`payment-radio-button${
                    this.state.fields.current_payment_method.value ===
                    method.key
                      ? `--active`
                      : ''
                  }`}>
                  <label htmlFor={`payment_method-${key}`}>
                    <div className="payment-radio-button__outer-circle">
                      <div className="payment-radio-button__outer-circle__inner" />
                    </div>
                    <input
                      type="radio"
                      id={`payment_method-${key}`}
                      name="current_payment_method"
                      value={method.key}
                      onChange={(e) => {
                        e.preventDefault();
                        this.handleInputChange(
                          e.target.value,
                          'current_payment_method',
                        );
                      }}
                    />
                    <span>{method.name}</span>
                  </label>
                </div>
              ))}
            </div>

            {this.state.fields.current_payment_method.value ===
              payMethods.CREDIT_CARD && (
              <Fragment>
                <h5>Enter Pay Amount</h5>
                {this.state.fields.amount.validation_msg && (
                  <div className="payment-error-msg">
                    {this.state.fields.amount.validation_msg}
                  </div>
                )}
                <div
                  className={`section${
                    this.state.fields.amount.validation_msg
                      ? '--highlighted'
                      : ''
                  }`}>
                  <div className="gw">
                    <div className="g g-1/2">Enter Amount*</div>
                    <div className="g g-1/2 u-text--right">
                      <input
                        className={`${
                          this.state.fields.amount.validation_msg
                            ? 'border-brand'
                            : ''
                        }`}
                        name="amount"
                        ref={this.paymentInputRef}
                        onChange={(e) => {
                          e.preventDefault();
                          this.handleInputChange(e.target.value, 'amount');
                        }}
                        type="number"
                        placeholder="R"
                      />

                      {this.state.is_processing ? (
                        <LuxityLoader />
                      ) : (
                        <GAButton
                          className="bg-white px-3 py-4 border border-black uppercase"
                          label="Make Payment"
                          onClick={(e) => {
                            e.preventDefault();
                            this.handleValidation();
                          }}>
                          <div className="flex gap-4 items-center">
                            Make Payment
                            <img
                              src="/assets/images/make-payment.png"
                              alt="make-payment"
                              width={15}
                              height={15}
                            />
                          </div>
                        </GAButton>
                      )}
                    </div>
                  </div>
                </div>
              </Fragment>
            )}

            {this.state.fields.current_payment_method.value ===
              payMethods.EFT && (
              <Fragment>
                <h5>Bank Details</h5>
                <p>For EFT Payment, please use the following bank details</p>
                <div className="section">
                  <div>Bank: FNB</div>
                  <div>Branch Code: 250 655</div>
                  <div>Account Type: Cheque</div>
                  <div>
                    <br />
                  </div>
                  <div>Account Name: Luxity (pty) ltd</div>
                  <div>
                    <b>Account Number: 62556319509</b>
                  </div>
                  <div>
                    <b>{`Reference: ${this.state.order.order_number}`}</b>
                  </div>
                </div>
              </Fragment>
            )}

            {this.state.fields.current_payment_method.value ===
              payMethods.CUSTOMER_CREDIT && (
              <div className="section">
                <div className="u-text--right">
                  {this.state.is_processing ? (
                    <LuxityLoader />
                  ) : (
                    <GAButton
                      className="bg-white px-3 py-4 border border-black uppercase"
                      label="Make Payment"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleCustomerCreditPayment();
                      }}>
                      <div className="flex gap-4 items-center">
                        Make Payment
                        <img
                          src="/assets/images/make-payment.png"
                          alt="make-payment"
                          width={15}
                          height={15}
                        />
                      </div>
                    </GAButton>
                  )}
                </div>
              </div>
            )}
          </Fragment>
        </AxiosPageReadyWrapper>
      ) : (
        'No Order number supplied'
      )}

      <form
        id="hidden_form"
        ref={this.assignFormRef}
        action={this.state.paygate.url || ''}
        method="POST">
        <input
          type="hidden"
          name="PAY_REQUEST_ID"
          value={this.state.paygate.PAY_REQUEST_ID || ''}
        />
        <input
          type="hidden"
          name="CHECKSUM"
          value={this.state.paygate.CHECKSUM || ''}
        />
        <input type="submit" className="u-hidden" value="SUBMIT" />
      </form>
    </Fragment>
  );
}

export default withRouter(Payment);
