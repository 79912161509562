import config from 'config/config.json';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export const canRunSentry = () =>
  config.APP_ENV === 'production' && typeof Sentry !== 'undefined';

export const initSentry = () => {
  if (canRunSentry()) {
    Sentry.init({
      dsn: 'https://36cd17be90d344fcb947c9a1993c3723@sentry.bonline.co.za/12',
      integrations: [new BrowserTracing()],
    });
  }
};

export const captureException = (
  error = new Error('Capture Exception Error'),
) => {
  if (canRunSentry()) {
    Sentry.captureException(error);
  }
};
