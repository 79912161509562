import React, { useEffect } from 'react';
import { setToken, delToken } from '../../functions/authenticator.func.js';

const AutoAuth = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const redirectUrl = urlParams.get('redirect_url');
    delToken();

    if (token && redirectUrl) {
      setToken(`Bearer ${token}`);
      const destinationUrl = `${window.location.origin}/${redirectUrl}`;
      window.location.assign(destinationUrl); // Redirect to the specified URL
    } else {
      window.location.assign('/');
    }
  }, []);
  return (
    <div className="u-text--center u-padding">
      <h4 className="u-fc--primary">Redirecting...</h4>
    </div>
  );
};

export default AutoAuth;
