import React, { Fragment } from 'react';
import GetSvg from 'vendor/GetSvg';
import { Link } from 'react-router-dom';
import { getItemCategoriesCount } from 'components/Submissions/utils/getCategories';
import { getLeadStatusText } from 'components/Submissions/utils/getLeadStatusText';
import { leadStatuses } from 'constants/leadStatuses';

export default ({ data, index }) => {
  return (
    <Fragment>
      <div className="latest-sale-desktop-container">
        <div className="sale-headings px-8 w-[calc(100%-50px)]">
          {index === 0 && (
            <div className="w-1/5">
              <strong>Offer ID</strong>
            </div>
          )}
          {index === 0 && (
            <div className="w-2/12">
              <strong>Date</strong>
            </div>
          )}
          {index === 0 && (
            <div className="w-2/5">
              <strong>Items by Category</strong>
            </div>
          )}
        </div>
        <div className="dashboard-content">
          <div className="dashboard-content__row px-8">
            <div className="w-1/5">
              O0-
              {data.id}
            </div>
            <div className="w-2/12">
              <div>{data.created_at}</div>
            </div>
            <div className="w-2/5">
              {getItemCategoriesCount(data.lead_items)}
            </div>
            <div>{getLeadStatusText(data.status)}</div>
          </div>
          <span className="sale-content-button">
            <div className="sale-action-button">
              <div className="headings-desktop">
                <Link to={{ pathname: '/submissions', target_id: data.id }}>
                  <GetSvg svg="eye" wrapperClass="svg" />
                </Link>
              </div>
            </div>
          </span>
        </div>
      </div>

      <div className="latest-sale-mobile-container">
        <div className="latest-sale-mobile-container__first-row">
          <div className="latest-sale-mobile-container__first-row__col-1">
            <span>
              <strong>Offer ID:</strong>
            </span>
            <span>
              O0-
              {data.id}
            </span>
          </div>
          <div className="latest-sale-mobile-container__first-row__col-2">
            <div className="sales-content">
              <strong>Date:</strong>
              <div className="sales-content-value">{data.created_at}</div>
            </div>
          </div>
        </div>
        <div className="sales-content mt-4 flex gap-2">
          {data.status !== leadStatuses.SENT && <strong>Status:</strong>}
          <span className="sales-content-value">
            {getLeadStatusText(data.status)}
          </span>
        </div>
        <div className="latest-sale-mobile-container__second-row">
          <div className="action-button">
            <div className="headings-mobile">
              <Link to={{ pathname: '/submissions', target_id: data.id }}>
                <strong>VIEW</strong>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
