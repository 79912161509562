import { hasValue } from '@bonlineza/b-lib/functions';

/*
There are occasions where sale_quote.total is 0 but the sale_order.total
will have the value or vice versa due to dear's inconsistencies
This function determines whether to use the sale_order.total or sale_quote.total
based which one has a value
*/
export const getQuoteOrSaleTotal = (quoteTotal = 0, orderTotal = 0) => {
  if (hasValue(quoteTotal) && quoteTotal > 0) {
    return quoteTotal;
  }

  if (hasValue(orderTotal) && orderTotal > 0) {
    return orderTotal;
  }
  return 0;
};
