import React, { Fragment } from 'react';
import GALink from '../GALink';

function RequestInfoSection({ title, button_text, button_link }) {
  return (
    <Fragment>
      <h4 className="u-fc--primary u-margin--bottom">{title}</h4>
      <div>
        <GALink
          href={button_link}
          className="btn--luxity"
          label={button_text}
          target="_blank"
          rel="noopener noreferrer">
          {button_text}
        </GALink>
      </div>
    </Fragment>
  );
}

export default RequestInfoSection;
