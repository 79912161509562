import React, { Fragment, useRef, useState, useEffect } from 'react';
import api from 'constants/api';
import IconHeader from 'components/IconHeader';
import OrdersComponent from 'components/Orders/FullDetailsItem';
import GARoute from 'components/GARoute';
import AxiosPageReadyWrapper, {
  AxiosPageReadyContext,
} from 'components/AxiosPageReadyWrapper';
import scrollToElement from 'functions/scrollToElement';
import withRouter from 'helpers/withRouter';
import NoPurchases from '../../components/NoPurchases';

const CurrentOrdersView = (props) => {
  const ordersPageReady = useRef();
  const [targetOrderId, setTargetOrderId] = useState('');
  const updateOrdersPage = () => ordersPageReady.current.refreshPage();
  useEffect(() => {
    if (targetOrderId) {
      window.setTimeout(() => {
        const targetElement = document.getElementById(targetOrderId);
        scrollToElement(targetElement);
      }, 500);
    }
  }, [targetOrderId]);

  return (
    <Fragment>
      <IconHeader title="Current Order" svgName="my_order" />
      <AxiosPageReadyWrapper
        ref={ordersPageReady}
        url={api.data.CURRENT_ORDERS}
        onData={() => setTargetOrderId(props.location.target_id)}>
        <AxiosPageReadyContext.Consumer>
          {({ data: current_orders = [] }) => {
            if (current_orders.length > 0) {
              return current_orders.map((order, index) => (
                <div id={order.id} key={index}>
                  <OrdersComponent
                    data={order}
                    onOrderChanged={updateOrdersPage}
                    footer={() => (
                      <GARoute
                        className="u-text--bold"
                        to="/order-history"
                        label="View Past Orders">
                        View Past Orders
                      </GARoute>
                    )}
                  />
                </div>
              ));
            }
            return <NoPurchases />;
          }}
        </AxiosPageReadyContext.Consumer>
      </AxiosPageReadyWrapper>
    </Fragment>
  );
};

export default withRouter(CurrentOrdersView);
