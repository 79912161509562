/** @format */

import React from 'react';
import SimpleSelect from 'react-select';

import PropTypes from 'prop-types';
import FormFieldRadioCheckbox from './FormFieldRadioCheckbox';

import l from '../../helpers/locale';

class FormFieldGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      placeholder: props.placeholder || l('SELECT-placeholder'),
      hasError: false,
      [props.group]:
        props.default &&
        Object.prototype.hasOwnProperty.call(props.default, 'value')
          ? props.default.value
          : '',
      preselectedValues: props.selectedValues
        ? props.opts.filter(
            (v) =>
              props.selectedValues.includes(`${v.value}`) ||
              props.selectedValues.includes(v.value),
          )
        : [],
    };
  }

  state;

  componentWillReceiveProps(nextProps) {
    if (
      !this.state.hasError &&
      Object.keys(nextProps.errorFields).includes(this.props.group)
    ) {
      this.setError(true);
    }

    return true;
  }

  setError = (val = false) =>
    this.setState((prevState) => ({
      ...prevState,
      hasError: val,
    }));

  getRef = (input) => {
    this.props.setRef(input, this.props.group);
  };

  updateField = (input) => {
    const value = input ? input.value : '';
    this.setState((prevState) => ({
      ...prevState,
      [this.props.group]: value,
      hasError: false,
    }));
  };

  render() {
    return (
      <div className={this.props.wrappingClass}>
        <div
          className={`${
            (this.state.hasError && 'form__item--danger') || 'form__item '
          }`}>
          <div className="gw">
            <div className="g g-1/1">
              {this.props.groupTitle !== '' ? (
                <div className={this.props.groupTitleContainerClass}>
                  <h3 className={this.props.groupTitleClass}>
                    {l(this.props.groupTitle)}
                  </h3>
                  {this.props.groupSubtitle !== '' ? (
                    <p>{l(this.props.groupSubtitle)}</p>
                  ) : null}
                </div>
              ) : null}
              {(this.props.label.length && (
                <label className="form__item__label" htmlFor="__">
                  {l(this.props.label)}
                  {(!this.props.viewOnly && this.props.mandatory && '*') || ''}
                </label>
              )) ||
                null}
            </div>
            <div className="g g-1/1">
              {(this.props.opts.length &&
                (this.props.type === 'select' ? (
                  <div>
                    {this.props.viewOnly ? (
                      <span className="text--dk--flushed">
                        {this.props.default.label}
                      </span>
                    ) : (
                      <div
                        data-qe-id={`action-click-form_selector_${this.props.name}`}>
                        <SimpleSelect
                          placeholder={this.state.placeholder}
                          defaultValue={this.props.default}
                          onValueChange={this.updateField}
                          options={this.props.opts.map((v) => ({
                            value: v.value,
                            label: v.label,
                          }))}
                        />
                        <span className="visuallyhidden">
                          <input
                            name={this.props.group}
                            value={this.state[this.props.group]}
                            type="text"
                            readOnly
                            ref={this.getRef}
                          />
                        </span>
                      </div>
                    )}
                  </div>
                ) : (
                  (this.props.viewOnly &&
                    (this.state.preselectedValues.length > 0 ? (
                      this.state.preselectedValues.map((item, itemKey) => (
                        <div key={itemKey} className={this.props.viewOnlyClass}>
                          {`${item.label}${
                            itemKey !== this.state.preselectedValues.length - 1
                              ? ','
                              : ''
                          }`}
                        </div>
                      ))
                    ) : (
                      <div />
                    ))) ||
                  (this.props.opts.length && (
                    <div className="form__item__group">
                      <div className="gw--no-guts">
                        {this.props.opts.map((v, vk) => (
                          <FormFieldRadioCheckbox
                            key={vk}
                            vk={vk}
                            type={this.props.type}
                            group={this.props.group}
                            innerWrappingClass={this.props.innerWrappingClass}
                            setRef={this.props.setRef}
                            inputClass={v.inputClass}
                            labelClass={v.labelClass}
                            value={v.value}
                            name={l(v.label)}
                            reversed={this.props.reverse}
                            hasError={this.state.hasError}
                            resetError={this.setError}
                            errorFields={this.props.errorFields}
                            selectedValues={this.props.selectedValues}
                          />
                        ))}
                      </div>
                    </div>
                  ))
                ))) ||
                null}
            </div>
            {this.state.hasError && (
              <div className="g g-1/1">
                <div className="form__item__validation">
                  {l(this.props.errorFields[this.props.group])}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

FormFieldGroup.propTypes = {
  type: PropTypes.oneOf(['checkbox', 'radio', 'select']).isRequired,
  opts: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string,
      inputClass: PropTypes.string,
      labelClass: PropTypes.string,
    }),
  ).isRequired,
  reverse: PropTypes.bool,
  group: PropTypes.string.isRequired,
  setRef: PropTypes.func,
  wrappingClass: PropTypes.string,
  innerWrappingClass: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  groupTitleContainerClass: PropTypes.string,
  groupTitleClass: PropTypes.string,
  groupTitle: PropTypes.string,
  groupSubtitle: PropTypes.string,
  default: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  errorFields: PropTypes.object,
  viewOnly: PropTypes.bool.isRequired,
  selectedValues: PropTypes.array,
  mandatory: PropTypes.bool,
};

FormFieldGroup.defaultProps = {
  wrappingClass: 'wrapping-class',
  innerWrappingClass: 'form__input-container',
  reverse: false,
  label: '',
  placeholder: null,
  groupTitleContainerClass: '',
  groupTitleClass: '',
  groupTitle: '',
  groupSubtitle: '',
  mandatory: false,
  default: '',
  errorFields: {},
  selectedValues: [],
  setRef: () => null,
};

export default FormFieldGroup;
