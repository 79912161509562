import React from 'react';
import LuxityLoader from 'components/LuxityLoader';
import config from 'config/config';

export const getLoginUrl = (systemMessage) => {
  const redirectParams = {
    redir_url: encodeURI(window.location.href),
    sys_msg: (systemMessage && encodeURI(systemMessage)) || null,
    login: true,
  };

  // remove invalid keys
  const queryString = Object.keys(redirectParams)
    .filter((key) => !!redirectParams[key])
    .map((key) => `${key}=${redirectParams[key]}`)
    .join('&');

  return `${config.LUXITY_SHOP_URL}?${queryString}`;
};

export default ({ systemMessage = null }) => {
  // GO TO WP LOGIN PAGE
  window.location.assign(getLoginUrl(systemMessage));

  return <LuxityLoader />;
};

// Polyfill for missing function for Store menu
const gotoMyAccount = () => {
  window.location.assign(getLoginUrl());
};
global.gotoMyAccount = gotoMyAccount;
