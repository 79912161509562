import React from 'react';
import BankAndCodeSelector from './BankAndCodeSelector';
import MaskedFormField from '../MaskedFormField';
import AccountTypeSelector from './AccountTypeSelector';

const formatAccountOptsForDropSelect = (optionsObj = {}) =>
  Object.keys(optionsObj).map((propName) => ({
    value: propName,
    label: optionsObj[propName],
    name: 'account_type',
  }));

export default (values) => {
  const passedObj = values || {};
  const { account_type_options = {}, account_type = '' } = values;
  const currentAccountType = account_type
    ? {
        value: account_type,
        label: account_type_options[account_type],
      }
    : null;

  return [
    {
      fields: [
        {
          type: 'text',
          mandatory: true,
          name: 'account_holder',
          label: 'Account Holder',
          itemClass: 'form__item--horizontal',
          value: passedObj?.account_holder || '',
        },
        {
          type: 'component',
          mandatory: true,
          name: 'account_number',
          label: 'Account Number',
          component: ({ ...props }) => (
            <MaskedFormField {...props} passedObj={passedObj} />
          ),
        },
        {
          type: 'component',
          mandatory: true,
          id: 'bank_and_code_selector',
          itemClass: 'bank-and-code-selector',
          label: 'Bank',
          name: 'bank',
          placeholder: 'Please select Branch Code',
          component: ({ ...props }) => (
            <BankAndCodeSelector {...props} passedObj={passedObj} />
          ),
        },
        {
          type: 'component',
          mandatory: true,
          id: 'account_type',
          itemClass: 'form__item--horizontal account-type',
          name: 'account_type',
          label: 'Account Type',
          placeholder: 'Please select account type',
          component: ({ ...props }) => (
            <AccountTypeSelector
              {...props}
              currentAccountType={currentAccountType}
              account_type_options={account_type_options}
            />
          ),
          default: currentAccountType,
          options: formatAccountOptsForDropSelect(account_type_options),
          group: 'account_type',
        },
      ],
    },
  ];
};
