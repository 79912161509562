/** @format */
import * as actionFunc from 'functions/actionRequests.func.js';

export default function generateConstants(baseAction) {
  const upperName = baseAction.toUpperCase();
  return {
    success: `${upperName}_REQUEST_SUCCESS`,
    failed: `${upperName}_REQUEST_FAILED`,
    fetching: `${upperName}_REQUEST_FETCHING`,
  };
}

export const generateActions = (baseAction) => {
  const constants = generateConstants(baseAction);
  return {
    success: actionFunc.success(constants.success),
    failed: actionFunc.failed(constants.failed),
    fetching: actionFunc.fetching(constants.fetching),
  };
};

// still deciding if this should be here
const initialRequestState = {
  fetching: false,
  failed: false,
  success: false,
};

export const createSharedNetworkReducer =
  (baseAction) =>
  (state = initialRequestState, { type, error }) => {
    switch (type) {
      case `${baseAction}_REQUEST_FETCHING`:
        return {
          ...initialRequestState.request,
          fetching: true,
        };
      case `${baseAction}_REQUEST_SUCCESS`:
        return {
          ...initialRequestState.request,
          success: true,
        };
      case `${baseAction}_REQUEST_FAILED`:
        return {
          ...initialRequestState.request,
          failed: true,
          message: error.message,
        };
      default:
        return state;
    }
  };
