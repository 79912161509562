import React from 'react';
import DropdownSelector from 'views/AddressesView/components/Addresses/DropdownSelector';

export default (
  values,
  updateCountryFn = () => false,
  countryHasChanged = false,
  setCountryHasChangedState = () => false,
  isLocal,
  provinceOptions = [],
  countryOptions = [],
  address_type = 'personal',
) => {
  const passedObj = values || {};

  const countrySelectValue = {
    label: values?.country || '',
    value: values?.country || '',
  };

  const provinceSelectValue = {
    label: values?.state || '',
    value: values?.state || '',
  };

  const provinceStateField = [
    isLocal
      ? {
          type: 'component',
          placeholder: 'Province/state *',
          label: 'Province/state',
          name: 'state',
          mandatory: true,
          value: passedObj?.state,
          itemClass:
            'form__item--horizontal province-country form__item__state',
          component: ({ ...props }) => (
            <DropdownSelector
              defaultDropdownValue={provinceSelectValue}
              options={provinceOptions}
              countryHasChanged={countryHasChanged}
              {...props}
            />
          ),
        }
      : {
          type: 'text',
          mandatory: true,
          name: 'state',
          value: countryHasChanged ? '' : passedObj?.state,
          placeholder: 'Province/state *',
          itemClass: 'form__item--horizontal form__item__state',
        },
  ];

  const companyNameField = [
    address_type === 'company'
      ? {
          type: 'text',
          mandatory: true,
          name: 'company_name',
          placeholder: 'Company Name *',
          label: 'Company Name',
          itemClass: 'form__item--horizontal form__item__line1 company_name',
          value: passedObj?.company_details?.company_name || '',
        }
      : {
          itemClass: 'form__item--hidden',
        },
  ];

  const vatNumberField = [
    address_type === 'company'
      ? {
          type: 'text',
          mandatory: false,
          name: 'vat_number',
          placeholder: 'Vat Number',
          label: 'VAT Number',
          itemClass: 'form__item--horizontal form__item__line1 vat_number',
          value: passedObj?.company_details?.vat_number || '',
        }
      : {
          itemClass: 'form__item--hidden',
        },
  ];

  return [
    {
      fields: [
        ...companyNameField,
        ...vatNumberField,
        {
          type: 'text',
          mandatory: true,
          name: 'line1',
          placeholder: 'Address Line 1 *',
          label: 'Address Line 1',
          itemClass: 'form__item--horizontal form__item__line1',
          value: passedObj?.line1 || '',
        },
        {
          type: 'text',
          mandatory: true,
          name: 'line2',
          placeholder: 'Address Line 2 *',
          label: 'Address Line 2',
          itemClass: 'form__item--horizontal form__item__line2',
          value: passedObj?.line2 || '',
        },
        {
          type: 'text',
          mandatory: true,
          name: 'city',
          label: 'Town/City',
          placeholder: 'Town/City *',
          itemClass: 'form__item--horizontal form__item__city',
          value: passedObj?.city || '',
        },

        ...provinceStateField,

        {
          type: 'text',
          mandatory: true,
          name: 'post_code',
          placeholder: 'Postal Code *',
          label: 'Postal Code',
          itemClass:
            'form__item--horizontal form__item--horizontal form__item__postcode',
          value: passedObj?.post_code || '',
        },

        {
          type: 'component',
          name: 'country',
          placeholder: 'Country *',
          label: 'Country',
          mandatory: true,
          value: passedObj?.country,
          itemClass:
            'form__item--horizontal province-country form__item__country',
          component: ({ ...props }) => (
            <DropdownSelector
              setCountryHasChangedState={setCountryHasChangedState}
              defaultDropdownValue={countrySelectValue}
              updateCountryFn={updateCountryFn}
              options={countryOptions}
              {...props}
            />
          ),
        },
      ],
    },
  ];
};
