/** @format */

import AuthActions, { auth } from '../constants/auth';

const initialAuthState = {
  auth: false,
  user: {
    created_at: '',
    email: '',
    id: null,
    name: '',
    updated_at: '',
    email_interest_send_schedule: null,
  },
  status: '',
  request: {
    fetching: false,
    success: false,
    failed: false,
    error: null,
  },
};

export const userAuthReducer = (
  state = initialAuthState,
  { type, payload, error = null },
) => {
  switch (type) {
    case AuthActions.fetching:
      return {
        ...state,
        request: {
          ...initialAuthState.request,
          fetching: true,
        },
      };
    case AuthActions.failed:
      return {
        ...state,
        request: {
          ...initialAuthState.request,
          failed: true,
          error,
        },
      };
    case AuthActions.success:
      return {
        ...state,
        request: {
          ...initialAuthState.request,
          success: true,
        },
        user: {
          ...payload,
        },
        auth: true,
      };
    case auth.AUTH_REMOVE:
      return {
        ...initialAuthState,
      };
    default:
      return state;
  }
};
