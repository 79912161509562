import React, { Fragment } from 'react';

export default function Index({ sale_order_number }) {
  return (
    <Fragment>
      <div className="desktop-eft-container">
        <div className="eft-container__col-1">
          <span>
            <b>Account Name: </b>
            <span>Luxity (pty) ltd</span>
          </span>
          <span>
            <b>Account Number: </b>
            <span>62556319509</span>
          </span>
          <span>
            <b>Bank: </b>
            <span>FNB</span>
          </span>
        </div>
        <div className="eft-container__col-2">
          <span>
            <b>Type: </b>
            <span>Cheque</span>
          </span>
          <span>
            <b>Branch Code: </b>
            <span>250 655</span>
          </span>
          <span>
            <b>Payment Reference: </b>
            <span>{sale_order_number}</span>
          </span>
        </div>

        <div className="luxity-text-info eft-container__col-3">
          Please use your
          <b> Order No </b>
          <div>as the payment reference.</div>
        </div>
      </div>

      <div className="mobile-eft-container">
        <div className="mobile-eft-container__col-1">
          <span>
            <b>Account Name: </b>
            <span>Luxity (pty) ltd</span>
          </span>
          <span>
            <b>Account Number: </b>
            <span>62556319509</span>
          </span>
          <span>
            <b>Bank: </b>
            <span>FNB</span>
          </span>
          <span>
            <b>Type: </b>
            <span>Cheque</span>
          </span>
          <span>
            <b>Branch Code: </b>
            <span>250 655</span>
          </span>
        </div>
        <div className="mobile-eft-container__col-2">
          <span>
            <b>Payment Reference: </b>
            <span>{sale_order_number}</span>
          </span>
          <div>
            Please use your
            <b> Order No </b>
            <span>as the payment reference.</span>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
