import React, { Fragment } from 'react';
import { getSubmissionStatusText } from '../utils/getSubmissionStatusText';

const SubmissionDetails = ({ submissions }) => {
  return (
    <Fragment>
      <div className="xm:hidden md:block">
        <div className="flex w-11/12 px-10 mb-1">
          <div className="w-1/5 font-bold">Submission ID</div>
          <div className="w-1/3 font-bold">Date</div>
          <div className="w-1/3 font-bold">Status</div>
        </div>
        {submissions.map((submission) => (
          <div key={submission.id} className="mb-2 border border-gray-200">
            <div className="flex w-11/12 px-10 py-2">
              <div className="w-1/5 text-gray-700">
                S0-
                {submission.id}
              </div>
              <div className="w-1/3 text-gray-700">
                {submission.created_date}
              </div>
              <div className="w-1/3 text-gray-700">
                {getSubmissionStatusText(submission.status)}
              </div>
            </div>
          </div>
        ))}
      </div>

      {submissions.map((submission) => (
        <div
          key={submission.id}
          className="p-3 mb-2 flex justify-between border border-gray-200 md:hidden">
          <div className="w-3/5">
            <div className="flex mb-1">
              <div className="mr-1 text-end font-bold">Submission ID:</div>
              <div className="text-gray-700">
                S0-
                {submission.id}
              </div>
            </div>
            <div className="flex">
              <div className="mr-1 text-end font-bold">Status:</div>
              <div className="text-gray-700">
                {getSubmissionStatusText(submission.status)}
              </div>
            </div>
          </div>
          <div>
            <div className="font-bold text-end">
              Date:&nbsp;
              <span className="font-normal text-gray-700">
                {submission.created_date}
              </span>
            </div>
          </div>
        </div>
      ))}
    </Fragment>
  );
};

export default SubmissionDetails;
