import React from 'react';
import InviteFriend from './components/InviteFriend';
import CouponList from './components/CouponList';

const Coupon = ({ showInviteAFriend }) => {
  return (
    <div className="coupons">
      {showInviteAFriend && <InviteFriend />}
      <CouponList showInviteAFriend={showInviteAFriend} />
    </div>
  );
};

export default Coupon;
