import React, { Fragment } from 'react';
import axios from 'helpers/axios';
import api from 'constants/api';
import requestErrorMessages from 'constants/requestErrorMessages';
import reviewQuestions from 'constants/reviewQuestions';
import { snakeCaseToTitleCase } from '@bonlineza/b-lib/functions';
import LuxityLoader from 'components/LuxityLoader';
import Form from 'vendor/Form';
import {
  success as notifySuccess,
  error as notifyError,
} from '@bonlineza/luxity-lib/Notify';
import PropTypes from 'prop-types';
import withRouter from 'helpers/withRouter';
import reviewFormFields from './reviewFormFields';

const BaseRequest = {
  loading: false,
  error: false,
  success: false,
};

class ReviewForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      request: BaseRequest,
      errorMessages: {},
    };
  }

  setErrors = (errObj) => this.setState({ errorMessages: { ...errObj } });

  handleValidation = (payload) => {
    const errorMessages = {};
    Object.keys(payload).forEach((propName) => {
      if (!payload[propName]) {
        errorMessages[propName] = `${snakeCaseToTitleCase(
          propName,
        )} is mandatory`;
      }
    });

    const errObj = {
      fields: errorMessages,
      result: Object.keys(errorMessages).length === 0,
    };

    return {
      ...errObj,
    };
  };

  formatPayload = (payload) => {
    const review = Object.keys(payload).map((key) => ({
      question: reviewQuestions[key],
      answer: payload[key],
    }));
    const { token } = this.props;

    return {
      review,
      token,
    };
  };

  getSubmit = (payload) => {
    this.updateRequest({ loading: true });
    axios
      .post(
        api.clientReview.submit(this.props.clientReviewId, this.props.token),
        this.formatPayload(payload),
      )
      .then(() => {
        this.updateRequest({ success: true });
        notifySuccess('Thank you for your feedback');
        this.props.history.push('/');
      })
      .catch((e) => {
        this.updateRequest({ failed: true });
        this.handleError(e.status, e.data);
      });
  };

  handleError = (code, errorData = {}) => {
    if (code === 422) {
      const errorMessages = {};
      Object.keys(errorData).forEach((errPropName) => {
        errorMessages[errPropName] = Array.isArray(errorData[errPropName])
          ? errorData[errPropName].reduce((pv, cV) => cV, '')
          : errorData[errPropName];
      });
      this.setErrors(errorMessages);
    } else if (code === 401) {
      notifyError(
        'Oops, it looks like your review token is invalid or you have submitted your Review already',
      );
    } else {
      notifyError(requestErrorMessages.RESPONSE_500);
    }
  };

  updateRequest = (obj) =>
    this.setState((prevState) => ({
      ...prevState,
      request: {
        ...BaseRequest,
        ...obj,
      },
    }));

  render = () => (
    <Fragment>
      <div className="review-form form">
        <Form
          sections={reviewFormFields()}
          submitAct={this.getSubmit}
          afterSubmitErrors={this.state.errorMessages}
          isProcessing={this.state.request.loading}
          validateBefore={this.handleValidation}
          submitButtonString="Submit"
          wrappingClass="luxity-form__wrapping"
        />

        {this.state.request.loading && <LuxityLoader />}
      </div>
    </Fragment>
  );
}

ReviewForm.propTypes = {
  clientReviewId: PropTypes.oneOfType(PropTypes.string, PropTypes.number)
    .isRequired,
  token: PropTypes.string.isRequired,
};

export default withRouter(ReviewForm);
