/** @format */

import actionTypes, { CLEAR_ERROR } from '../constants';

const initialState = {
  overlay: null,
};

export default function appReducer(state = initialState, { type }) {
  switch (type) {
    case actionTypes.OVERLAY.SERVER_ERROR:
      return {
        ...state,
        overlay: type,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        overlay: null,
      };

    default:
      return state;
  }
}
