import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Notification from 'vendor/Notification';
import LuxityNotification from 'vendor/LuxityNotification';
import { fC } from 'helpers/locale';

const BodyNotification = ({
  requireAccountDetails,
  requireIdVerification,
  customerCredits,
}) => {
  return (
    <Fragment>
      {customerCredits > 0 && (
        <Notification
          containerClassName="notification notification--available-credit"
          svgName="available_credits"
          renderContent={() => (
            <Fragment>
              <div className="notification__text-wrapper">
                <p className="notification__text-wrapper__body--no-margin">
                  <Fragment>
                    <strong>
                      Available Credit:&nbsp;
                      {fC(customerCredits, false)}
                    </strong>
                    &nbsp;- There's never been a better time to expand your
                    collection!
                  </Fragment>
                </p>
              </div>
            </Fragment>
          )}
        />
      )}
      {requireAccountDetails ? (
        <Notification
          renderContent={() => (
            <Fragment>
              <div className="notification__text-wrapper">
                <p className="notification__text-wrapper__body--no-margin">
                  <Fragment>
                    <strong>Account Details: </strong>
                    to ensure you are paid out swiftly once payment is due,
                    please update&nbsp; Bank Account Details &nbsp;in&nbsp;
                    <Link to="/profile">your profile.</Link>
                  </Fragment>
                </p>
              </div>
            </Fragment>
          )}
        />
      ) : null}
      {requireIdVerification && (
        <Notification
          renderContent={() => (
            <Fragment>
              <div className="notification__text-wrapper">
                <p className="notification__text-wrapper__body--no-margin">
                  <Fragment>
                    <strong>Account Details: </strong>
                    To ensure you are paid out swiftly once payment is due,
                    please verify&nbsp;
                    <Link to="/profile">your profile</Link>
                    &nbsp;by uploading your ID document.
                  </Fragment>
                </p>
              </div>
            </Fragment>
          )}
        />
      )}
      <LuxityNotification />
    </Fragment>
  );
};

export default BodyNotification;
