import React from 'react';
import { leadStatuses } from 'constants/leadStatuses';

export const getLeadStatusText = (status) => {
  switch (status) {
    case leadStatuses.ACCEPTED:
      return <div className="text-[#4FA254]">Accepted</div>;
    case leadStatuses.DECLINED:
      return <div className="text-[#CA3B33]">Declined</div>;
    case leadStatuses.RECEIVED:
      return <div className="text-[#3D6CD4]">Received</div>;
    default:
      return null;
  }
};
