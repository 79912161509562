import React, { useState, useEffect } from 'react';
import SimpleSelect from 'react-select';
import { hasValue } from '@bonlineza/b-lib/functions';

const DropdownSelector = ({
  itemClass = 'province-country',
  options = [],
  errors = {},
  name = '',
  controlRef = () => false,
  defaultDropdownValue = {},
  label: title = '',
  dropdownWrapperClass = 'province-country__dropdown-selector-wrapper',
  viewOnly = false,
  mandatory = false,
  updateCountryFn = () => false,
  setCountryHasChangedState = () => false,
  countryHasChanged = false,
  placeholder = 'Select Country',
}) => {
  const [dropdownValue, setDropdownValue] = useState(defaultDropdownValue);
  const [errMsg, setErrMsg] = useState(errors[name]);

  const getTitle = () =>
    `${title}${mandatory && viewOnly === false ? ' *' : ''}`;

  useEffect(() => {
    // Set default value initially
    const { value = '' } = dropdownValue;
    controlRef(name, value);
  }, [controlRef, dropdownValue, defaultDropdownValue, name]);

  useEffect(() => {
    const { label = '', value = '' } = dropdownValue;
    updateCountryFn(label);
    controlRef(name, value);
  }, [controlRef, dropdownValue, name, updateCountryFn]);

  const changeHandler = (value) => {
    setErrMsg('');
    setCountryHasChangedState(true);
    setDropdownValue(value);
  };

  useEffect(() => {
    setErrMsg(errors[name]);
  }, [errors, name]);

  const appendDefaultValue = () => {
    const { label = '', value = '' } = defaultDropdownValue;
    if (hasValue(label) && hasValue(value) && !countryHasChanged) {
      return { defaultValue: defaultDropdownValue };
    }
    return {};
  };
  return (
    <div className={itemClass}>
      <div
        className={`${dropdownWrapperClass}${
          hasValue(errMsg) ? '--error' : ''
        }`}>
        {title && (
          <div className="province-country__dropdown-selector-wrapper__title">
            {getTitle()}
          </div>
        )}
        {viewOnly ? (
          <div className="text--dk--flushed">
            {defaultDropdownValue.label || placeholder}
          </div>
        ) : (
          <SimpleSelect
            className="dropdown-selector-container"
            classNamePrefix="dropdown-selector"
            placeholder={placeholder}
            options={options}
            onChange={changeHandler}
            isDisabled={viewOnly}
            {...appendDefaultValue()}
          />
        )}
      </div>
      {hasValue(errMsg) && (
        <div className="form__item__validation">{errMsg}</div>
      )}
    </div>
  );
};

export default DropdownSelector;
