/** @format */

import React, { Fragment } from 'react';
import l from 'helpers/locale';
import { PrimaryButton } from '@bonlineza/luxity-lib';
import PropTypes from 'prop-types';
import FormFieldGroup from './FormFieldGroup';
import FormField from './FormField';
import sanitizeStore from './helpers/sanitizeStore.js';

// type getRefsType = () => Array<
//   | {
//       name: string,
//       input: any,
//     }
//   | *,
// >;
//
// type RefFuncTypeReturn = {
//   getRefs: getRefsType,
//   controlRef: (string, string) => boolean,
//   batchUpdate: (Object[]) => getRefsType,
// };
//
// type ErrorShape = {
//   result: boolean,
//   fields?: Array<{
//     [string]: string,
//   }>,
// };
//
// type FormPropsFieldsType = {
//   id: string,
//   name: string,
//   label?: string,
//   type?: string,
//   value?: string | number | boolean,
//   selectedValues?: Array<string | number>,
//   options?: Array<OptsShape>, // required for type checkbox | radio
//   containerClass?: string,
//   itemClass?: string,
//   groupTitleContainerClass?: string,
//   groupTitleClass?: string,
//   groupTitle?: string,
//   component?: Function,
//   placeholder?: string,
//   default?: {
//     value: string | number,
//     label: string,
//   },
// };
//
// type StateShape = {
//   errors: ErrorShape,
// };

class Form extends React.Component {
  static defaultProps = {
    customFooter: null,
    fieldsOnly: false,
    validateBefore: () => ({ result: true }),
    afterSubmitErrors: {},
    viewOnly: false,
    wrappingClass: '',
    wrappingOuterClass: '',
    formClass: '',
    Before() {
      return null;
    },
    After() {
      return null;
    },
    cancelAct: false,
    isProcessing: false,
    submitButtonString: 'submit',
    submitButtonClass: 'btn--luxity',
    actionsJsx: [],
    toEdit: false,
    displayToEdit: false,
    sections: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
    this.refStore = this.handleRef();
    this.formFieldGroupTypes = ['checkbox', 'radio', 'select'];
  }

  static getDerivedStateFromProps(nextProps) {
    if (Object.keys(nextProps.afterSubmitErrors).length > 0) {
      return {
        errors: nextProps.afterSubmitErrors,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (Object.keys(prevState.errors).length > 0) {
      this.scrollToTop();
    }
  }

  setErrors = (errors) =>
    this.setState((prevState) => ({
      ...prevState,
      errors,
    }));

  getBefore = () => {
    // this is unnecessary
    const { Before } = this.props;
    return <Before setRef={this.refStore.setRef} />;
  };

  getAfter = () => {
    // this is unnecessary
    const { After } = this.props;
    return <After setRef={this.refStore.setRef} />;
  };

  getFooter = () => {
    if (this.props.customFooter) {
      return this.props.customFooter(() => this.refStore.getRefs());
    }

    if (this.props.viewOnly && this.props.displayToEdit) {
      return (
        <div className="fl-right__item">
          <PrimaryButton
            onClick={(e) => {
              e.preventDefault();
              this.props.toEdit();
            }}
            type="button">
            <div className="flex gap-4 items-center">
              EDIT
              <img
                src="/assets/images/edit.png"
                alt="edit"
                width={15}
                height={15}
              />
            </div>
          </PrimaryButton>
        </div>
      );
    }

    if (this.props.viewOnly) return null;

    return (
      <Fragment>
        {this.props.cancelAct && (
          <div className="fl-right__item">
            <PrimaryButton
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.setErrors({});
                this.props.cancelAct();
              }}>
              <div className="flex gap-4 items-center">
                Cancel
                <img
                  src="/assets/images/delete.png"
                  alt="cancel"
                  width={15}
                  height={15}
                />
              </div>
            </PrimaryButton>
          </div>
        )}
        {this.props.actionsJsx.length
          ? this.props.actionsJsx.map((Action, index) => (
              <div key={index} className="fl-right__item">
                <Action />
                {/* this is a button */}
              </div>
            ))
          : null}
        <div className="fl-right__item">
          <PrimaryButton
            disabled={this.props.isProcessing}
            type="submit"
            data-qe-id="action-submit-form">
            <div className="flex gap-4 items-center">
              {this.props.submitButtonString}
              <img
                src="/assets/images/save.png"
                alt="save"
                width={15}
                height={15}
              />
            </div>
          </PrimaryButton>
        </div>
      </Fragment>
    );
  };

  getFieldType = (item) => {
    switch (true) {
      case this.formFieldGroupTypes.includes(item.type):
        return (
          <FormFieldGroup
            controlRef={this.refStore.controlRef}
            errorFields={this.state.errors}
            viewOnly={this.props.viewOnly}
            key={item.id}
            opts={item.options}
            group={item.name}
            innerWrappingClass={item.innerClass}
            {...item}
          />
        );
      case item.type === 'component':
        return item.component({
          label: item.label,
          batchUpdate: this.refStore.batchUpdate,
          controlRef: this.refStore.controlRef,
          key: item.id,
          viewOnly: this.props.viewOnly,
          hasError: Object.prototype.hasOwnProperty.call(
            this.state.errors,
            item.name,
          ),
          ...item,
          errors: this.state.errors,
        });
      default:
        return (
          <FormField
            viewOnly={this.props.viewOnly}
            errorFields={this.state.errors}
            key={item.id}
            controlRef={this.refStore.controlRef}
            {...item}
          />
        );
    }
  };

  getFields = () => (
    <Fragment>
      <div className={this.props.wrappingOuterClass}>
        {this.getBefore()}

        {this.props.sections.map((sect, key) => (
          <div key={key} className={sect.sectionClass ? sect.sectionClass : ''}>
            {sect.title && (
              <div>
                <h1 className="card__header__title">{l(sect.title)}</h1>
              </div>
            )}
            {sect.subtitle && (
              <Fragment>
                <div className="u-fc--primary u-text--bold">
                  {l(sect.subtitle)}
                </div>
                <hr />
              </Fragment>
            )}

            <div className={this.props.wrappingClass}>
              {sect.fields.length &&
                sect.fields.map((item, key2) => (
                  <Fragment key={key2}>{this.getFieldType(item)}</Fragment>
                ))}
            </div>
          </div>
        ))}
      </div>

      {this.getAfter()}

      <div className="fl-right form-buttons">{this.getFooter()}</div>
    </Fragment>
  );

  scrollToTop = () => {
    // this doesn't work for AsideSlides
    const container = document.querySelector('.app-layout__body');
    const currentScroll = container.scrollTop;

    if (currentScroll > 0) {
      if (typeof container.scrollTo === 'function') {
        container.scrollTo(0, 0);
      } else {
        container.scrollTop = 0;
      }
    }

    return true;
  };

  handleRef = () => {
    const refs = [];
    return {
      getRefs() {
        return refs;
      },

      controlRef(name, value) {
        const index = refs.findIndex((item) => item.name === name);
        if (index !== -1) {
          refs.splice(index, 1);
          refs.push({ name, value });
        } else {
          refs.push({ name, value });
        }
        return refs;
      },

      batchUpdate(items) {
        items.forEach((item) => {
          const { name, value } = item;

          const index = refs.findIndex((ref) => ref.name === name);
          if (index !== -1) {
            refs.splice(index, 1);
            refs.push({ name, value });
          } else {
            refs.push({ name, value });
          }
          return item;
        });
        return refs;
      },
    };
  };

  submitHandler = (e, store, cb) => {
    e.preventDefault();

    const payload = sanitizeStore(store);
    const validation = this.props.validateBefore
      ? this.props.validateBefore(payload)
      : { result: false };

    return validation.result
      ? this.payloadValidated(payload, cb)
      : this.setErrors(validation.fields);
  };

  payloadValidated = (payload, cb) => {
    this.clearErrors({});
    return cb(payload, this.setErrors);
  };

  clearErrors = () =>
    this.setState((prevState) => ({
      ...prevState,
      errors: {},
    }));

  render() {
    return (
      <Fragment>
        {this.props.fieldsOnly ? (
          this.getFields()
        ) : (
          <form
            className={`${this.props.formClass} ${
              (!this.props.viewOnly && 'editing') || ''
            }`}
            onSubmit={(e) =>
              this.submitHandler(
                e,
                this.refStore.getRefs(),
                this.props.submitAct,
              )
            }>
            {this.getFields()}
          </form>
        )}
      </Fragment>
    );
  }
}

Form.propTypes = {
  customFooter: PropTypes.func,
  fieldsOnly: PropTypes.bool,
  submitAct: PropTypes.func.isRequired,
  cancelAct: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  wrappingClass: PropTypes.string,
  wrappingOuterClass: PropTypes.string,
  validateBefore: PropTypes.func,
  Before: PropTypes.node,
  After: PropTypes.node,
  formClass: PropTypes.string,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      sectionClass: PropTypes.string,
      fields: PropTypes.array,
    }),
  ),
  afterSubmitErrors: PropTypes.object, // some errors js can't catch
  viewOnly: PropTypes.bool,
  isProcessing: PropTypes.bool,
  submitButtonString: PropTypes.string,
  submitButtonClass: PropTypes.string,
};

export default Form;
