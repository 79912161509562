export const NavbarAssets = {
  accordionIcon: '/assets/images/arrow-right.svg',
  backIcon: '/assets/images/back-arrow.svg',
  logoSrc: '/assets/images/luxity-logo.svg',
  menuIcon: '/assets/images/menu.svg',
  inputSearchIcon: '/assets/images/search.svg',
  cartIcon: '/assets/images/navbar/cart.svg',
  closeIcon: '/assets/images/close.svg',
  accountIcon: '/assets/svgs/account.svg',
  interestIcon: '/assets/images/navbar/interest.svg',
  orderIcon: '/assets/svgs/my_order.svg',
  saleIcon: '/assets/svgs/my_sale.svg',
  newBrandsImage: '/assets/images/navbar/new-arrivals.webp',
  bagsImage: '/assets/images/navbar/bags.webp',
  shoesImage: '/assets/images/navbar/shoes.webp',
  clothingImage: '/assets/images/navbar/apparel.webp',
  accessoriesImage: '/assets/images/navbar/accessories.webp',
  watchesImage: '/assets/images/navbar/watch.webp',
};
