/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Fragment, useState } from 'react';
import ReactSelect from 'react-select';
import { ColorRing } from 'react-loader-spinner';
import PhoneNumberField from 'components/PhoneNumberField';
import MaskedFormField from '../MaskedFormField';
import { idValidationStatuses } from '../../../../constants/idValidationStatuses';

function getGeneralFields(obj = {}) {
  const {
    first_name,
    surname,
    email,
    contact_number,
    id_number,
    id_file,
    contact_number_country,
    whatsapp_number,
    whatsapp_number_country,
    is_id_validated,
    id_disapproved,
  } = obj;
  const country_code = contact_number_country?.code;
  const whatsapp_country_code = whatsapp_number_country?.code;
  return {
    first_name,
    surname,
    email,
    country_code,
    contact_number,
    id_number,
    id_file,
    whatsapp_number,
    whatsapp_country_code,
    is_id_validated,
    id_disapproved,
  };
}

function getOptionsFromGraphqlResponse(response) {
  return response?.map((res) => ({
    label: res?.communication_preference,
    value: res?.communication_preference,
  }));
}

function generateSelectedValue(option) {
  return {
    value: option,
    label: option,
  };
}

export default (
  values,
  diallingCodes,
  communicationPreferenceOptions,
  onPreferenceChange,
  communication_preference,
) => {
  const generalFields = values ? getGeneralFields(values) : {};

  const options = getOptionsFromGraphqlResponse(communicationPreferenceOptions);

  const default_value = generateSelectedValue(values?.communication_preference);
  const selected = communication_preference
    ? generateSelectedValue(communication_preference)
    : default_value;

  const TooltipText = ({ text }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseEnter = () => {
      setShowTooltip(true);
    };

    const handleMouseLeave = () => {
      setShowTooltip(false);
    };

    return (
      <div
        className="relative inline-block w-1/2"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <img
          src="assets/images/tooltip.png"
          alt="Tooltip"
          className="ml-1 mb-3 md:mb-2 w-5 h-5"
        />
        {showTooltip && (
          <div className="absolute bg-black text-white py-1 px-2 rounded z-10 bottom-full left-10 md:left-0 -translate-x-1/2 -translate-y-2">
            {text}
          </div>
        )}
      </div>
    );
  };

  const renderBadge = () => {
    if (generalFields.id_disapproved === idValidationStatuses.PENDING) {
      return (
        <ColorRing
          visible
          height="20"
          width="20"
          ariaLabel="color-ring-loading"
          wrapperStyle={{}}
          wrapperClass="color-ring-wrapper"
          colors={['#000000', '#000000', '#000000', '#000000', '#000000']}
        />
      );
    }

    if (generalFields.is_id_validated) {
      return (
        <img
          src="assets/images/approved.png"
          alt="Approved"
          className="ml-2 align-middle"
        />
      );
    }

    if (generalFields.id_disapproved === idValidationStatuses.DISAPPROVED) {
      return (
        <TooltipText text="There was an error matching your ID number to your submitted ID document, please try again" />
      );
    }

    return null;
  };

  return [
    {
      fields: [
        {
          type: 'email',
          mandatory: true,
          name: 'email',
          label: 'Email',
          viewOnly: true,
          itemClass: 'form__item--horizontal',
          value: generalFields?.email || '',
        },
        {
          type: 'text',
          mandatory: true,
          name: 'first_name',
          label: 'First name',
          itemClass: 'form__item--horizontal',
          value: generalFields?.first_name || '',
        },
        {
          type: 'text',
          mandatory: true,
          name: 'surname',
          label: 'Surname',
          itemClass: 'form__item--horizontal',
          value: generalFields?.surname || '',
        },
        {
          type: 'component',
          mandatory: true,
          name: 'contact_number',
          label: 'Contact Number',
          value: '',
          component: (props) => (
            <Fragment>
              <div className="form__item--horizontal">
                <label htmlFor="contact_number" className="form__item__label">
                  Contact Number
                  {!props.viewOnly && props.mandatory && '*'}
                </label>
                <div className="text--dk--flushed form__item__input">
                  <PhoneNumberField
                    name="contact_number"
                    className="form__item--horizontal"
                    phoneNumber={generalFields?.contact_number}
                    countryCode={generalFields?.country_code}
                    countryCodeData={diallingCodes}
                    countryCodeVar="country_code"
                    phoneNumberVar="contact_number"
                    {...props}
                  />
                </div>
              </div>
            </Fragment>
          ),
        },
        {
          type: 'component',
          mandatory: false,
          name: 'whatsapp_number',
          label: 'WhatsApp Number',
          value: '',
          component: (props) => (
            <Fragment>
              <div className="form__item--horizontal">
                <label htmlFor="whatsapp_number" className="form__item__label">
                  WhatsApp Number
                  {!props.viewOnly && props.mandatory && '*'}
                </label>
                <div className="text--dk--flushed form__item__input">
                  <PhoneNumberField
                    name="whatsapp_number"
                    className="form__item--horizontal"
                    // show contact number as whatsapp number if whatsapp number is not set
                    phoneNumber={
                      generalFields?.whatsapp_number
                        ? generalFields?.whatsapp_number
                        : generalFields?.contact_number
                    }
                    countryCode={
                      generalFields?.whatsapp_country_code
                        ? generalFields?.whatsapp_country_code
                        : generalFields?.country_code
                    }
                    countryCodeVar="whatsapp_country_code"
                    phoneNumberVar="whatsapp_number"
                    countryCodeData={diallingCodes}
                    placeholder="WhatsApp Number"
                    {...props}
                  />
                </div>
              </div>
            </Fragment>
          ),
        },
        {
          type: 'component',
          mandatory: false,
          name: 'communication_preference',
          label: 'Communication Preference',
          value: generalFields?.contact_number || '',
          component: (props) => (
            <Fragment>
              <div className="form__item--horizontal hidden">
                <label
                  htmlFor="communication_preference"
                  className="form__item__label">
                  Communication Preference
                  {!props.viewOnly && props.mandatory && '*'}
                </label>
                {props?.viewOnly ? (
                  <div className="text--dk--flushed form__item__input">
                    <ReactSelect
                      isMulti={false}
                      isSearchable={false}
                      components={{ IndicatorSeparator: () => null }}
                      options={options}
                      className="notification-dropdown-select-container"
                      classNamePrefix="notification-dropdown-select"
                      onChange={() => {}}
                      isDisabled
                      value={[default_value]}
                    />
                  </div>
                ) : (
                  <div className="text--dk--flushed form__item__input">
                    <ReactSelect
                      isMulti={false}
                      isSearchable={false}
                      components={{ IndicatorSeparator: () => null }}
                      options={options}
                      className="notification-dropdown-select-container"
                      classNamePrefix="notification-dropdown-select"
                      onChange={onPreferenceChange}
                      value={[selected]}
                    />
                  </div>
                )}
              </div>
            </Fragment>
          ),
        },
        {
          type: 'component',
          mandatory: true,
          name: 'id_number',
          label: 'ID/Passport Number',
          component: ({ ...props }) => {
            const { id_disapproved, is_id_validated } = generalFields;

            if (id_disapproved === idValidationStatuses.DISAPPROVED) {
              return (
                <Fragment>
                  <MaskedFormField
                    {...props}
                    passedObj={{
                      ...generalFields,
                      id_number: generalFields.id_number,
                    }}
                  />
                </Fragment>
              );
            }

            if (
              is_id_validated === true ||
              id_disapproved === idValidationStatuses.PENDING
            ) {
              return (
                <Fragment>
                  <div className="form__item--horizontal">
                    <label htmlFor="id_file" className="form__item__label">
                      ID/Passport Number
                    </label>
                    <div className="text--dk--flushed form__item__input">
                      {renderBadge()}
                    </div>
                  </div>
                </Fragment>
              );
            }

            return (
              <Fragment>
                <MaskedFormField
                  {...props}
                  passedObj={{
                    ...generalFields,
                    id_number: generalFields.id_number,
                  }}
                  viewOnly={false}
                />
              </Fragment>
            );
          },
        },
        {
          type: 'component',
          mandatory: true,
          name: 'id_file',
          component: ({ ...props }) => {
            const { id_disapproved, is_id_validated } = generalFields;

            if (
              is_id_validated === true ||
              id_disapproved === idValidationStatuses.PENDING
            ) {
              return null;
            }

            if (
              id_disapproved === idValidationStatuses.DISAPPROVED ||
              !is_id_validated
            ) {
              return (
                <Fragment>
                  <div className="form__item--horizontal">
                    <label htmlFor="id_file" className="form__item__label">
                      ID/Passport Document
                      {renderBadge()}
                      {!props.viewOnly && props.mandatory && '*'}
                    </label>
                    <div className="text--dk--flushed form__item__input">
                      <input
                        type="file"
                        id="id_file"
                        name="id_file"
                        onChange={(e) =>
                          props.controlRef('id_file', e.target.files[0])
                        }
                        disabled={props.viewOnly}
                      />
                    </div>
                  </div>
                </Fragment>
              );
            }

            return (
              <Fragment>
                <div className="form__item--horizontal">
                  <label htmlFor="id_file" className="form__item__label">
                    ID/Passport Document
                    {!props.viewOnly && props.mandatory && '*'}
                  </label>
                  <div className="text--dk--flushed form__item__input">
                    {generalFields.id_file ? (
                      <div className="file-upload-success">
                        {generalFields.id_file.split('/').pop()}
                      </div>
                    ) : (
                      <input
                        type="file"
                        id="id_file"
                        name="id_file"
                        onChange={(e) =>
                          props.controlRef('id_file', e.target.files[0])
                        }
                        disabled={props.viewOnly}
                      />
                    )}
                  </div>
                </div>
              </Fragment>
            );
          },
        },
      ],
    },
  ];
};
