import React from 'react';
import LuxityRadioFormWrapper from 'components/LuxityRadioButtons/LuxityRadioFormWrapper';
import reviewQuestions from 'constants/reviewQuestions';

export default () => [
  {
    fields: [
      {
        type: 'component',
        name: 'review_question_1',
        id: 'review_question_1',
        component: ({ ...props }) => (
          <LuxityRadioFormWrapper
            name="review_question_1"
            title={reviewQuestions.review_question_1}
            options={[
              {
                innerText: 'Yes',
                value: 'yes',
              },
              {
                innerText: 'No',
                value: 'no',
              },
            ]}
            {...props}
          />
        ),
      },
      {
        type: 'component',
        name: 'review_question_2',
        id: 'review_question_2',
        component: ({ ...props }) => (
          <LuxityRadioFormWrapper
            name="review_question_2"
            title={reviewQuestions.review_question_2}
            options={[
              {
                innerText: 'Yes',
                value: 'yes',
              },
              {
                innerText: 'No',
                value: 'no',
              },
            ]}
            {...props}
          />
        ),
      },
      {
        itemClass: 'luxity-form__item',
        label: reviewQuestions.comment,
        labelClass: 'luxity-form__item__label',
        type: 'textarea',
        name: 'comment',
        id: 'comment',
      },
    ],
  },
];
