export const getUserLuxityLeadsQuery = (initialLimit, email, status) =>
  `{
        luxity_leads(limit: ${initialLimit}, page: 1, email: "${email}" status: [${status}]) {
            data {
                id
                created_at
                email
                status
                lead_notes
                follow_up_status
                lead_items {
                    id
                    category_type
                    item_name
                    consignment_price
                    cash_price
                    status
                    consignment
                }
            }
            per_page
            total
            current_page
            last_page
        }
    }`;

export const getUserFormSubmissionsQuery = (limit, without_leads, status) =>
  `{
        user_form_submissions(limit: ${limit}, page: 1, without_leads: ${without_leads}, sort_by: "created_at|desc", status: [${status}]) {
            data {
                id
                created_date
                status
            }
            per_page
            total
            current_page
            last_page
        }
    }`;
