import React, { Fragment } from 'react';
import {
  snakeCaseToTitleCase,
  removeWhiteSpaces,
} from '@bonlineza/b-lib/functions';
import Form from 'vendor/Form';
import accountFormFields from './accountFormFields';
import { update_account } from '../../actions';
import PasswordConfirmation from '../PasswordConfirmation';

const BaseRequest = {
  loading: false,
  error: false,
  success: false,
};

const baseAccountDetails = {
  account_holder: null,
  account_number: null,
  branch_code: null,
  bank: null,
};

class AccountDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      request: BaseRequest,
      accountDetails: { ...baseAccountDetails },
      accountModalVisibility: false,
      password: '',
      errorMessages: {},
      editting: false,
    };
  }

  setErrors = (errObj) => this.setState({ errorMessages: { ...errObj } });

  handleValidation = (payload) => {
    // add requirements here - pass true for now
    const errorMessages = {};
    if (!payload?.account_type) {
      errorMessages.account_type = 'Account Type is mandatory';
    }
    Object.keys(payload).forEach((propName) => {
      if (
        !payload[propName] &&
        ['email', 'account_number'].indexOf(propName) === -1
      ) {
        errorMessages[propName] = `${snakeCaseToTitleCase(
          propName,
        )} is mandatory`;
      }
    });
    const errObj = {
      fields: errorMessages,
      result: Object.keys(errorMessages).length === 0,
    };

    return {
      ...errObj,
    };
  };

  getAccountDetails = (payload) => {
    const { account_holder, account_number, account_type, branch_code, bank } =
      payload;

    const accountDetails = {
      account_holder: account_holder.trim(),
      account_number: removeWhiteSpaces(account_number || ''),
      account_type, // selection field
      branch_code: branch_code.trim(),
      bank: bank.trim(),
    };
    this.setState({
      accountDetails,
      accountModalVisibility: true,
    });
  };

  handleBeforeSubmitPasswordValidation = (password = null) => {
    const errorMessages = {};
    if (!password) {
      errorMessages.password = 'Password is mandatory';
    }
    this.setErrors(errorMessages);
    return Object.keys(errorMessages).length === 0;
  };

  updateAccountDetails = () => {
    const { password } = this.state;
    if (this.handleBeforeSubmitPasswordValidation(password)) {
      this.updateRequest({ loading: true });
      update_account(
        {
          ...this.state.accountDetails,
          password,
        },
        () => {
          this.updateRequest({ success: true });
          this.props.actionCallback('update_account')();
        },
        (e) => {
          this.updateRequest({ error: true });
          this.handleError(e.status, e.data);
        },
      );
    }
  };

  handleError = (code, errorData = {}) => {
    if (code === 422) {
      const errorMessages = {};
      Object.keys(errorData).forEach((errPropName) => {
        errorMessages[errPropName] = errorData[errPropName].reduce(
          (pv, cV) => cV,
          '',
        );
        this.setErrors(errorMessages);
      });
      if (errorMessages.password) {
        this.setErrors({
          password: errorMessages.password,
        });
      } else {
        this.setState({
          accountModalVisibility: false,
          errorMessages: { ...errorMessages },
        });
      }
    } else {
      this.props.actionCallback('generic_error')();
    }
  };

  updateRequest = (obj) =>
    this.setState((prevState) => ({
      ...prevState,
      request: {
        ...BaseRequest,
        ...obj,
      },
    }));

  switchEdit = () =>
    this.setState((prevState) => ({
      ...prevState,
      editting: !prevState.editting,
      password: '',
      errorMessages: {},
    }));

  closeAction = () => {
    this.setState({
      accountModalVisibility: false,
      errorMessages: {},
    });
  };

  updatePasswordValue = (name, value) => {
    const { errorMessages } = this.state;
    const oldErrors = {
      ...errorMessages,
    };
    delete oldErrors[name];
    this.setState({
      [name]: !value ? null : value,
      errorMessages: oldErrors,
    });
  };

  getOptions = () => [
    {
      cb: () => this.closeAction(),
      buttonText: 'Cancel',
      buttonClass: 'modal__container__footer__item__button--secondary-button',
      dataQeId: 'button-cancel',
    },
    {
      cb: () => this.updateAccountDetails(),
      buttonText: 'Submit',
      isDisabled: this.state.request.loading,
      buttonClass:
        'modal__container__footer__item__button--primary-button--bold',
      dataQeId: 'button-submit',
    },
  ];

  render = () => (
    <Fragment>
      <Form
        sections={accountFormFields(this.props.bankDetails)}
        submitAct={this.getAccountDetails}
        validateBefore={this.handleValidation}
        submitButtonString="Save"
        cancelAct={this.switchEdit}
        viewOnly={!this.state.editting}
        displayToEdit
        toEdit={this.switchEdit}
        submitButtonClass="text-button"
        afterSubmitErrors={this.state.errorMessages}
      />
      <PasswordConfirmation
        isOpen={this.state.accountModalVisibility}
        onClose={this.closeAction}
        isLoading={this.state.request.loading}
        options={this.getOptions()}
        controlRef={this.updatePasswordValue}
        errors={this.state.errorMessages}
      />
    </Fragment>
  );
}

export default AccountDetails;
