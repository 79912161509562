import React from 'react';
// import Header from './components/Header';
import Notification from 'vendor/Notification';

// todo -
export default () => (
  <Notification
    renderContent={() => (
      <p>
        Oops, it looks like your review token is invalid or you have submitted
        your Review already.
      </p>
    )}
  />
);
