/** @format */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import getSvgStore from 'vendor/GetSvg/reducers';
import TooltipReducer from 'components/ModalTooltip/reducer';
import ProfileReducer from 'views/Profile/reducer';

import history from 'helpers/history';
import appReducer from './app';
import { userAuthReducer } from './auth';

export default combineReducers({
  authStore: userAuthReducer,
  router: connectRouter(history), // root reducer with router state
  profileStore: ProfileReducer,
  appStore: appReducer,
  svgStore: getSvgStore,
  tooltipStore: TooltipReducer,
});
