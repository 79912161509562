import React, { Fragment } from 'react';
import AddressFormGroup from './AddressFormGroup';

// group of AddressForms
class Addresses extends React.Component {
  static getDerivedStateFromProps(props, state) {
    let result = [];
    if (props.billing_addresses.length !== state.billing_addresses.length) {
      result = [
        ...result,
        {
          billing_addresses: props.billing_addresses,
        },
      ];
    }
    if (props.shipping_addresses.length !== state.shipping_addresses.length) {
      result = [
        ...result,
        {
          shipping_addresses: props.shipping_addresses,
        },
      ];
    }

    return result;
  }

  constructor(props) {
    super(props);
    this.state = {
      billing_addresses: props.billing_addresses,
      shipping_addresses: props.shipping_addresses,
    };
  }

  // type === billing_addresses | shipping_addresses
  addNewAddress = (type) => {
    this.setState((prevState) => ({
      ...prevState,
      [type]: [...prevState[type], { isNew: true }],
    }));
  };

  render() {
    return (
      <Fragment>
        <AddressFormGroup
          actionCallback={this.props.actionCallback}
          addressType="Billing"
          addresses={this.state.billing_addresses}
          countryOptions={this.props.countryOptions || []}
          provinceOptions={this.props.provinceOptions || []}
        />
      </Fragment>
    );
  }
}

export default Addresses;
