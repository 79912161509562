import React, { Fragment } from 'react';
import generateStatusPoints from 'functions/generateStatusPoints.func';
import LuxityToolTip from 'components/LuxityToolTip';

const StatusItemGauge = ({ statusList, statusPosition }) => {
  const statusPoints =
    statusList.length > 1
      ? generateStatusPoints(statusList, statusPosition)
      : [];

  return (
    <Fragment>
      <div className="item__header__right__secondary">
        <div className={`step-${statusPoints.length}-gauge`}>
          {statusPoints.map((statusPoint, index) => (
            <LuxityToolTip
              key={index}
              text={statusPoint.tooltipText}
              containerClassName={`${statusPoint.className} tooltip`}>
              {statusPoint.stepNumber}
            </LuxityToolTip>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default StatusItemGauge;
