/** @format */

import { connect } from 'react-redux';
import { hasAuth } from '../functions/authenticator.func.js';

const App = ({ children }) => {
  if (!hasAuth()) {
    return children;
  }
  return null;
};

const mapState = ({ authStore }) => ({
  hasProfile: !!authStore.user.id,
});

export default connect(mapState)(App);
