import React, { Fragment, useEffect, useState } from 'react';
import withRouter from 'helpers/withRouter';
import axios from 'helpers/axios';
import api from 'constants/api';
import {
  success as notifySuccess,
  error as notifyError,
} from '@bonlineza/luxity-lib/Notify';
import requestErrorMessages from 'constants/requestErrorMessages';
import LuxityLoader from 'components/LuxityLoader';
import { hasAuth } from 'functions/authenticator.func';
import GALink from 'components/GALink';
import config from 'config/config';
import GARoute from 'components/GARoute';
import RequestInfoSection from '../../components/RequestInfoSection';

const request = {
  fetching: false,
  failed: false,
  success: false,
  errorCode: null,
  errorMsg: '',
};

const UnSubscribeInterest = ({ params: { token } }) => {
  const [requestState, setRequestState] = useState(request);
  const unsubscribeToken = token.split('?')[0];
  const updateRequestState = (obj = {}) => {
    const newObj = {
      request,
      ...obj,
    };
    setRequestState(newObj);
  };

  useEffect(() => {
    updateRequestState({ fetching: true });
    axios
      .get(`${api.user.UNSUBSCRIBE_INTERESTS}/${unsubscribeToken}`)
      .then(() => {
        updateRequestState({ success: true });
        notifySuccess('You were successfully unsubscribed from interests.');
      })
      .catch((e) => {
        updateRequestState({ failed: true, errorCode: e.status });
        notifyError(requestErrorMessages.RESPONSE_500);
      });
  }, [unsubscribeToken]);

  const getErrorMarkup = () => {
    notifyError(requestErrorMessages.RESPONSE_500);
    return (
      <Fragment>
        <h4>We were unable to unsubscribe you from the interests.</h4>
        <div>
          Please contact
          <GALink
            href={`${config.LUXITY_SHOP_URL}/bug-report`}
            target="_blank"
            className="u-fc--primary"
            rel="noopener noreferrer"
            label="Go to Luxity Shop">
            <span> support </span>
          </GALink>
          for further assistance.
        </div>
        <div>
          Or click
          <GARoute to="/" className="u-fc--primary">
            <span> here </span>
          </GARoute>
          to go back to
          {hasAuth() ? ' Dashboard' : ' Login'}
        </div>
      </Fragment>
    );
  };

  return (
    <div className="u-text--center u-padding">
      {requestState.success && (
        <Fragment>
          <RequestInfoSection
            title="You have been successfully removed from the interests."
            button_link={config.LUXITY_SHOP_URL}
            button_text="Shop Now"
          />
        </Fragment>
      )}

      {requestState.fetching && (
        <Fragment>
          <h4 className="u-fc--primary">
            Working on unsubscribing you from the interests.
          </h4>
          <LuxityLoader />
        </Fragment>
      )}

      {requestState.failed && getErrorMarkup()}
    </div>
  );
};

export default withRouter(UnSubscribeInterest);
