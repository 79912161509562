/** @format */

import numeral from 'numeral';
import store from '../store';

export function getLocale() {
  return store
    ? store.getState().appStore.locale
    : window.localStorage.getItem('_sl');
}

// FORMAT CURRENCY
export function fC(number, round = true) {
  return !Number.isNaN(parseFloat(number))
    ? numeral(
        round ? Math.round(parseFloat(number)) : parseFloat(number),
      ).format('$ 0,0.00')
    : 'R -';
}

export default function handleLocale(localeKey) {
  return localeKey ? localeKey.toLocaleString() : null;
}
