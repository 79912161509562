import React, { Fragment, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import api from 'constants/api';
import IconHeader from '../../IconHeader';
import GAButton from '../../GAButton';
import GetSvg from '../../../vendor/GetSvg';
import AxiosPageReadyWrapper, {
  AxiosPageReadyContext,
} from '../../AxiosPageReadyWrapper';
import CouponItem from '../CouponItem';
import CouponForm from './CouponForm';

const CouponList = ({ showInviteAFriend }) => {
  const [couponAddState, setCouponAddState] = useState({
    isAddCouponClicked: false,
    accordionHeight: 0,
  });
  const getCouponList = (coupons) => {
    if (showInviteAFriend) {
      return coupons.slice(0, 3);
    }
    return coupons;
  };

  const viewMore = () => {
    window.location.assign('/coupons');
  };

  const couponsPageReady = useRef();

  const handleAddCoupon = () => {
    setCouponAddState((prevState) => ({
      ...prevState,
      isAddCouponClicked: !prevState?.isAddCouponClicked,
      accordionHeight: 'auto',
    }));

    if (couponAddState.isAddCouponClicked) {
      closeAccordion();
    }
  };

  const closeAccordion = () => {
    setCouponAddState((prevState) => ({
      ...prevState,
      isAddCouponClicked: prevState?.isAddCouponClicked,
      accordionHeight: 0,
    }));
  };

  const AccordionContent = () => {
    return (
      <AnimateHeight height={couponAddState.accordionHeight}>
        <CouponForm />
      </AnimateHeight>
    );
  };

  return (
    <div
      className={`coupons__section
      ${showInviteAFriend ? 'coupons__section__dashboard' : ''}`}>
      <div className="coupons__header">
        <IconHeader title="Coupons" svgName="my_coupon" />
        <div className="coupons__add">
          <GAButton
            label="add-coupon-btn"
            className="action-button card-btn"
            type="button"
            onClick={handleAddCoupon}>
            <GetSvg svg="add" wrapperClass="svg" />
          </GAButton>
        </div>
      </div>
      <AccordionContent />
      <div className="coupons__list">
        <AxiosPageReadyWrapper ref={couponsPageReady} url={api.data.COUPONS}>
          <AxiosPageReadyContext.Consumer>
            {({ data: coupon = [] }) => {
              const couponList = getCouponList(coupon);
              return (
                <Fragment>
                  {couponList.length > 0 ? (
                    couponList.map((data, key) => {
                      return <CouponItem key={key} index={key} data={data} />;
                    })
                  ) : (
                    <div className="coupons__empty">
                      <div className="coupons__empty-content">
                        <div className="coupons--empty-message">
                          Currently you have no saved coupons with Luxity.
                        </div>
                      </div>
                    </div>
                  )}
                  {coupon.length > 3 && showInviteAFriend && (
                    <div className="coupons__view-more">
                      <button
                        type="button"
                        className="btn--primary coupons__view-more-btn"
                        onClick={viewMore}>
                        view more
                      </button>
                    </div>
                  )}
                </Fragment>
              );
            }}
          </AxiosPageReadyContext.Consumer>
        </AxiosPageReadyWrapper>
      </div>
    </div>
  );
};

export default CouponList;
