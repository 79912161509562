import React, { Fragment } from 'react';

const CouponItem = ({ index, data }) => {
  return (
    <Fragment>
      <div className="coupons__data-row">
        <div className="coupons__heading">
          {index === 0 && <div className="coupons__code">Coupon Code</div>}
          {index === 0 && <div className="coupons__value">Value</div>}
          {index === 0 && <div className="coupons__date">Valid Until</div>}
          {index === 0 && <div className="coupons__date">Status (Reason)</div>}
        </div>
        <div className="coupons__content">
          <div className="coupons__code">{data.code}</div>
          <div className="coupons__value">
            {data.is_percentage
              ? `${data.discount_value}% OFF`
              : `R${data.discount_value} OFF`}
          </div>
          <div className="coupons__date">{data.valid_to}</div>
          <div className="coupons__status">
            {data.is_active
              ? 'Active'
              : `Inactive ${data?.reason ? `(${data.reason})` : ''}`}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CouponItem;
