import React from 'react';

const LuxityToolTip = ({
  children = null,
  text,
  containerClassName = 'tooltip',
}) => (
  <div className={containerClassName}>
    {children}
    <span className="tooltip__body">{text}</span>
  </div>
);

export default LuxityToolTip;
