import config from 'config/config';

export const links = {
  contactUsLink: `${config.LUXITY_SHOP_URL}/contact-us`,
  ourStoryLink: `${config.LUXITY_SHOP_URL}/about`,
  sellToUsLink: `${config.LUXITY_SHOP_URL}/sell-to-us`,
  blogLink: `${config.LUXITY_SHOP_URL}/blog`,
  careers: `${config.LUXITY_SHOP_URL}/blog/careers`,
  productLink: `${config.LUXITY_SHOP_URL}/products/brands`,
  faqLink: `${config.LUXITY_SHOP_URL}/faq`,
  privacyLink: `${config.LUXITY_SHOP_URL}/privacy`,
  shippingLink: `${config.LUXITY_SHOP_URL}/shipping`,
  termsConditionsLink: `${config.LUXITY_SHOP_URL}/terms-conditions`,
  cartLink: `${config.LUXITY_SHOP_URL}/cart`,
  bugReportLink: `${config.LUXITY_SHOP_URL}/bug-report`,
  sellYourWatchLink: `${config.LUXITY_SHOP_URL}/sell-your-watch`,
  sellYourJewelleryLink: `${config.LUXITY_SHOP_URL}/sell-your-jewellery`,
  sellYourHandbagLink: `${config.LUXITY_SHOP_URL}/sell-your-handbag`,
  sellYourShoesLink: `${config.LUXITY_SHOP_URL}/sell-your-shoes`,
  sellYourClothingLink: `${config.LUXITY_SHOP_URL}/sell-your-clothing`,
};

export const brands = {
  LOUIS_VUITTON: 'Louis Vuitton',
  GUCCI: 'Gucci',
  CHANEL: 'Chanel',
  CHRISTIAN_LOUBOUTIN: 'Christian Louboutin',
  CHRISTIAN_DIOR: 'Christian Dior',
  BURBERRY: 'Burberry',
  ROLEX: 'Rolex',
  CARTIER: 'Cartier',
};

export const brandLinks = {
  LOUIS_VUITTON: `${config.LUXITY_SHOP_URL}/products/brands/louis-vuitton`,
  GUCCI: `${config.LUXITY_SHOP_URL}/products/brands/gucci`,
  CHANEL: `${config.LUXITY_SHOP_URL}/products/brands/chanel`,
  CHRISTIAN_LOUBOUTIN: `${config.LUXITY_SHOP_URL}/products/brands/christian-louboutin`,
  CHRISTIAN_DIOR: `${config.LUXITY_SHOP_URL}/products/brands/christian-dior`,
  BURBERRY: `${config.LUXITY_SHOP_URL}/products/brands/burberry`,
  ROLEX: `${config.LUXITY_SHOP_URL}/products/brands/rolex`,
  CARTIER: `${config.LUXITY_SHOP_URL}/products/brands/cartier`,
};

export const locationLinks = {
  nelsonMandelaLink:
    'https://www.google.com/maps/place/Luxity+%7C+Pre-owned+Authenticated+Luxury/@-26.106636,28.054678,16z/data=!4m6!3m5!1s0x1dcc5d995dea6c5b:0x2d89244b85e124af!8m2!3d-26.1066365!4d28.0546775!16s%2Fg%2F11c1pv8z_l?hl=en-US&entry=ttu',
  gatewayLink:
    'https://www.google.com/maps/place/Luxity+%7C+Pre-owned+Authenticated+Luxury/@-29.727163,31.066486,10z/data=!4m6!3m5!1s0x1ef705469d98d885:0x3c7e9190dd1ffc07!8m2!3d-29.7271632!4d31.0664861!16s%2Fg%2F11jz8l9n1f?hl=en&entry=ttu',
  menlynMainLink:
    'https://www.google.com/maps/place/Luxity+%7C+Pre-Owned+Authenticated+Luxury/@-25.78506,28.28117,16z/data=!4m6!3m5!1s0x1e956168354739a5:0x739e01cdcf2cd613!8m2!3d-25.78506!4d28.28117!16s%2Fg%2F11q2sy6gc5?hl=en&entry=ttu',
  vaWaterfrontLink:
    'https://www.google.com/maps/place/Luxity+%7C+Pre-owned+Authenticated+Luxury/@-33.903805,18.421075,18z/data=!4m6!3m5!1s0x1dcc6792256c095b:0xb477c6879933ad23!8m2!3d-33.9038046!4d18.4210754!16s%2Fg%2F11fj9c3qs3?hl=en-US&entry=ttu',
};
