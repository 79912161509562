export const categories = {
  BAGS: 'BAGS',
  SHOES: 'SHOES',
  APPAREL: 'APPAREL',
  ACCESSORIES: 'ACCESSORIES',
  WATCHES: 'WATCHES',
  JEWELLERY: 'JEWELLERY',
  SCARVES: 'SCARVES',
  WALLETS: 'WALLETS',
  SUNGLASSES: 'SUNGLASSES',
  PENS: 'PENS',
};
