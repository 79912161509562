import React, { Fragment } from 'react';
import api from 'constants/api';
import OrdersComponent from 'components/Orders/FullDetailsItem';
import IconHeader from 'components/IconHeader';
import AxiosPageReadyWrapper, {
  AxiosPageReadyContext,
} from 'components/AxiosPageReadyWrapper';
import NoPurchases from '../../components/NoPurchases';

const OrdersHistoryView = () => (
  <Fragment>
    <IconHeader title="Orders | Order History" svgName="my_order" />
    <AxiosPageReadyWrapper url={api.data.ORDER_HISTORY}>
      <AxiosPageReadyContext.Consumer>
        {({ data: order_history = [] }) => {
          if (order_history.length > 0) {
            return order_history.map((order, index) => (
              <Fragment key={index}>
                <OrdersComponent data={order} />
              </Fragment>
            ));
          }
          return <NoPurchases />;
        }}
      </AxiosPageReadyContext.Consumer>
    </AxiosPageReadyWrapper>
  </Fragment>
);

export default OrdersHistoryView;
