import React, { useContext, Fragment } from 'react';
import ListContext from 'vendor/GqlQueryList/config/ListContext.js';
import OrderItem from './Item';
import NoPurchases from '../NoPurchases';

export default ({ footer = null, profile = null, onOrderChanged }) => {
  const context = useContext(ListContext);
  const { list } = context;
  return (
    <Fragment>
      <div className="orders">
        {list.length > 0 ? (
          list.map((order, key) => {
            return (
              <OrderItem
                index={key}
                key={key}
                data={order}
                onOrderChanged={onOrderChanged}
                profile={profile}
              />
            );
          })
        ) : (
          <NoPurchases />
        )}

        {footer && list.length > 0 ? (
          <div className="orders__footer fl-right">{footer()}</div>
        ) : null}
      </div>
    </Fragment>
  );
};
