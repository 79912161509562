/** @format */

import React from 'react';

import l, { getLocale } from 'helpers/locale';
import PropTypes from 'prop-types';

class RadioCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  linkRef = (ref) =>
    this.props.setRef(ref, `${this.props.group}-${this.props.vk}`);

  changeHandler = () => (this.props.hasError ? this.props.resetError() : null);

  generateInput = () => (
    <input
      type={this.props.type}
      onChange={this.changeHandler}
      className={`${
        this.props.inputClass !== undefined
          ? this.props.inputClass
          : 'input-class'
      } ${
        this.props.hasError ||
        Object.keys(this.props.errorFields).includes(this.props.group)
          ? 'form__input-container--error'
          : ''
      }`}
      name={this.props.group}
      value={this.props.value}
      id={`${this.props.group}-${this.props.vk}`}
      data-qe-id={`choice-${this.props.group}-${this.props.vk}`}
      ref={this.linkRef}
      defaultChecked={
        this.props.selectedValues &&
        (this.props.selectedValues.includes(`${this.props.value}`) ||
          this.props.selectedValues.includes(this.props.value))
      }
    />
  );

  generateLabel = () =>
    this.props.group === 'profile[accept]' ? (
      this.generateLink()
    ) : (
      <label
        className={
          this.props.labelClass !== undefined
            ? this.props.labelClass
            : 'label-class'
        }
        htmlFor={`${this.props.group}-${this.props.vk}`}>
        {l(this.props.name)}
      </label>
    );

  generateLink = () => (
    <a
      className={this.props.labelClass}
      href={`/#/${getLocale().toLowerCase()}/terms-and-conditions`}
      target="_blank"
      rel="noopener noreferrer">
      {l(this.props.name)}
    </a>
  );

  render() {
    return (
      <span className={this.props.innerWrappingClass}>
        {this.props.reversed ? this.generateInput() : this.generateLabel}
        {this.props.reversed ? this.generateLabel() : this.generateInput}
      </span>
    );
  }
}

RadioCheckbox.propTypes = {
  reversed: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  inputClass: PropTypes.string.isRequired,
  group: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  vk: PropTypes.number.isRequired,
  setRef: PropTypes.func.isRequired,
  labelClass: PropTypes.string,
  name: PropTypes.string.isRequired,
  innerWrappingClass: PropTypes.string.isRequired,
  resetError: Function.isRequired,
  hasError: PropTypes.bool.isRequired,
  selectedValues: PropTypes.array,
};

RadioCheckbox.defaultProps = {
  labelClass: '',
  selectedValues: [],
};

export default RadioCheckbox;
