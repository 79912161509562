import React from 'react';
import { error as notifyError } from '@bonlineza/luxity-lib/Notify';
import api from 'constants/api';
import AxiosPageReadyWrapper from 'components/AxiosPageReadyWrapper';
import withRouter from 'helpers/withRouter';
import ReviewForm from './components/ReviewForm';
import TokenExpired from './components/TokenExpired';

class Review extends React.Component {
  constructor(props) {
    super(props);
    const parsed = new URLSearchParams(this.props.location.search);
    this.state = {
      tokenValid: false,
      token: this.props.params.token,
      rating: parsed.get('rating') || '',
      sale_id: this.props.params.sale_id,
      email_template_guid: this.props.params.template,
      client_review_id: null,
    };
  }

  getBodyContent = () => {
    const { tokenValid, client_review_id, token } = this.state;

    if (tokenValid) {
      return <ReviewForm clientReviewId={client_review_id} token={token} />;
    }
    return <TokenExpired />;
  };

  onData = (response) => {
    this.setState({
      tokenValid: true,
      client_review_id: response.data.id,
    });
  };

  onGetTokenFailed = () => {
    this.setState({ tokenValid: false });
    notifyError(
      'Oops, it looks like your review token is invalid or you have submitted your Review already',
    );
  };

  render = () => {
    const { sale_id, rating, email_template_guid, token } = this.state;

    return (
      <div className="page page-review">
        <div className="page__header">
          <h1>Your Feedback Matters:</h1>
        </div>
        <div className="page__body">
          <AxiosPageReadyWrapper
            url={api.clientReview.getId(token)}
            postBody={{
              rating,
              sale_id,
              email_template_guid,
            }}
            method="post"
            customErrorHandler={this.onGetTokenFailed}
            customErrorMessage="Review token is invalid or has already been submitted"
            onData={this.onData}>
            {this.getBodyContent()}
          </AxiosPageReadyWrapper>
        </div>
      </div>
    );
  };
}

export default withRouter(Review);
