/** @format */

import React, { Fragment } from 'react';
import Notify from '@bonlineza/luxity-lib/Notify';
import LuxityMenu from './components/LuxityMenu';
import AppFooter from './components/Footer';

const AppLayout = ({ children }) => (
  <Fragment>
    <Notify />
    <div className="app-layout">
      <div className="app-layout__header">
        <LuxityMenu />
      </div>
      <div className="app-layout__body">{children}</div>
      <div className="app-layout__footer">
        <AppFooter />
      </div>
    </div>
  </Fragment>
);

export default AppLayout;
