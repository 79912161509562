import React, { Fragment, useState } from 'react';
import Select from 'react-select';

const AccountTypeSelector = ({
  controlRef,
  hasError,
  viewOnly,
  label,
  name,
  itemClass,
  errors,
  placeholder,
  mandatory,
  currentAccountType,
  account_type_options,
}) => {
  const [currentSelectedAccountType, setCurrentSelectedAccountType] =
    useState(currentAccountType);

  return (
    <Fragment>
      <div
        className={`${(!viewOnly && hasError && `has-error `) || ''}
  ${itemClass}`}>
        <div className="form__item__label">
          {label}
          {!viewOnly && mandatory && '*'}
        </div>
        <div className="form__item__input">
          {viewOnly ? (
            <div className="text--dk--flushed">
              {currentSelectedAccountType?.label}
            </div>
          ) : (
            <Select
              className="react-select"
              classNamePrefix="react-select"
              isSearchable={false}
              // run control ref onrender
              luxityInitial={controlRef(
                name,
                currentSelectedAccountType?.value,
              )}
              options={Object.keys(account_type_options).map((v) => ({
                label: account_type_options[v],
                value: v,
              }))}
              placeholder={placeholder}
              defaultValue={currentSelectedAccountType}
              onChange={(selected) => {
                setCurrentSelectedAccountType({
                  value: selected.value,
                  label: selected.value,
                });
                controlRef(name, selected.value);
              }}
            />
          )}
        </div>
      </div>
      {!viewOnly && hasError && (
        <div className="form__item__validation">{errors[name]}</div>
      )}
    </Fragment>
  );
};

export default AccountTypeSelector;
